import React, { FC, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import { FieldErrors } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { IHelper } from '../../../store/reducers/helpers/types';
import { CircularProgress } from '@material-ui/core';
import { isUndefined } from 'lodash';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';

type TOptionTwo = {
  value: number;
  title: string;
};

type TCustomSelectProps = {
  options: IHelper[] | TOptionTwo[] | any;
  title: string;
  errors?: FieldErrors;
  name: string;
  value: string | number | IHelper | TOptionTwo | undefined | any;
  disabled?: boolean;
  loading?: boolean;
  endAdornment?: ReactNode;
  filterOptions?: (options: any[], state: FilterOptionsState<any>) => any[];
  onChange: (value: any) => void;
  onInputChange?: (value: string) => void;
};

const CustomAutocompleteSelect: FC<TCustomSelectProps> = ({
  options,
  title,
  value,
  errors,
  name,
  disabled = false,
  loading = false,
  endAdornment,
  filterOptions,
  onChange,
  onInputChange,
}) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <FormLabel component="legend" className={classes.label}>
        {title}
      </FormLabel>
      <Autocomplete
        value={value as any}
        options={options as any}
        classes={{ inputRoot: classes.selectInput }}
        filterOptions={filterOptions}
        onChange={(e, val) => {
          onChange(val);
        }}
        disabled={disabled}
        getOptionLabel={(option) => option.displayName || option.title || option.name || ''}
        onInputChange={(_, value) => onInputChange?.(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.input}
            placeholder="Выберите из списка"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {isUndefined(endAdornment) ? params.InputProps.endAdornment : endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {errors && <ErrorMessage as="span" className={classes.error} errors={errors} name={name} />}
    </FormControl>
  );
};

export default React.memo(CustomAutocompleteSelect);

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    display: 'flex',
  },
  label: {
    fontWeight: 300,
    fontStyle: 'normal',
    fontSize: 16,
    lineHeight: '19px',
    paddingBottom: 4,
  },
  selectInput: {
    height: 'auto',
    padding: '0px 40px 0px 0px !important',
  },
  input: {
    '& input': {
      padding: '7px 10px !important',
    },
  },
  error: {
    color: theme.palette.secondary.light,
  },
}));
