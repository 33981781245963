import * as Yup from 'yup';
import VALIDATION_MESSAGES, { DEFAULT_MAX_TEXT_LENGTH } from 'constants/ValidationsMessage';

export const ValidationSchema = Yup.object().shape({
  mark: Yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return Yup.object().required(VALIDATION_MESSAGES.required).nullable();
      case 'string':
        return Yup.string()
          .required(VALIDATION_MESSAGES.required)
          .nullable()
          .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength());
      default:
        return Yup.mixed();
    }
  }),
  model: Yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return Yup.object().required(VALIDATION_MESSAGES.required).nullable();
      case 'string':
        return Yup.string()
          .required(VALIDATION_MESSAGES.required)
          .nullable()
          .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength());
      default:
        return Yup.mixed();
    }
  }),
  autoClass: Yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return Yup.object().required(VALIDATION_MESSAGES.required).nullable();
      case 'string':
        return Yup.string()
          .required(VALIDATION_MESSAGES.required)
          .nullable()
          .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength());
      default:
        return Yup.mixed();
    }
  }),
  year: Yup.mixed().required(VALIDATION_MESSAGES.required).nullable(),
  registration: Yup.string()
    .required(VALIDATION_MESSAGES.required)
    .nullable()
    .min(1, VALIDATION_MESSAGES.minLength(1))
    .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength()),
  vin: Yup.string()
    .required(VALIDATION_MESSAGES.required)
    .nullable()
    .min(1, VALIDATION_MESSAGES.minLength(1))
    .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength()),
  crash: Yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return Yup.object().required(VALIDATION_MESSAGES.required).nullable();
      case 'string':
        return Yup.string()
          .required(VALIDATION_MESSAGES.required)
          .nullable()
          .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength());
      default:
        return Yup.mixed();
    }
  }),
  city: Yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return Yup.object().required(VALIDATION_MESSAGES.required).nullable();
      case 'string':
        return Yup.string()
          .required(VALIDATION_MESSAGES.required)
          .nullable()
          .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength());
      default:
        return Yup.mixed();
    }
  }),
  color: Yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return Yup.object().required(VALIDATION_MESSAGES.required).nullable();
      case 'string':
        return Yup.string()
          .required(VALIDATION_MESSAGES.required)
          .nullable()
          .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength());
      default:
        return Yup.mixed();
    }
  }),
  bodyType: Yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return Yup.object().required(VALIDATION_MESSAGES.required).nullable();
      case 'string':
        return Yup.string()
          .required(VALIDATION_MESSAGES.required)
          .nullable()
          .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength());
      default:
        return Yup.mixed();
    }
  }),
});
