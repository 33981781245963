import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteById,
  loadAllCars,
  loadById,
  updateById,
  create,
  loadCarPromoById,
  pause,
  loadCarPhotoReportById,
  reject,
  restore,
} from 'store/reducers/cars/api';
import Snackbar from 'services/Snackbar';
import { AxiosError } from 'axios';
import { ActionsTypes } from 'store/reducers/cars/types';
import { Car, ICarsFilters, IPausePeriod } from 'interfaces/Car';
import { serverErrorText } from 'constants/ServerCode';

const validateError = (err: AxiosError) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }
  const errorCode = error.response.status;
  // @ts-ignore
  const errorMessage: string = serverErrorText[errorCode] || error.message;
  Snackbar.show(errorMessage, 'error');
  return errorMessage;
};

export const loadCars = createAsyncThunk(
  ActionsTypes.LOAD_ALL_CARS,
  async (filters: ICarsFilters, { rejectWithValue }) => {
    try {
      const response = await loadAllCars(filters);
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);

export const loadCarById = createAsyncThunk(ActionsTypes.LOAD_CAR, async (id: string, { rejectWithValue }) => {
  try {
    const response = await loadById(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(validateError(err));
  }
});

export const updateCarById = createAsyncThunk(ActionsTypes.UPDATE_CAR, async (car: Car, { rejectWithValue }) => {
  try {
    const { id, ...fields } = car;
    const response = await updateById(id, { data: fields });
    return response.data;
  } catch (err) {
    return rejectWithValue(validateError(err));
  }
});

export const deleteCarById = createAsyncThunk(ActionsTypes.DELETE_CAR, async (id: string, { rejectWithValue }) => {
  try {
    const response = await deleteById(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(validateError(err));
  }
});

export const createCar = createAsyncThunk(ActionsTypes.CREATE_CAR, async (car: Car, { rejectWithValue }) => {
  try {
    const response = await create({ data: car });
    return response.data;
  } catch (err) {
    return rejectWithValue(validateError(err));
  }
});

export const loadCarPromo = createAsyncThunk(ActionsTypes.LOAD_CAR_PROMO, async (id: string, { rejectWithValue }) => {
  try {
    const response = await loadCarPromoById(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(validateError(err));
  }
});

export const pauseCar = createAsyncThunk(ActionsTypes.PAUSE_CAR, async (data: IPausePeriod, { rejectWithValue }) => {
  try {
    const response = await pause(data.carId, { on: data.on, off: data.off });
    return response.data;
  } catch (err) {
    return rejectWithValue(validateError(err));
  }
});

export const loadCarPhotoReport = createAsyncThunk(
  ActionsTypes.LOAD_PHOTO_REPORT,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await loadCarPhotoReportById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);

export const rejectCarById = createAsyncThunk(ActionsTypes.REJECT_CAR, async (id: string, { rejectWithValue }) => {
  try {
    const response = await reject(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(validateError(err));
  }
});

export const restoreCarById = createAsyncThunk(ActionsTypes.RESTORE_CAR, async (id: string, { rejectWithValue }) => {
  try {
    const response = await restore(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(validateError(err));
  }
});
