import { Sponsor } from 'interfaces/Sponsors';
import { ICarsFilters, Car } from 'interfaces/Car';
import { TLoadEntity } from 'store/baseTypes';
import { IPhoto } from 'interfaces/Media';

export const MAX_NUMBER_OF_PROMOS_PER_REQUEST = 10;

export enum PromotionStatus {
  new = 'новая',
  work = 'активная',
  done = 'завершенная',
}

export interface IPromotionsFilters {
  autoClass?: string | null;
  sponsorName?: string | null;
  status?: string | null;
  city?: string | null;
  period?: string | null;
  searchStr?: string | null;
  start: number | Date | null;
  finish: number | Date | null;
  limit?: number;
  offset?: number;
  name?: string;
}

export interface Promotion {
  readonly id: string;
  name: string;
  city: string;
  distribution: number;
  period: number;
  autoClass: string;
  budget: number;
  eMail: string;
  description: string;
  phone: string;
  status?: PromotionStatus;
  sponsor: Sponsor;
  start: string;
  finish: string;
  files: IPhoto[];
  cars: Car[];
  reportPeriod: number;
  sponsorName?: string;
}

export interface PromotionsCars {
  reviewCarsFilters: ICarsFilters;
  cars: {
    cars: Car[];
    filters: ICarsFilters;
    error: string | null | undefined;
    count: number;
  } & TLoadEntity;
  reviewCars: {
    cars: Car[];
    error: string | null | undefined;
    count: number;
  } & TLoadEntity;
}

export interface PromotionsMaps {
  maps: {
    maps: string;
    error: string | null | undefined;
  } & TLoadEntity;
}

export interface ILoadPromotionCars extends ICarsFilters {
  id: string;
}

export interface IPromoMaket {
  comment: string;
  maketId: string;
}

export interface IUploadPromoMaket extends IPromoMaket {
  id: string;
}
