import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  loadDriverById,
  loadDrivers,
  updateDriverById,
  loadDriverCars,
  createDriverCar,
  freezeDriverById,
} from './actions';
import { initialDriver, initialState } from './types';
import { TState } from 'store/index';
import { IDriversFilters } from 'interfaces/Driver';
import { ICarsFilters } from 'interfaces/Car';

const driversSlice = createSlice({
  name: 'drivers',
  initialState,
  reducers: {
    changeFilters(state, action: PayloadAction<IDriversFilters>) {
      state.listing.filters = { ...state.listing.filters, ...action.payload };
    },
    changeCarsFilters(state, action: PayloadAction<ICarsFilters>) {
      state.cars.filters = { ...state.cars.filters, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadDrivers.pending, (state) => {
      state.listing.entities = [];
      state.listing.loading = true;
      state.listing.loaded = false;
    });
    builder.addCase(loadDrivers.fulfilled, (state, { payload }) => {
      state.listing.entities = payload.data[0];
      state.listing.count = payload.data[1];
      state.listing.loading = false;
      state.listing.loaded = true;
    });
    builder.addCase(loadDrivers.rejected, (state, action) => {
      state.listing.loading = false;
      state.listing.error = action.error.message;
    });

    builder.addCase(loadDriverById.pending, (state) => {
      state.item.entity = initialDriver;
      state.item.loading = true;
      state.item.loaded = false;
    });
    builder.addCase(loadDriverById.fulfilled, (state, { payload }) => {
      state.item.entity = payload.data;
      state.item.loading = false;
      state.item.loaded = true;
    });
    builder.addCase(loadDriverById.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error.message;
    });

    builder.addCase(updateDriverById.pending, (state) => {
      state.item.loading = true;
      state.item.loaded = false;
    });
    builder.addCase(updateDriverById.fulfilled, (state, { payload }) => {
      state.item.entity = payload.data;
      state.item.loading = false;
      state.item.loaded = true;
    });
    builder.addCase(updateDriverById.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error.message;
    });

    builder.addCase(loadDriverCars.pending, (state) => {
      state.cars.cars = [];
      state.cars.loading = true;
      state.cars.loaded = false;
    });
    builder.addCase(loadDriverCars.fulfilled, (state, { payload }) => {
      state.cars.cars = payload[0];
      state.cars.count = payload[1];
      state.cars.loading = false;
      state.cars.loaded = true;
    });
    builder.addCase(loadDriverCars.rejected, (state, action) => {
      state.cars.loading = false;
      state.cars.error = action.error.message;
    });

    builder.addCase(createDriverCar.pending, (state) => {
      state.cars.loading = true;
      state.cars.loaded = false;
    });
    builder.addCase(createDriverCar.fulfilled, (state, { payload }) => {
      state.cars.cars = [...state.cars.cars, payload.data];
      state.cars.count = state.cars.count + 1;
      state.cars.loading = false;
      state.cars.loaded = true;
    });
    builder.addCase(createDriverCar.rejected, (state, action) => {
      state.cars.loading = false;
      state.cars.error = action.error.message;
    });

    builder.addCase(freezeDriverById.pending, (state) => {
      state.item.loading = true;
      state.item.loaded = false;
    });
    builder.addCase(freezeDriverById.fulfilled, (state, { payload }) => {
      state.item.entity = { ...state.item.entity, ...payload.driver };
      state.item.loading = false;
      state.item.loaded = true;
    });
    builder.addCase(freezeDriverById.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error.message;
    });
  },
});

export const { changeFilters, changeCarsFilters } = driversSlice.actions;
export const getDrivers = (state: TState) => state.drivers.listing.entities;
export const getCountDrivers = (state: TState) => state.drivers.listing.count;
export const getDriversLoading = (state: TState) => state.drivers.listing.loading;
export const getDriversFilters = (state: TState) => state.drivers.listing.filters;
export const getDriver = (state: TState) => state.drivers.item.entity;
export const getLoadingDriver = (state: TState) => state.drivers.item.loading;
export const getDriverCars = (state: TState) => state.drivers.cars.cars;
export const getDriverCarsLoading = (state: TState) => state.drivers.cars.loading;
export const getDriverCarsFilter = (state: TState) => state.drivers.cars.filters;
export const getCarsCount = (state: TState) => state.drivers.cars.count;
export default driversSlice.reducer;
