import { axiosClient } from './ApiClient';

class AuthStorage {
  get token(): string {
    return localStorage.getItem('token') as string;
  }

  get isAuthorized(): boolean {
    return !!this.token;
  }

  signIn = (token: string) => {
    localStorage.setItem('token', token);
    axiosClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  };

  signOut = () => {
    localStorage.removeItem('token');
    axiosClient.defaults.headers.common.Authorization = undefined;
  };
}

export const authService = new AuthStorage();
