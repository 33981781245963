import React from 'react';
import cn from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles } from '@material-ui/core';
import { Promo } from 'store/reducers/promo/types';
import { format } from 'date-fns';
import { Edit } from 'assets/images';
import { PromoStatusBadge } from '../status/status';

const formatExpirationDate = (date: string) => format(new Date(date), 'dd.LL.yyyy');

type Props = {
  className?: string;
  promo: Promo;
  onEdit?: () => void;
};

export const PromoDetailsCard = ({ className, promo, onEdit }: Props) => {
  const classes = useStyles();

  return (
    <div className={cn(classes.root, className)}>
      <div className={classes.header}>
        <Typography className={classes.title} component="h3">
          Информация о промо
        </Typography>
        <Edit className={classes.edit} onClick={onEdit} />
      </div>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        <PromoStatusBadge className={classes.status} variant={promo.status} />

        <Grid container direction="column">
          <Grid className={classes.row} item>
            <Typography className={classes.label}>Организация</Typography>
            <Typography className={classes.value}>{promo.name}</Typography>
          </Grid>
          <Grid className={classes.row} item>
            <Typography className={classes.label}>Название</Typography>
            <Typography className={classes.value}>{promo.annotation}</Typography>
          </Grid>
          <Grid className={classes.row} item>
            <Typography className={classes.label}>Истекает</Typography>
            <Typography className={classes.value}>{formatExpirationDate(promo.finish)}</Typography>
          </Grid>
          <Grid className={classes.row} item>
            <Typography className={classes.label}>QR-код</Typography>
            <img className={classes.qrCode} src={promo.QRCode} alt="QR Code" />
          </Grid>
          <Grid className={classes.row} item>
            <Typography className={classes.label}>Промокод</Typography>
            <Typography className={classes.value}>{promo.promoCode}</Typography>
          </Grid>
          <Grid className={classes.row} item>
            <Typography className={classes.label}>Реферальная ссылка:</Typography>
            <Typography className={classes.value}>{promo.refUrl}</Typography>
          </Grid>
        </Grid>
        <Typography className={classes.description}>{promo.description}</Typography>
        {promo.photo && <img className={classes.image} src={promo.photo.URI} alt="Promo Image" />}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: 580,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2),
    },
    title: {
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '21px',
      color: '#000',
      wordBreak: 'break-all',
    },
    edit: {
      marginLeft: 'auto',
      cursor: 'pointer',
    },
    divider: {
      backgroundColor: '#f2f2f2',
    },
    content: {
      position: 'relative',
      padding: theme.spacing(2),
      paddingTop: theme.spacing(7),
    },
    status: {
      position: 'absolute',
      top: theme.spacing(2),
      right: theme.spacing(2),
    },
    row: {
      marginBottom: theme.spacing(2),
    },
    label: {
      marginBottom: theme.spacing(0.25),
      fontWeight: 300,
      fontSize: 16,
      lineHeight: '19px',
      color: '#7d7d7d',
    },
    value: {
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '21px',
      color: '#000',
      wordBreak: 'break-all',
    },
    qrCode: {
      height: 134,
      width: 134,
      objectFit: 'contain',
    },
    description: {
      margin: 0,
      marginBottom: theme.spacing(2),
      borderRadius: 8,
      border: '1px solid #f2f2f2',
      padding: theme.spacing(1),
      fontWeight: 300,
      fontSize: 16,
      lineHeight: '19px',
      color: '#000',
      wordBreak: 'break-all',
    },
    image: {
      aspectRatio: `${2 / 1}`,
      maxWidth: 341,
    },
  }),
);
