import React, { ReactElement } from 'react';
import { Promotion } from 'interfaces/Promotions';
import Status from 'components/shared/status';
import adCompaniesStyles from './styles';

export type OnClickListItem = {
  onEdit: (id: string) => void;
};

export type PropsListItem = {
  item: Promotion;
} & OnClickListItem;

const AdCompaniesListItem = ({
  item: { id, name, autoClass, status, period, budget, distribution },
  onEdit,
}: PropsListItem): ReactElement | null => {
  const classes = adCompaniesStyles();
  const onClickEdit = () => {
    onEdit(id);
  };

  return (
    <div className={classes.container} role="presentation" onClick={onClickEdit}>
      <div className={classes.infoContainer}>
        <div className={classes.bigItem}>
          <div>{name}</div>
        </div>
        <div className={classes.item}>
          <Status status={status} />
        </div>
        <div className={classes.item}>
          <div>{autoClass}</div>
        </div>
        <div className={classes.item}>
          <div>{period}</div>
        </div>
        <div className={classes.item}>
          <div>{distribution}</div>
        </div>
        <div className={classes.item}>
          <div>{budget}</div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AdCompaniesListItem);
