import React, { FC } from 'react';
import { Car } from 'interfaces/Car';
import Checkbox from '@material-ui/core/Checkbox';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import selectCarsStyles from './styles';

export type OnClickListItem = {
  onSelect: (id: string) => void;
};

export type PropsListItem = {
  item: Car;
  checked?: boolean;
} & OnClickListItem;

const SelectCarListItem: FC<PropsListItem> = ({
  item: { id, mark, city, bodyType, color, year, autoClass, crash },
  checked,
  onSelect,
}: PropsListItem) => {
  const classes = selectCarsStyles();

  const onClickSelect = () => {
    onSelect(id);
  };

  const isCrash = crash ? 'да' : 'нет';

  return (
    <div className={classes.mainContainer} onClick={onClickSelect}>
      <Checkbox
        checked={checked}
        icon={<CircleUnchecked className={classes.checkbox} />}
        checkedIcon={<CircleChecked className={classes.checkbox} />}
      />
      <div className={classes.container} role="presentation">
        <div className={classes.infoContainer}>
          <div className={classes.item}>
            <div>{mark}</div>
          </div>
          <div className={classes.item}>
            <div>{city}</div>
          </div>
          <div className={classes.item}>
            <div>{bodyType}</div>
          </div>
          <div className={classes.item}>
            <div>{color}</div>
          </div>
          <div className={classes.item}>
            <div>{year}</div>
          </div>
          <div className={classes.item}>
            <div>{autoClass}</div>
          </div>
          <div className={classes.item}>
            <div>{isCrash}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SelectCarListItem);
