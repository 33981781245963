import React from 'react';
import { Grid, createStyles, makeStyles } from '@material-ui/core';
import { PromoListItem } from './list-item';
import { PromoListHeader } from './header';
import { Promo } from 'store/reducers/promo/types';

type Props = {
  className?: string;
  items: Promo[];
  onItemClick?: (id: string) => void;
};

export const PromoList = ({ className, items, onItemClick }: Props) => {
  const classes = useStyles();

  const handleItemClick = (id: string) => () => onItemClick?.(id);

  return (
    <Grid className={className} container direction="column">
      <PromoListHeader />

      {items.map((item) => (
        <PromoListItem
          key={item.id}
          className={classes.row}
          status={item.status}
          annotation={item.annotation}
          name={item.name}
          expirationDate={item.finish}
          onClick={handleItemClick(item.id)}
        />
      ))}
    </Grid>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    row: {
      marginBottom: theme.spacing(1),

      '&:last-of-type': {
        marginBottom: 0,
      },
    },
  }),
);
