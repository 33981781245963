import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import TextFieldInput from 'components/shared/inputs/TextFieldInput';
import CustomAutocompleteSelect from 'components/shared/select/CustomAutocompleteSelect';
import PhotoInput from 'components/shared/inputs/PhotoInput';
import { useAppDispatch } from 'store';
import { updateCarById } from 'store/reducers/cars/actions';
import { createDriverCar } from 'store/reducers/drivers/actions';
import { Car } from 'interfaces/Car';
import { CarEditForm } from 'constants/Forms';
import { axiosClient } from 'services/ApiClient';
import { options, crashOptions } from 'utils/options';
import { yupResolver } from '@hookform/resolvers/yup';
import { ValidationSchema } from 'components/containers/cars/item/elements/edit/ValidationSchema';
import { AxiosError } from 'axios';
import Snackbar from 'services/Snackbar';
import { useSelector } from 'react-redux';
import { getBodies, getCities, getClasses, getColors, getMarks, getModels } from 'store/reducers/helpers';
import { IHelper } from '../../../../../../store/reducers/helpers/types';
import { loadModelsByMarkHelper } from '../../../../../../store/reducers/helpers/actions';

type TCarEditProps = {
  car: Car;
  onSave: () => void;
  driverId: string;
  isCreate?: boolean;
};

const DEFAULT_MODELS: IHelper[] = [];

const CarEdit: React.FC<TCarEditProps> = ({ car, onSave, isCreate, driverId }) => {
  const dispatch = useAppDispatch();
  const [avatar, setAvatar] = useState(car.avatar);
  const [isDisabledModel, setDisabledModel] = useState(!!car.mark);
  const [models, setModels] = useState(DEFAULT_MODELS);

  const helperMarks = useSelector(getMarks);
  const getMarkValue = (mark: string) => helperMarks.entities.find((item) => item.displayName === mark);

  const formattedFormData = {
    ...car,
    mark: getMarkValue(car.mark),
  };

  const [mark, setMark] = useState(formattedFormData.mark);

  const { handleSubmit, control, errors, watch, setValue } = useForm<any>({
    defaultValues: formattedFormData,
    resolver: yupResolver(ValidationSchema),
  });

  const defaultModelValue = async () => {
    const helperModels = await dispatch(loadModelsByMarkHelper(Number(formattedFormData?.mark?.id)));
    const value = helperModels.payload.data.find((item: any) => item.displayName === formattedFormData.model);
    if (watchMark !== null) setValue('model', value);
  };

  useEffect(() => {
    if (formattedFormData.model && formattedFormData.mark) {
      defaultModelValue();
    }
  }, [car]);

  const handleMark = async (mark: number) => {
    setDisabledModel(!mark);
    const helperModels = await dispatch(loadModelsByMarkHelper(mark));
    handleHelperModels(helperModels.payload.data);
  };

  const handleHelperModels = (data: any) => setModels(data);

  const helperCities = useSelector(getCities);
  const getCityValue = (city: string) => helperCities.entities.find((item) => item.displayName === city);

  const helperAutoClasses = useSelector(getClasses);
  const getAutoClassValue = (autoClass: string) =>
    helperAutoClasses.entities.find((item) => item.displayName === autoClass);

  const helperColors = useSelector(getColors);
  const getColorValue = (autoClass: string) => helperColors.entities.find((item) => item.displayName === autoClass);

  const helperBodyTypes = useSelector(getBodies);
  const getBodyTypeValue = (bodyType: string) => helperBodyTypes.entities.find((item) => item.displayName === bodyType);

  const getCrashValue = (crash: boolean) => crashOptions.find((item) => item.value === crash);

  const onSubmit = useCallback(
    async (data) => {
      data.city = data.city.displayName || car.city;
      data.mark = data.mark.displayName || car.mark;
      data.model = data.model.displayName || car.model;
      data.autoClass = data.autoClass.displayName || car.autoClass;
      data.color = data.color.displayName || car.color;
      data.bodyType = data.bodyType.displayName || car.bodyType;
      data.crash = typeof data.crash === 'object' ? data.crash.value : car.crash;
      if (isCreate) {
        await dispatch(
          createDriverCar({
            driverId,
            car: { ...data, year: data.year.value || data.year, avatar: avatar },
          }),
        );
        onSave();
      } else {
        dispatch(
          updateCarById({
            ...data,
            id: car.id,
            year: data.year.value || data.year,
            avatar: avatar,
          }),
        );
        onSave();
      }
    },
    [car.crash, car.autoClass, car.bodyType, car.city, car.color, avatar, driverId, onSave, car.id, isCreate, dispatch],
  );

  const getYearValue = (value: number) => {
    return {
      title: value?.toString(),
      value: value,
    };
  };

  const onDeleteImage = () => {
    setAvatar('');
  };

  const photoInputChangeHandler = async (e: React.SyntheticEvent<EventTarget>) => {
    const formData = new FormData();
    const formInput = (e.target as HTMLFormElement).files[0];
    formData.append('file', formInput);
    try {
      const response = await axiosClient.post(`/photos/avatar`, formData, {
        responseType: 'text',
      });
      setAvatar(response.data);
    } catch (err) {
      const error = err as AxiosError;

      if (!error.response) throw err;

      const errorMessage: string = error.response.data.error;
      Snackbar.show(errorMessage, 'error');
    }
  };

  const watchMark = watch('mark');

  useEffect(() => {
    if (!watchMark) {
      setModels(DEFAULT_MODELS);
      setValue('model', null);
      setDisabledModel(true);
      return;
    }
    if (watchMark !== mark) {
      setValue('model', null);
      setMark(watchMark);
    }
    handleMark(watchMark?.id || watchMark || null);
  }, [watchMark, setModels]);

  return (
    <form id={CarEditForm} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PhotoInput url={avatar} onDeleteImage={onDeleteImage} title="Фото ТС" onChange={photoInputChangeHandler} />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="mark"
            control={control}
            render={(props) => (
              <CustomAutocompleteSelect
                name="mark"
                value={props.value}
                options={helperMarks.entities}
                title="Марка:"
                onChange={props.onChange}
                errors={errors}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="model"
            control={control}
            render={(props) => (
              <CustomAutocompleteSelect
                name="model"
                value={props.value}
                options={models}
                title="Модель:"
                onChange={props.onChange}
                errors={errors}
                disabled={isDisabledModel}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="autoClass"
            control={control}
            render={(props) => (
              <CustomAutocompleteSelect
                name="autoClass"
                value={typeof props.value === 'object' ? props.value : getAutoClassValue(props.value)}
                options={helperAutoClasses.entities}
                title="Класс авто:"
                errors={errors}
                onChange={props.onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="year"
            control={control}
            render={(props) => (
              <CustomAutocompleteSelect
                value={typeof props.value === 'object' ? props.value : getYearValue(props.value)}
                options={options()}
                title="Год выпуска"
                onChange={props.onChange}
                errors={errors}
                name="year"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={TextFieldInput}
            field={{ title: 'Гос. номер' }}
            name="registration"
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller as={TextFieldInput} field={{ title: 'VIN-номер' }} name="vin" errors={errors} control={control} />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="crash"
            control={control}
            render={(props) => (
              <CustomAutocompleteSelect
                name="crash"
                value={typeof props.value === 'object' ? props.value : getCrashValue(props.value)}
                options={crashOptions}
                title="Участвовал в ДТП:"
                errors={errors}
                onChange={props.onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="city"
            control={control}
            render={(props) => (
              <CustomAutocompleteSelect
                name="city"
                value={typeof props.value === 'object' ? props.value : getCityValue(props.value)}
                options={helperCities.entities}
                title="Город:"
                errors={errors}
                onChange={props.onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="color"
            control={control}
            render={(props) => (
              <CustomAutocompleteSelect
                name="color"
                value={typeof props.value === 'object' ? props.value : getColorValue(props.value)}
                options={helperColors.entities}
                title="Цвет:"
                errors={errors}
                onChange={props.onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="bodyType"
            control={control}
            render={(props) => (
              <CustomAutocompleteSelect
                name="bodyType"
                value={typeof props.value === 'object' ? props.value : getBodyTypeValue(props.value)}
                options={helperBodyTypes.entities}
                title="Тип кузова:"
                errors={errors}
                onChange={props.onChange}
              />
            )}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default React.memo(CarEdit);
