import React from 'react';
import cn from 'classnames';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

type AcceptButtonProps = {
  className?: string;
  title?: string;
  onClick?: () => void;
  formId?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  submitButton: {
    borderRadius: 8,
    border: '1px solid #2D9CDB',
    color: theme.palette.secondary.main,
    fontSize: 15,
    padding: '0px 5px',
    textTransform: 'none',
    minWidth: 104,
    fontWeight: 'normal',
    height: 32,
    '&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.background.default,
      border: '1px solid #2D9CDB',
    },
  },
}));

const AcceptButton: React.FC<AcceptButtonProps> = ({ className, title, onClick, formId }) => {
  const classes = useStyles();

  return (
    <Button
      className={cn(className, classes.submitButton)}
      type="submit"
      variant="outlined"
      form={formId}
      onClick={onClick}
    >
      {title || 'Применить'}
    </Button>
  );
};

export default AcceptButton;
