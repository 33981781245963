import React from 'react';

import { Theme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

type AcceptButtonProps = {
  title?: string;
  onClick: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  submitButton: {
    borderRadius: 8,
    border: '1px solid #CC2631',
    color: theme.palette.secondary.light,
    fontSize: 15,
    padding: '0px 8px',
    textTransform: 'none',
    minWidth: 104,
    height: 32,
    fontWeight: 'normal',
    '&:hover': {
      background: theme.palette.secondary.light,
      color: theme.palette.background.default,
      border: '1px solid #CC2631',
    },
  },
}));

const DeleteButton: React.FC<AcceptButtonProps> = ({ title, onClick }) => {
  const classes = useStyles();

  return (
    <Button className={classes.submitButton} variant="outlined" type="submit" onClick={onClick}>
      {title || 'Удалить'}
    </Button>
  );
};

export default DeleteButton;
