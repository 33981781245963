import React, { useRef } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import useDebounce from 'utils/useDebounce';
import OutlinedInput, { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) => ({
  inputRoot: {
    borderRadius: '16px !important',
  },
  input: {
    padding: 7,
  },
  notchedOutline: {
    borderColor: theme.palette.primary.dark,
  },
  icon: {
    color: theme.palette.primary.dark,
  },
}));

type SearchProps = {
  defaultValue?: string;
  onChangeSearch: (value: string) => void;
  delay?: number;
  placeholder?: string;
  fullWidth?: boolean;
  disabled?: boolean;
} & OutlinedInputProps;

const Search: React.FC<SearchProps> = ({
  defaultValue = '',
  onChangeSearch,
  delay = 500,
  placeholder = 'Поиск',
  fullWidth,
  disabled,
}) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = React.useState(defaultValue);
  const debouncedSearchTerm = useDebounce<string>(searchTerm, delay);

  const isMountRef = useRef(false);

  React.useEffect(() => {
    if (isMountRef.current) {
      onChangeSearch(searchTerm);
    }
    isMountRef.current = true;
  }, [debouncedSearchTerm]);

  const onClearCLick = React.useCallback((): void => {
    setSearchTerm('');
  }, [setSearchTerm]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<{ value: string }>): void => {
      const { value } = event.target;

      setSearchTerm(value);

      if (value === '') {
        onClearCLick();
      }
    },
    [setSearchTerm, onClearCLick],
  );

  return (
    <OutlinedInput
      value={searchTerm}
      onChange={handleChange}
      placeholder={placeholder}
      inputProps={{ 'aria-label': 'search' }}
      notched={false}
      fullWidth={fullWidth}
      disabled={disabled}
      classes={{
        root: classes.inputRoot,
        input: classes.input,
        notchedOutline: classes.notchedOutline,
      }}
      endAdornment={
        <>
          {!searchTerm && <SearchIcon className={classes.icon} />}

          {searchTerm && (
            <IconButton onClick={onClearCLick} size="small">
              <Close className={classes.icon} />
            </IconButton>
          )}
        </>
      }
    />
  );
};

export default Search;
