import { Driver } from 'interfaces/Driver';
import { Promotion } from 'interfaces/Promotions';

export const MAX_NUMBER_OF_CAR_PER_REQUEST = 10;

export enum CarStatus {
  Review = 'на рассмотрении',
  Busy = 'в работе',
  Hold = 'в ожидании',
  Reject = 'отклонён',
  Pause = 'на паузе',
}

export enum CarSegment {
  premium = 'premium',
}

export const carSegmentNames: { [key in CarSegment]: string } = {
  premium: 'Премиум',
};

export interface Car {
  readonly id: string;
  city: string;
  mark: string;
  model: string;
  year: number;
  bodyType: string;
  color: string;
  vin: string;
  crash: boolean;
  photo: string;
  autoClass: string;
  status: CarStatus;
  segment: CarSegment;
  registration: string;
  avatar: string;
  driver: Driver;
  promotion: Promotion | null;
  pause: { on: string; off: string };
}

export interface ICarsFilters {
  searchStr?: string | null;
  city?: string | null;
  mark?: string | null;
  model?: string | null;
  autoClass?: string | null;
  year?: number | null;
  bodyType?: string | null;
  color?: string | null;
  vin?: string | null;
  crash?: boolean | null;
  status?: string | null;
  limit?: number;
  offset?: number;
}

export const initialCarsFilters: ICarsFilters = {
  searchStr: null,
  city: null,
  mark: null,
  model: null,
  autoClass: null,
  year: null,
  bodyType: null,
  color: null,
  vin: null,
  crash: null,
  status: null,
  limit: MAX_NUMBER_OF_CAR_PER_REQUEST,
  offset: undefined,
};
export interface IPeriod {
  on: string;
  off: string;
}

export interface IPausePeriod extends IPeriod {
  carId: string;
}

export interface SignCarForPromo {
  carId: string;
  promoId: string;
}
