import * as Yup from 'yup';
import VALIDATION_MESSAGES, { DEFAULT_MAX_TEXT_LENGTH } from 'constants/ValidationsMessage';

export const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required(VALIDATION_MESSAGES.required)
    .nullable()
    .min(1, VALIDATION_MESSAGES.minLength(1))
    .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength()),
  /*  lastName: Yup.string()
    .required(VALIDATION_MESSAGES.required)
    .nullable()
    .min(1, VALIDATION_MESSAGES.minLength(1))
    .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength()),
  firstName: Yup.string()
    .required(VALIDATION_MESSAGES.required)
    .nullable()
    .min(1, VALIDATION_MESSAGES.minLength(1))
    .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength()),
  fatherName: Yup.string()
    .required(VALIDATION_MESSAGES.required)
    .nullable()
    .min(1, VALIDATION_MESSAGES.minLength(1))
    .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength()),*/
  city: Yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return Yup.object().nullable();
      case 'string':
        return Yup.string().nullable().max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength());
      default:
        return Yup.mixed();
    }
  }),
  phone: Yup.string()
    .required(VALIDATION_MESSAGES.required)
    .nullable()
    .min(1, VALIDATION_MESSAGES.minLength(1))
    .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength()),
  eMail: Yup.string()
    .nullable()
    .email(VALIDATION_MESSAGES.incorrectEmail)
    .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength())
    .transform((val) => val || null),
  taxStatus: Yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return Yup.object().nullable();
      case 'string':
        return Yup.string().nullable().max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength());
      default:
        return Yup.mixed().nullable();
    }
  }),
  beneficiary: Yup.string().nullable().max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength()),
  inn: Yup.string()
    .when('taxStatus', {
      is: (taxStatus: any) => taxStatus.displayName == 'Физ. лицо',
      then: Yup.string().matches(/^[0-9]{12}$/, {
        message: VALIDATION_MESSAGES.constLength(12),
        excludeEmptyString: true,
      }),
    })
    .when('taxStatus', {
      is: (taxStatus: any) => taxStatus.displayName == 'Юр. лицо',
      then: Yup.string().matches(/^[0-9]{10}$/, {
        message: VALIDATION_MESSAGES.constLength(10),
        excludeEmptyString: true,
      }),
    })
    .nullable()
    .transform((val) => val || null),
  kpp: Yup.string()
    .matches(/^[0-9]{9}$/, { message: VALIDATION_MESSAGES.constLength(9), excludeEmptyString: true })
    .nullable()
    .transform((val) => val || null),
  bik: Yup.string()
    .matches(/^[0-9]{9}$/, { message: VALIDATION_MESSAGES.constLength(9), excludeEmptyString: true })
    .nullable()
    .transform((val) => val || null),
  account: Yup.string()
    .matches(/^[0-9]{20}$/, {
      message: VALIDATION_MESSAGES.constLength(20),
      excludeEmptyString: true,
    })
    .nullable()
    .transform((val) => val || null),
});
