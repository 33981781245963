import React, { FC } from 'react';
import { Car } from 'interfaces/Car';
import selectCarsStyles from './styles';
import SelectCarListTitle from './SelectCarListTitle';
import SelectCarListItem from './SelectCarListItem';

export type OnClickListItem = {
  onSelect: (id: string) => void;
};

export type PropsListItem = {
  items: Car[];
  carIds: string[];
} & OnClickListItem;

const SelectCarList: FC<PropsListItem> = ({ items, onSelect, carIds }) => {
  const classes = selectCarsStyles();

  return (
    <>
      <SelectCarListTitle />
      {items.map((car: Car) => (
        <div key={car.id} className={classes.tileItem} aria-hidden="true">
          <SelectCarListItem onSelect={onSelect} item={car} checked={carIds.includes(car.id)} />
        </div>
      ))}
    </>
  );
};

export default React.memo(SelectCarList);
