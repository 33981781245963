import { Car, CarSegment, ICarsFilters, CarStatus, initialCarsFilters } from 'interfaces/Car';
import { initialDriver } from 'store/reducers/drivers/types';
import { Promotion } from 'interfaces/Promotions';

export const initialCar: Car = {
  id: '',
  city: '',
  mark: '',
  model: '',
  year: new Date().getFullYear(),
  bodyType: '',
  color: '',
  vin: '',
  crash: false,
  photo: '',
  autoClass: '',
  status: CarStatus.Review,
  segment: CarSegment.premium,
  registration: '',
  avatar: '',
  driver: initialDriver,
  promotion: null,
  pause: {
    on: '',
    off: '',
  },
};

export enum ActionsTypes {
  LOAD_ALL_CARS = 'LOAD_ALL_CARS',
  LOAD_CAR = 'LOAD_CAR',
  CREATE_CAR = 'CREATE_CAR',
  UPDATE_CAR = 'UPDATE_CAR',
  DELETE_CAR = 'DELETE_CAR',
  SIGN_CAR_FOR_PROMO = 'SIGN_CAR_FOR_PROMO',
  LOAD_CAR_PROMO = 'LOAD_CAR_PROMO',
  PAUSE_CAR = 'PAUSE_CAR',
  REJECT_CAR = 'REJECT_CAR',
  RESTORE_CAR = 'RESTORE_CAR',
  LOAD_PHOTO_REPORT = 'LOAD_PHOTO_REPORT ',
}

export interface CarsState {
  listFilters: ICarsFilters; // for persist
  listing: {
    error: string | null | undefined;
    entities: Car[];
    count: number;
    filters: ICarsFilters;
    loading: boolean;
    loaded: boolean;
  };
  item: {
    error: string | null | undefined;
    entity: Car;
    promos: Promotion[];
    photoReports: any;
    loading: boolean;
    loaded: boolean;
  };
}

export const initialState: CarsState = {
  listFilters: initialCarsFilters,
  listing: {
    entities: [],
    count: 0,
    filters: initialCarsFilters,
    loading: false,
    loaded: false,
    error: null,
  },
  item: {
    entity: initialCar,
    promos: [],
    photoReports: [],
    loading: false,
    loaded: false,
    error: null,
  },
};
