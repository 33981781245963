import React, { ReactElement } from 'react';
import { Car } from 'interfaces/Car';
import CarListItem from './CarListItem';
import CarListTitle from './CarListTitle';
import carsStyles from './styles';

export type OnClickListItem = {
  onEdit: (id: string) => void;
};

export type PropsListItem = {
  items: Car[];
} & OnClickListItem;

const CarList = ({ items, onEdit }: PropsListItem): ReactElement | null => {
  const classes = carsStyles();

  return (
    <>
      <CarListTitle />
      {items.map((car: Car) => (
        <div key={car.id} className={classes.tileItem} aria-hidden="true">
          <CarListItem onEdit={onEdit} item={car} />
        </div>
      ))}
    </>
  );
};

export default React.memo(CarList);
