import React, { ReactElement } from 'react';
import classnames from 'classnames';
import AdvertisersStyles from './styles';

const AdvertiserListTitle = (): ReactElement | null => {
  const classes = AdvertisersStyles();
  return (
    <div className={classes.infoContainer} style={{ paddingTop: 0 }}>
      <div className={classnames(classes.item, classes.title)}>
        <div>Организация</div>
      </div>
      <div className={classnames(classes.item, classes.title)}>
        <div>Город</div>
      </div>
      <div className={classnames(classes.item, classes.title)}>
        <div>E-mail</div>
      </div>
      <div className={classnames(classes.item, classes.title)}>
        <div>Активных кампаний</div>
      </div>
    </div>
  );
};

export default React.memo(AdvertiserListTitle);
