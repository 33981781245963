import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from 'constants/Routes';
import { useDispatch, useSelector } from 'react-redux';
import BaseLayout from 'components/baseLayout';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DriverHeader from 'components/containers/drivers/item/elements/header';
import ColumnContainer from 'components/layouts/columnContainer';
import CarsListing from 'components/containers/drivers/item/elements/cars';
import DriverView from 'components/containers/drivers/item/elements/view';
import Filters from 'components/containers/drivers/item/elements/cars/filters';
import ConfirmActionModal from 'components/shared/modal/ConfirmActionModal';
import Modal from 'components/shared/modal';
import { CarEditForm, DriverEditForm } from 'constants/Forms';
import { DriverEdit } from 'components/containers/drivers/item/elements/edit';
import CarEdit from 'components/containers/cars/item/elements/edit';
import { initialCar } from 'store/reducers/cars/types';
import { getDriver, getLoadingDriver } from 'store/reducers/drivers';
import { loadDriverById, deleteDriverById, loadDriverCars, freezeDriverById } from 'store/reducers/drivers/actions';
import Loader from 'components/shared/loader';
import { DriverStatus } from 'interfaces/Driver';
import { getFilterOffset, getPageByOffset } from 'utils/common';
import { MAX_NUMBER_OF_CAR_PER_REQUEST } from 'interfaces/Car';
import {
  changeCarsFilters,
  getCarsCount,
  getDriverCars,
  getDriverCarsFilter,
  getDriverCarsLoading,
} from 'store/reducers/drivers';
import Pagination from '@material-ui/lab/Pagination';
import { loadCarCitiesHelper, loadCarMarksHelper, loadTaxStatusesHelper } from 'store/reducers/helpers/actions';
import { changeFilters } from 'store/reducers/drivers';
import { initialDriversFilters } from 'store/reducers/drivers/types';

const titles = {
  driver: 'Данные водителя ТС',
  cars: 'Транспортные средства',
};

const DriverContainer: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const driver = useSelector(getDriver);
  const isLoadingDriver = useSelector(getLoadingDriver);

  const isLoadingDriverCars = useSelector(getDriverCarsLoading);
  const driverCars = useSelector(getDriverCars);
  const carsFilters = useSelector(getDriverCarsFilter);
  const carsCount = useSelector(getCarsCount);
  const pageCount = Math.ceil(carsCount / MAX_NUMBER_OF_CAR_PER_REQUEST);

  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openFreezeModal, setOpenFreezeModal] = React.useState(false);
  const [openCreateCarModal, setOpenCreateCarModal] = React.useState(false);
  const [page, setPage] = useState<number>(getPageByOffset(carsFilters.offset));

  const handleChangePage = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
      const offset = getFilterOffset(value, MAX_NUMBER_OF_CAR_PER_REQUEST);
      dispatch(changeCarsFilters({ ...carsFilters, offset }));
    },
    [dispatch, carsFilters],
  );
  const handleCloseEditModal = () => setOpenEditModal(false);
  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);
  const handleOpenFreezeModal = () => setOpenFreezeModal(true);
  const handleCloseFreezeModal = () => setOpenFreezeModal(false);
  const handleOpenCreateCarModal = () => setOpenCreateCarModal(true);
  const handleCloseCreateCarModal = () => setOpenCreateCarModal(false);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const onClickBack = () => {
    history.push(Routes.drivers);
  };

  const onClickDelete = useCallback(async () => {
    await dispatch(deleteDriverById(id));
    dispatch(changeFilters(initialDriversFilters));
    history.push(Routes.drivers);
  }, [history, id, dispatch]);

  const onClickFreeze = useCallback(() => {
    dispatch(freezeDriverById(id));
    handleCloseFreezeModal();
  }, [dispatch, id]);

  const onChangeFilters = useCallback(
    (filters) => {
      dispatch(changeCarsFilters(filters));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(loadTaxStatusesHelper());
    dispatch(loadCarCitiesHelper());
    dispatch(loadCarMarksHelper());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadDriverById(id));
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(loadDriverCars({ id, ...carsFilters }));
  }, [carsFilters, id, dispatch]);

  const onCreateCar = useCallback(async () => {
    handleCloseCreateCarModal();
    dispatch(loadDriverById(id));
  }, [id, dispatch]);

  return (
    <BaseLayout>
      <Grid>
        <Grid item container xs={12} className={classes.padding}>
          <DriverHeader
            title={driver.name}
            actionTitle={driver.status === DriverStatus.frozen ? 'Разморозить' : 'Заморозить'}
            onClickBack={onClickBack}
            onClickHold={handleOpenFreezeModal}
            onClickDelete={handleOpenDeleteModal}
          />
        </Grid>
        <Grid item container xs={12}>
          <Grid item lg={4} xs={12} className={classes.borderTop}>
            <ColumnContainer title={titles.driver} onClickIcon={handleOpenEditModal}>
              {isLoadingDriver ? <Loader show /> : <DriverView driver={driver} />}
            </ColumnContainer>
          </Grid>
          <Grid item lg={8} xs={12} className={classes.border}>
            <ColumnContainer
              title={titles.cars}
              page={page}
              count={carsCount}
              onClickCreate={handleOpenCreateCarModal}
              handlePage={handleChange}
            >
              <Filters filters={carsFilters} onChangeFilters={onChangeFilters} />
              {isLoadingDriverCars ? <Loader show /> : <CarsListing cars={driverCars} />}
              {pageCount > 1 && (
                <Pagination
                  className={classes.pagination}
                  count={pageCount}
                  shape="rounded"
                  page={page}
                  onChange={handleChangePage}
                />
              )}
            </ColumnContainer>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        actionTitle="Сохранить"
        formId={DriverEditForm}
        modalTitle="Водитель"
      >
        {isLoadingDriver ? <Loader show /> : <DriverEdit onSave={handleCloseEditModal} driver={driver} />}
      </Modal>
      <ConfirmActionModal
        openModalStatus={openDeleteModal}
        buttonClose={handleCloseDeleteModal}
        buttonDelete={onClickDelete}
        actionText="Удалить"
        text="водителя"
      />
      <ConfirmActionModal
        openModalStatus={openFreezeModal}
        buttonClose={handleCloseFreezeModal}
        buttonDelete={onClickFreeze}
        cancelable
        actionText={driver.status === DriverStatus.frozen ? 'Разморозить' : 'Заморозить'}
        text="аккаунт"
      />
      <Modal
        open={openCreateCarModal}
        onClose={handleCloseCreateCarModal}
        actionTitle="Сохранить"
        formId={CarEditForm}
        modalTitle="Транспортное средство"
      >
        <CarEdit isCreate onSave={onCreateCar} driverId={driver.id} car={initialCar} />
      </Modal>
    </BaseLayout>
  );
};

export default DriverContainer;

const useStyles = makeStyles((theme: Theme) => ({
  padding: {
    padding: 16,
  },
  borderTop: {
    borderTop: '1px solid #F2F2F2',
  },
  border: {
    borderLeft: '1px solid #F2F2F2',
    borderRight: '1px solid #F2F2F2',
    borderTop: '1px solid #F2F2F2',
    [theme.breakpoints.down('md')]: {
      borderLeft: 'none',
      borderRight: 'none',
    },
  },
  pagination: {
    float: 'right',
    marginBottom: theme.spacing(1),
  },
}));
