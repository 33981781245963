import React from 'react';

import { Theme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

type ClearButtonProps = {
  title?: string;
  onClick: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  submitButton: {
    borderRadius: 8,
    border: 'none',
    color: theme.palette.text.secondary,
    fontSize: 15,
    padding: 0,
    textTransform: 'none',
    minWidth: 104,
    height: 32,
    fontWeight: 'normal',
  },
}));

const ClearButton: React.FC<ClearButtonProps> = ({ title, onClick }) => {
  const classes = useStyles();

  return (
    <Button className={classes.submitButton} variant="outlined" onClick={onClick}>
      {title || 'Сбросить'}
    </Button>
  );
};

export default ClearButton;
