export interface IOptions {
  title: string;
  value: number | string | boolean;
}
export const crashOptions = [
  {
    title: 'Да',
    value: true,
  },
  {
    title: 'Нет',
    value: false,
  },
];

export const options = (): IOptions[] => {
  const yearOptions = [];
  let i = 1960;
  const year = new Date().getFullYear();
  while (i <= year) {
    yearOptions.push({ title: i.toString(), value: i });
    i++;
  }
  return yearOptions;
};
