import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import * as reducers from 'store/reducers';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { combineReducers } from 'redux';
import { useDispatch } from 'react-redux';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'sideBar', 'users'],
};

const carsConfig = {
  key: 'cars',
  storage,
  whitelist: ['listFilters'],
};

const promotionsConfig = {
  key: 'promotions',
  storage,
  whitelist: ['reviewCarsFilters'],
};

const rootReducer = combineReducers({
  ...reducers,
  cars: persistReducer(carsConfig, reducers.cars),
  promotions: persistReducer(promotionsConfig, reducers.promotions),
});

export type TState = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export { store, persistor };
