import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import TextFieldInput from 'components/shared/inputs/TextFieldInput';
import { useAppDispatch } from 'store';
import { ResetPasswordForm } from 'constants/Forms';
import { updateUserPassword } from 'store/reducers/users/actions';
import { yupResolver } from '@hookform/resolvers/yup';
import { ValidationSchemaResetPassword } from './ValidationSchemaResetPassword';

interface IFormInputs {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

type TResetPasswordProps = {
  onSave: () => void;
};

const ResetPassword: React.FC<TResetPasswordProps> = ({ onSave }) => {
  const dispatch = useAppDispatch();

  const { handleSubmit, errors, setError, control } = useForm<IFormInputs>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(ValidationSchemaResetPassword),
    context: undefined,
    criteriaMode: 'all',
    shouldFocusError: true,
    shouldUnregister: true,
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    },
  });

  const onSubmit = useCallback(
    async (data: IFormInputs) => {
      if (data.newPassword === data.newPasswordConfirm) {
        try {
          await dispatch(
            updateUserPassword({
              oldPassword: data.oldPassword,
              password: data.newPassword,
            }),
          ).then((result) => {
            if (result.payload === 201) {
              onSave();
            }
            if (result.payload?.statusCode === 1001) {
              setError('oldPassword', {
                type: 'manual',
                message: 'Неверный пароль',
              });
            }
          });
        } catch (err) {}
      } else {
        setError('newPassword', {
          type: 'manual',
          message: 'Пароли не совпадают',
        });
      }
    },
    [dispatch, setError, useForm],
  );

  return (
    <form id={ResetPasswordForm} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            as={TextFieldInput}
            field={{ title: 'Старый пароль:', type: 'password' }}
            name="oldPassword"
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={TextFieldInput}
            field={{ title: 'Новый пароль:', type: 'password' }}
            name="newPassword"
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={TextFieldInput}
            field={{ title: 'Повторите пароль:', type: 'password' }}
            name="newPasswordConfirm"
            errors={errors}
            control={control}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default React.memo(ResetPassword);
