import React, { ReactElement } from 'react';
import AdvertisersStyles from './styles';
import { Sponsor } from 'interfaces/Sponsors';

export type OnClickListItem = {
  onEdit: (id: string) => void;
};

export type PropsListItem = {
  item: Sponsor;
} & OnClickListItem;

const AdvertiserListItem = ({
  item: { id, name, city, eMail, __promotions__ },
  onEdit,
}: PropsListItem): ReactElement | null => {
  const classes = AdvertisersStyles();
  const onClickEdit = () => {
    onEdit(id);
  };

  return (
    <div className={classes.container} role="presentation" onClick={onClickEdit}>
      <div className={classes.infoContainer}>
        <div className={classes.item}>
          <div>{name}</div>
        </div>
        <div className={classes.item}>
          <div>{city}</div>
        </div>
        <div className={classes.item}>
          <div>{eMail}</div>
        </div>
        <div className={classes.item}>
          <div>{__promotions__.length}</div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AdvertiserListItem);
