import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import DeleteButton from 'components/shared/buttons/DeleteButton';
import { Back } from 'assets/images';

type TProps = {
  onClickDelete: () => void;
  onClickBack: () => void;
  title: string;
};

const AdvertiserHeader: FC<TProps> = ({ title, onClickBack, onClickDelete }) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <div className={classes.titleContainer} onClick={onClickBack}>
        <Back />
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
      </div>
      <div className={classes.buttons}>
        <DeleteButton onClick={onClickDelete} />
      </div>
    </div>
  );
};

export default React.memo(AdvertiserHeader);

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    flexWrap: 'wrap',
  },
  titleContainer: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
  },
  title: {
    paddingLeft: 10,
  },
  buttons: {
    display: 'flex',
  },
}));
