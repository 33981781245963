import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { Car } from 'assets/images';

type TAvatarProps = {
  url?: string;
};

const EntityAvatar: FC<TAvatarProps> = ({ url }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Avatar variant="rounded" alt="avatar" src={url || Car} className={classes.avatar} />
    </div>
  );
};

export default React.memo(EntityAvatar);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    height: 134,
    width: 134,
  },
  avatar: {
    width: 134,
    height: 134,
  },
}));
