import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { AttachIcon, CloseIcon } from 'assets/images';
import { IPhoto } from 'interfaces/Media';
import Loader from 'components/shared/loader';

export type TPhotoInputProps = {
  title: string;
  onChange: (e: React.SyntheticEvent<EventTarget>) => void;
  files: IPhoto[];
  onDeleteImage: (id: string) => void;
  loading: boolean;
};

const FileInput: React.FC<TPhotoInputProps> = ({ title, files, onChange, onDeleteImage, loading, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <input {...props} type="file" id="filelId" hidden accept="image/jpeg" onChange={onChange} />
      <div style={{ maxWidth: 240 }} className={classes.label}>
        {title}
      </div>
      <div className={classes.textContainer}>
        <div style={{ display: 'flex' }}>
          {files.map((photo) => (
            <div key={photo.id} className={classes.root}>
              <div onClick={() => onDeleteImage(photo.id)} className={classes.close}>
                <CloseIcon />
              </div>
              <img alt={photo.comment} className={classes.image} src={photo.thumbnail} />
            </div>
          ))}
          <Loader show={loading} />
        </div>
        {files.length < 5 && (
          <div>
            <AttachIcon />
            <label htmlFor="filelId" className={classes.text}>
              Добавить файлы макета
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileInput;

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 600,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  root: {
    display: 'flex',
    position: 'relative',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: 47,
    width: 47,
  },
  label: {
    fontSize: 16,
    width: '40%',
    maxWidth: 240,
    fontWeight: 300,
    lineHeight: '19px',
    color: theme.palette.primary.main,
    paddingBottom: 2,
  },
  text: {
    color: theme.palette.secondary.main,
    fontSize: 16,
    lineHeight: '19px',
    paddingLeft: theme.spacing(1),
    cursor: 'pointer',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  image: {
    width: 47,
    height: 47,
    background: '#C4C4C4',
    borderRadius: 4,
    objectFit: 'cover',
  },
  close: {
    position: 'absolute',
    height: 24,
    width: 24,
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    top: 0,
    right: 0,
    background: 'rgba(0, 0, 0, 0.4)',
    borderRadius: '0px 4px',
    cursor: 'pointer',
    transition: 'all 200ms ease-in-out',
    opacity: 0,
    '&:hover': {
      opacity: 1,
    },
  },
}));
