import React, { FC } from 'react';
import classnames from 'classnames';
import adCompaniesStyles from './styles';

const AdCompaniesListTitle: FC = () => {
  const classes = adCompaniesStyles();
  return (
    <div className={classes.infoContainer} style={{ paddingTop: 0 }}>
      <div className={classnames(classes.item, classes.title)}>
        <div>Организация</div>
      </div>
      <div className={classnames(classes.item, classes.title)}>
        <div>Город</div>
      </div>
      <div className={classnames(classes.bigItem, classes.title)}>
        <div>Кампания</div>
      </div>
      <div className={classnames(classes.item, classes.title)}>
        <div>Статус</div>
      </div>
      <div className={classnames(classes.item, classes.title)}>
        <div>Класс ТС</div>
      </div>
      <div className={classnames(classes.item, classes.title)}>
        <div>Период проведения РК</div>
      </div>
    </div>
  );
};

export default React.memo(AdCompaniesListTitle);
