import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  loadCarById,
  loadCars,
  updateCarById,
  loadCarPromo,
  loadCarPhotoReport,
  rejectCarById,
  restoreCarById,
  pauseCar,
} from './actions';
import { initialCar, initialState } from './types';
import { TState } from 'store/index';
import { ICarsFilters } from 'interfaces/Car';
import { holdCar, rejectCar, busyCar } from 'store/reducers/promotions/actions';

const carsSlice = createSlice({
  name: 'cars',
  initialState,
  reducers: {
    changeFilters(state, action: PayloadAction<ICarsFilters>) {
      state.listing.filters = { ...state.listing.filters, ...action.payload };
    },
    changeListFilters(state, action: PayloadAction<ICarsFilters>) {
      state.listFilters = { ...state.listFilters, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadCars.pending, (state) => {
      state.listing.entities = [];
      state.listing.loading = true;
      state.listing.loaded = false;
    });
    builder.addCase(loadCars.fulfilled, (state, { payload }) => {
      state.listing.entities = payload.data[0];
      state.listing.count = payload.data[1];
      state.listing.loading = false;
      state.listing.loaded = true;
    });
    builder.addCase(loadCars.rejected, (state, action) => {
      state.listing.loading = false;
      state.listing.error = action.error.message;
    });

    builder.addCase(loadCarById.pending, (state) => {
      state.item.entity = initialCar;
      state.item.loading = true;
      state.item.loaded = false;
    });
    builder.addCase(loadCarById.fulfilled, (state, { payload }) => {
      state.item.entity = payload.data;
      state.item.loading = false;
      state.item.loaded = true;
    });
    builder.addCase(loadCarById.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error.message;
    });

    builder.addCase(updateCarById.pending, (state) => {
      state.item.loading = true;
      state.item.loaded = false;
    });
    builder.addCase(updateCarById.fulfilled, (state, { payload }) => {
      state.item.entity = payload.data;
      state.item.loading = false;
      state.item.loaded = true;
    });
    builder.addCase(updateCarById.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error.message;
    });

    builder.addCase(loadCarPromo.fulfilled, (state, { payload }) => {
      state.item.promos = payload;
    });

    builder.addCase(loadCarPhotoReport.fulfilled, (state, { payload }) => {
      state.item.photoReports = payload;
    });

    builder.addCase(holdCar.pending, (state) => {
      state.item.loading = true;
      state.item.loaded = false;
    });
    builder.addCase(holdCar.fulfilled, (state, { payload }) => {
      state.item.entity = payload;
      state.item.loading = false;
      state.item.loaded = true;
    });
    builder.addCase(holdCar.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error.message;
    });

    builder.addCase(rejectCar.pending, (state) => {
      state.item.loading = true;
      state.item.loaded = false;
    });
    builder.addCase(rejectCar.fulfilled, (state, { payload }) => {
      state.item.entity = payload;
      state.item.loading = false;
      state.item.loaded = true;
    });
    builder.addCase(rejectCar.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error.message;
    });

    builder.addCase(rejectCarById.pending, (state) => {
      state.item.loading = true;
      state.item.loaded = false;
    });
    builder.addCase(rejectCarById.fulfilled, (state, { payload }) => {
      state.item.entity = payload;
      state.item.loading = false;
      state.item.loaded = true;
    });
    builder.addCase(rejectCarById.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error.message;
    });

    builder.addCase(restoreCarById.pending, (state) => {
      state.item.loading = true;
      state.item.loaded = false;
    });
    builder.addCase(restoreCarById.fulfilled, (state, { payload }) => {
      state.item.entity = payload;
      state.item.loading = false;
      state.item.loaded = true;
    });
    builder.addCase(restoreCarById.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error.message;
    });

    builder.addCase(pauseCar.pending, (state) => {
      state.item.loading = true;
      state.item.loaded = false;
    });
    builder.addCase(pauseCar.fulfilled, (state, { payload }) => {
      state.item.entity = { ...state.item.entity, status: payload.status, pause: { ...payload } };
      state.item.loading = false;
      state.item.loaded = true;
    });
    builder.addCase(pauseCar.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error.message;
    });

    builder.addCase(busyCar.pending, (state) => {
      state.item.loading = true;
      state.item.loaded = false;
    });
    builder.addCase(busyCar.fulfilled, (state, { payload }) => {
      state.item.entity = { ...state.item.entity, ...payload };
      state.item.loading = false;
      state.item.loaded = true;
    });
    builder.addCase(busyCar.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error.message;
    });
  },
});

export const { changeFilters, changeListFilters } = carsSlice.actions;
export const getCars = (state: TState) => state.cars.listing.entities;
export const getCountCars = (state: TState) => state.cars.listing.count;
export const getCarsLoading = (state: TState) => state.cars.listing.loading;
export const getCarsFilters = (state: TState) => state.cars.listing.filters;
export const getCarsListFilters = (state: TState) => state.cars.listFilters;
export const getCar = (state: TState) => state.cars.item.entity;
export const getLoadingCar = (state: TState) => state.cars.item.loading;
export const getCarPromos = (state: TState) => state.cars.item.promos;
export const getCarPhotoReport = (state: TState) => state.cars.item.photoReports;
export default carsSlice.reducer;
