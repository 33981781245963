import React, { FC, useCallback, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import BaseLayout from 'components/baseLayout';
import { ConfirmDeleteUser } from 'components/containers/profile/elements/confirmDeleteUser';
import { deleteUserByMe } from 'store/reducers/users/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Routes } from 'constants/Routes';
import { useHistory } from 'react-router-dom';
import { getUserMe, getLoadingUserMe } from 'store/reducers/users';
import Loader from 'components/shared/loader';
import AcceptButton from 'components/shared/buttons/AcceptButton';
import Modal from 'components/shared/modal';
import { AdvertiserEditForm, ResetPasswordForm, UserEditForm } from 'constants/Forms';
import ResetPassword from 'components/containers/profile/elements/forms/ResetPasswordFrom';
import UserEdit from 'components/containers/profile/elements/forms/EditUserForm';
import ColumnContainer from 'components/layouts/columnContainer';
import { ProfileHeader } from 'components/containers/profile/elements/header';
import { ProfileView } from 'components/containers/profile/elements/view';
import AdvertiserView from '../advertisers/item/elements/view';
import AdvertiserEdit from '../advertisers/item/elements/edit';
import { loadCarCitiesHelper } from '../../../store/reducers/helpers/actions';

export const UserProfile: FC = () => {
  const classes = useStyles();
  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [isModalResetPasswordOpen, setModalResetPasswordOpen] = useState(false);
  const [isModalEditOpen, setModalEditOpen] = useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const userMe = useSelector(getUserMe);
  const userMeLoading = useSelector(getLoadingUserMe);

  const handleDeleteModalOpen = () => setModalDeleteOpen(!isModalDeleteOpen);

  const handleResetPasswordModalOpen = () => setModalResetPasswordOpen(true);
  const handleResetPasswordModalClose = () => setModalResetPasswordOpen(false);

  const handleEditModalOpen = () => setModalEditOpen(true);
  const handleEditModalClose = () => setModalEditOpen(false);

  const handleCloseEditModal = () => setOpenEditModal(false);
  const handleOpenEditModal = () => setOpenEditModal(true);

  useEffect(() => {
    dispatch(loadCarCitiesHelper());
  }, [dispatch]);

  const onClickDelete = useCallback(() => {
    dispatch(deleteUserByMe());
    localStorage.removeItem('token');
    history.push(Routes.auth);
  }, [history, dispatch]);

  return (
    <BaseLayout>
      <Grid>
        <Grid item container xs={12} className={classes.padding}>
          <ProfileHeader title="Личный кабинет" onClickDelete={handleDeleteModalOpen} />
        </Grid>
        <Grid item container xs={12} className={classes.borderTop}>
          <Grid item lg={4} xs={12} className={classes.border}>
            <ColumnContainer title="Личные данные" onClickIcon={handleEditModalOpen}>
              {userMeLoading ? (
                <Loader show />
              ) : (
                <>
                  <ProfileView profile={userMe} />
                  <AcceptButton title="Изменить пароль" onClick={handleResetPasswordModalOpen} />
                </>
              )}
            </ColumnContainer>
            <ColumnContainer title="Общие данные" onClickIcon={handleOpenEditModal}>
              <AdvertiserView advertiser={userMe.sponsor} />
            </ColumnContainer>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={isModalResetPasswordOpen}
        onClose={handleResetPasswordModalClose}
        actionTitle="Изменить пароль"
        formId={ResetPasswordForm}
        modalTitle="Изменение пароля"
      >
        <ResetPassword onSave={handleResetPasswordModalClose} />
      </Modal>
      <Modal
        open={isModalEditOpen}
        onClose={handleEditModalClose}
        actionTitle="Сохранить"
        formId={UserEditForm}
        modalTitle="Пользователь"
      >
        <UserEdit user={userMe} onSave={handleEditModalClose} />
      </Modal>
      <ConfirmDeleteUser
        openModalStatus={isModalDeleteOpen}
        buttonClose={handleDeleteModalOpen}
        buttonDelete={onClickDelete}
      />
      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        actionTitle="Сохранить"
        formId={AdvertiserEditForm}
        modalTitle="Рекламодатель"
      >
        <AdvertiserEdit isSponsor onSave={handleCloseEditModal} advertiser={userMe.sponsor} />
      </Modal>
    </BaseLayout>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  padding: {
    padding: 16,
  },
  borderTop: {
    borderTop: '1px solid #F2F2F2',
  },
  border: {
    borderRight: '1px solid #F2F2F2',
    [theme.breakpoints.down('md')]: {
      borderRight: 'none',
    },
  },
}));
