import React, { FC } from 'react';
import { Button, IconButton, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';

type TProps = {
  openModalStatus: boolean;
  buttonClose: () => void;
  buttonDelete: () => void;
};

export const ConfirmDeleteUser: FC<TProps> = (TProps) => {
  const classes = useStyles();

  return (
    <Modal open={TProps.openModalStatus} onClose={TProps.buttonClose}>
      <div className={classes.paper}>
        <div className={classes.iconButtonDiv}>
          <IconButton color="inherit" onClick={TProps.buttonClose}>
            <ClearIcon />
          </IconButton>
        </div>
        <>
          <p className={classes.textInfo}>Вы уверены, что хотите</p>
          <p className={classes.textInfo}>удалить учетную</p>
          <p className={classes.textInfo}>запись? Отменить это</p>
          <p className={classes.textInfo}>действие будет невозможно</p>
        </>
        <div className={classes.buttons}>
          <Button classes={{ root: classes.buttonResetText }} onClick={TProps.buttonClose}>
            Отмена
          </Button>
          <Button
            variant="outlined"
            classes={{ outlined: classes.outlined, root: classes.buttonConfirmText }}
            onClick={TProps.buttonDelete}
          >
            Удалить
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    height: '232px',
    width: '366px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    boxSizing: 'border-box',
    border: '1px solid #FFFFFF',
    outline: '0',
  },
  textInfo: {
    color: 'black',
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '22px',
    textAlign: 'center',
    alignItem: 'center',
    margin: 4,
  },
  iconButtonDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    width: 'auto',
    justifyContent: 'space-evenly',
    marginTop: theme.spacing(3),
    alignItems: 'center',
  },
  outlined: {
    border: '1px solid',
    borderColor: '#CC2631',
  },
  buttonResetText: {
    textTransform: 'inherit',
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '18px',
    color: '#7D7D7D',
    width: '104px',
  },
  buttonConfirmText: {
    textTransform: 'inherit',
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '18px',
    color: '#CC2631',
    width: '104px',
    border: '1px solid #CC2631',
    boxSizing: 'border-box',
    borderRadius: '8px',
  },
}));
