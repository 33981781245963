import React from 'react';
import { Grid } from '@material-ui/core';
import FieldView from 'components/shared/inputs/FieldView';
import Status from 'components/shared/status';
import { makeStyles } from '@material-ui/core/styles';
import { Promotion } from 'interfaces/Promotions';
import { format, parseISO } from 'date-fns';

type TAdCompanyViewProps = {
  adCompany: Promotion;
};

const AdCompanyView: React.FC<TAdCompanyViewProps> = ({ adCompany }) => {
  const classes = useStyles();

  const promoPeriod =
    adCompany.start &&
    adCompany.finish &&
    `${format(parseISO(adCompany.start), 'dd.MM.yyyy')}-${format(parseISO(adCompany.finish), 'dd.MM.yyyy')}`;

  return (
    <Grid container>
      <Grid item xs={6}>
        <FieldView title="Организация:" value={adCompany?.sponsor?.name} />
        <FieldView title="Город:" value={adCompany?.city} />
        <FieldView title="Класс авто:" value={adCompany?.autoClass} />
        <FieldView title="Тираж:" value={adCompany?.distribution} />
        <FieldView title="Сроки проведения:" value={promoPeriod} />
        <FieldView title="Бюджет:" value={adCompany?.budget} />
        <FieldView title="Телефон:" value={adCompany?.sponsor?.phone} />
        <FieldView title="Дополнительный телефон:" value={adCompany?.sponsor?.phoneExt} />
        <FieldView title="E-mail:" value={adCompany?.eMail} />
      </Grid>
      <Grid item container xs={6} justify="flex-end">
        <Status status={adCompany?.status} />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.descriptionText}>{adCompany?.description}</div>
      </Grid>
      <Grid item container xs={12}>
        {adCompany?.files.map((photo) => (
          <img alt={photo.comment} key={photo.id} className={classes.image} src={photo.URI} />
        ))}
      </Grid>
    </Grid>
  );
};

export default React.memo(AdCompanyView);

const useStyles = makeStyles((theme) => ({
  descriptionText: {
    wordBreak: 'break-all',
    width: '100%',
    border: ' 1px solid #F2F2F2',
    boxSizing: 'border-box',
    borderRadius: '8px',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '19px',
    color: theme.palette.text.primary,
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  image: {
    width: 260,
    height: 260,
    background: '#C4C4C4',
    borderRadius: 4,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    objectFit: 'cover',
  },
}));
