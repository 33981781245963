import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

type LoaderProps = {
  show: boolean;
};

const useStyles = makeStyles(() => ({
  container: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    zIndex: 9,
  },
}));

const Loader: React.FC<LoaderProps> = ({ show }) => {
  const classes = useStyles();

  return show ? (
    <div className={classes.container}>
      <CircularProgress size={50} />
    </div>
  ) : (
    <></>
  );
};

export default Loader;
