import { makeStyles, Theme } from '@material-ui/core/styles';

const adCompaniesStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    cursor: 'pointer',
    border: '1px solid #F2F2F2',
    borderRadius: 8,
    '&:hover': {
      boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.1)',
    },
  },
  infoContainer: {
    flex: 1,
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    position: 'relative',
    padding: 16,
    width: '100%',
  },
  item: {
    flex: '0 1 16%',
    minWidth: '16%',
    fontSize: 15,
    lineHeight: '18px',
    color: theme.palette.text.primary,
    overflow: 'hidden',
  },
  bigItem: {
    flex: '0 1 20%',
    minWidth: '20%',
    fontSize: 15,
    lineHeight: '18px',
    color: theme.palette.text.primary,
    overflow: 'hidden',
  },
  tileItem: {
    maxWidth: '100%',
    flexBasis: '100%',
    marginBottom: theme.spacing(1),
  },
  title: {
    color: theme.palette.text.secondary,
  },
}));

export default adCompaniesStyles;
