import { Car, ICarsFilters } from 'interfaces/Car';
import { TLoadEntity } from 'store/baseTypes';

export const MAX_NUMBER_OF_DRIVER_PER_REQUEST = 10;

export enum DriverStatus {
  frozen = 'заморожен',
}

export const DriverStatusNames: { [key in DriverStatus]: string } = {
  заморожен: 'заморожен',
};

export interface IDriversFilters {
  city?: string | null;
  status?: string | null;
  searchStr?: string | null;
  name?: string | null;
  limit?: number;
  offset?: number;
}

export interface Driver {
  readonly id: string;
  name: string;
  firstName: string;
  lastName: string;
  fatherName: string;
  phone: string;
  city: string;
  eMail: string;
  taxStatus: string;
  beneficiary: string;
  inn: string;
  kpp: string;
  bik: string;
  account: string;
  license: string;
  licenseYear: string;
  birthday: string;
  driverName?: string;
  status?: DriverStatus.frozen;
  __cars__: Car[];
}

export interface DriverCars {
  cars: {
    cars: Car[];
    filters: ICarsFilters;
    error: string | null | undefined;
    count: number;
  } & TLoadEntity;
}

export interface ILoadDriverCars extends ICarsFilters {
  id: string;
}
