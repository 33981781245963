import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteById, loadAll, loadById, updateById, create, createPromotionById } from 'store/reducers/sponsors/api';
import Snackbar from 'services/Snackbar';
import { AxiosError } from 'axios';
import { ActionsTypes } from 'store/reducers/sponsors/types';
import { ISponsorsFilters, Sponsor, TCreateSponsorPromotion } from 'interfaces/Sponsors';
import { serverErrorText } from 'constants/ServerCode';

const validateError = (err: AxiosError) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }
  const errorCode = error.response.status;
  const errorText = error.response.data.error;
  // @ts-ignore
  const errorMessage: string = serverErrorText[errorText || errorCode] || error.message;
  Snackbar.show(errorMessage, 'error');
  return errorMessage;
};

export const loadSponsors = createAsyncThunk(
  ActionsTypes.LOAD_ALL_SPONSORS,
  async (filters: ISponsorsFilters, { rejectWithValue }) => {
    try {
      const response = await loadAll(filters);
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);

export const loadSponsorById = createAsyncThunk(ActionsTypes.LOAD_SPONSOR, async (id: string, { rejectWithValue }) => {
  try {
    const response = await loadById(id);
    return response.data;
  } catch (err) {
    const error: AxiosError = err;
    if (!error.response) {
      throw err;
    }
    const errorCode = error.response.status;
    // @ts-ignore
    const errorMessage: string = serverErrorText[errorCode] || error.message;
    Snackbar.show(errorMessage, 'error');
    return rejectWithValue(errorMessage);
  }
});

export const updateSponsorById = createAsyncThunk(
  ActionsTypes.UPDATE_SPONSOR,
  async (sponsor: Sponsor, { rejectWithValue }) => {
    try {
      const { id, ...fields } = sponsor;
      const response = await updateById(id, { data: fields });
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);

export const deleteSponsorById = createAsyncThunk(
  ActionsTypes.DELETE_SPONSOR,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await deleteById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);

export const createSponsor = createAsyncThunk(
  ActionsTypes.CREATE_SPONSOR,
  async (sponsor: Sponsor, { rejectWithValue }) => {
    try {
      const response = await create({ data: sponsor });
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);

export const createSponsorPromotion = createAsyncThunk(
  ActionsTypes.CREATE_SPONSOR_PROMOTION,
  async (data: TCreateSponsorPromotion, { rejectWithValue }) => {
    try {
      const { sponsorId, ...fields } = data;
      const response = await createPromotionById(sponsorId, fields.promotion);
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);
