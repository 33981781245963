import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { Car, CloseIcon } from 'assets/images';
import { Fade } from '@material-ui/core';

type TAvatarProps = {
  url?: string;
  onClick: () => void;
};

const SmallEntityAvatar: FC<TAvatarProps> = ({ url, onClick }) => {
  const classes = useStyles();
  const [isHover, setHover] = React.useState(false);

  return (
    <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className={classes.root}>
      {isHover && (
        <Fade timeout={500} in={isHover}>
          <div onClick={onClick} className={classes.close}>
            <CloseIcon />
          </div>
        </Fade>
      )}
      <Avatar variant="rounded" alt="avatar" src={url || Car} className={classes.avatar} />
    </div>
  );
};

export default React.memo(SmallEntityAvatar);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    height: 100,
    width: 100,
  },
  avatar: {
    width: 100,
    height: 100,
  },
  close: {
    position: 'absolute',
    height: 32,
    width: 32,
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    top: 0,
    right: 0,
    background: 'rgba(0, 0, 0, 0.4)',
    borderRadius: '0px 4px',
    cursor: 'pointer',
  },
}));
