import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AdsCard from './Card';
import { Promotion } from 'interfaces/Promotions';

type TCarAdsProps = {
  ad: Promotion | null;
  carId: string;
  photoReports: any;
};

const CarAds: React.FC<TCarAdsProps> = ({ ad, carId, photoReports }) => {
  const classes = useStyles();

  if (!ad)
    return (
      <span className={classes.noAdsText}>
        Здесь будут отображаться рекламные кампании, на которые записано транспортное средство
      </span>
    );

  return (
    <Grid container>
      <Grid item xs={12}>
        <AdsCard key={carId} carId={carId} ad={ad} photoReports={photoReports} />
      </Grid>
    </Grid>
  );
};

export default React.memo(CarAds);

const useStyles = makeStyles((theme) => ({
  noAdsText: {
    fontWeight: 300,
    fontSize: 16,
    lineHeight: '19px',
    color: theme.palette.primary.dark,
  },
}));
