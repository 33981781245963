import React from 'react';

import { Theme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

type CreateButtonProps = {
  title?: string;
  onClick: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  submitButton: {
    borderRadius: 8,
    border: '1px solid #7D7D7D',
    color: theme.palette.text.primary,
    fontSize: 15,
    padding: '0px 8px',
    textTransform: 'none',
    height: 32,
    fontWeight: 'normal',
    '&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.background.default,
      border: '1px solid #2D9CDB',
    },
  },
}));

const CreateButton: React.FC<CreateButtonProps> = ({ title, onClick }) => {
  const classes = useStyles();

  return (
    <Button className={classes.submitButton} variant="outlined" type="submit" onClick={onClick}>
      {title || '+ Создать'}
    </Button>
  );
};

export default CreateButton;
