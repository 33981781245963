import React, { FC, useCallback, useState } from 'react';
import classnames from 'classnames';
import { Logo, OrgName, AdCompany, Drivers, Advertisers, Exit, UserAccount, Cars, Ellipse, Promo } from 'assets/images';
import { changePage, changeOpenSideBar, getSideBar } from 'store/reducers/sideBar';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Drawer, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import ListItemLink from '@material-ui/core/ListItem';
import { NavLink, useLocation } from 'react-router-dom';
import { Routes } from 'constants/Routes';
import { logout } from 'store/reducers/auth/actions';
import { getUserMe, resetMe } from 'store/reducers/users';
import { ConfirmLogOutUser } from './elements/confirmLogOut';
import { changeFilters as changePromosFilters } from 'store/reducers/promotions';
import { changeFilters as changeCarsFilters } from 'store/reducers/cars';
import { changeFilters as changeDriversFilters } from 'store/reducers/drivers';
import { initialPromotionsFilters } from '../../store/reducers/promotions/types';
import { initialCarsFilters } from '../../interfaces/Car';
import { initialDriversFilters } from '../../store/reducers/drivers/types';

type TMenuItem = {
  id: number;
  title: string;
  Icon: any;
  route: string;
};

const drawerWidth = 295;
const MENU_LIST_ADMIN: TMenuItem[] = [
  { id: 1, title: 'Рекламные кампании', Icon: AdCompany, route: Routes.adCompanies },
  { id: 2, title: 'Транспортные средства', Icon: Cars, route: Routes.cars },
  { id: 3, title: 'Водители', Icon: Drivers, route: Routes.drivers },
  { id: 4, title: 'Рекламодатели', Icon: Advertisers, route: Routes.advertisers },
  { id: 5, title: 'Личный кабинет', Icon: UserAccount, route: Routes.profile },
  { id: 6, title: 'Промо', Icon: Promo, route: Routes.promos },
];

const MENU_LIST: TMenuItem[] = [
  { id: 1, title: 'Рекламные кампании', Icon: AdCompany, route: Routes.adCompanies },
  { id: 5, title: 'Личный кабинет', Icon: UserAccount, route: Routes.profile },
];
type IProps = {};

const BaseLayout: FC<IProps> = ({ children }) => {
  const classes = useStyles();
  const { open } = useSelector(getSideBar);
  const dispatch = useDispatch();
  const location = useLocation();
  const userMe = useSelector(getUserMe);
  const [isOpenLogOutModal, setOpenLogOutModal] = useState(false);

  const handleLogOut = useCallback(() => {
    dispatch(logout());
    dispatch(resetMe());
  }, [dispatch]);

  const handlePage = useCallback(
    (page: number) => () => {
      if (page === 1) dispatch(changePromosFilters(initialPromotionsFilters));
      if (page === 2) dispatch(changeCarsFilters(initialCarsFilters));
      if (page === 3) dispatch(changeDriversFilters(initialDriversFilters));
      dispatch(changePage(page));
    },
    [dispatch],
  );

  const handleModalOpen = () => setOpenLogOutModal(!isOpenLogOutModal);
  const handleOpen = useCallback(() => dispatch(changeOpenSideBar()), [dispatch]);

  const NAVIGATION_MENU = userMe?.admin ? MENU_LIST_ADMIN : MENU_LIST;

  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        anchor="left"
        className={classnames(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: classnames({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.listMenu}>
          <div className={classes.listMenuTop}>
            <div className={classes.paper}>
              <div className={classes.sideBar}>
                <div>
                  <Logo className={classes.logoSVG} />
                </div>
                <Typography component="h1" variant="h5">
                  <OrgName className={open ? classes.orgNameOpen : classes.orgNameClose} />
                </Typography>
              </div>
            </div>
            <List>
              {NAVIGATION_MENU.map(({ id, title, Icon, route }) => (
                <Button onClick={handlePage(id)} key={id} className={classes.pageButton}>
                  <ListItemLink component={NavLink} to={route} className={classes.item}>
                    <ListItemIcon className={classes.itemIcon}>
                      <Icon fill={location.pathname.split('/')[1] === route.split('/')[1] ? '#CC2631' : '#000000'} />
                    </ListItemIcon>
                    {open ? (
                      <ListItemText
                        className={classes.itemText}
                        primary={title}
                        style={{
                          color: location.pathname.split('/')[1] === route.split('/')[1] ? '#CC2631' : '#000000',
                        }}
                      />
                    ) : null}
                  </ListItemLink>
                </Button>
              ))}
            </List>
          </div>
          <div className={classes.listMenuBottom}>
            <List>
              <Button onClick={handleOpen} className={classes.pageButton}>
                <ListItem className={classes.item} button>
                  <ListItemIcon className={classes.itemIcon}>
                    <Ellipse />
                  </ListItemIcon>
                </ListItem>
              </Button>
              <Button onClick={handleModalOpen} className={classes.pageButton}>
                <ListItem className={classes.item} button>
                  <ListItemIcon className={classes.itemIcon}>
                    <Exit className={classes.exit} />
                  </ListItemIcon>
                  {open ? <ListItemText className={classes.itemText} primary={'Выйти'} /> : null}
                </ListItem>
              </Button>
            </List>
          </div>
        </div>
      </Drawer>
      <main
        className={classnames(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {children}
      </main>
      <ConfirmLogOutUser
        openModalStatus={isOpenLogOutModal}
        buttonClose={handleModalOpen}
        buttonLogOut={handleLogOut}
      />
    </div>
  );
};

export default BaseLayout;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sideBar: {
    height: '122px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  logoSVG: {
    height: '48.76px',
    width: '39.01px',
    margin: '10px',
  },
  listMenu: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  listMenuTop: {},
  listMenuBottom: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '100%',
  },
  exit: {
    height: '16px',
    width: '21px',
  },
  orgNameOpen: {
    height: '32px',
    width: '136.37px',
    margin: '10px',
  },
  orgNameClose: {
    height: '19.24px',
    width: '82px',
    margin: '10px',
  },
  body: {
    display: 'flex',
  },
  root: {
    display: 'flex',
    height: '96.5vh',
    margin: '16px 16px 0 16px',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    overflow: 'hidden',
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    background: '#FFFFFF',
    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    marginRight: theme.spacing(4),
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    position: 'relative',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: '#FFFFFF',
    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    position: 'inherit',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '105px',
    position: 'inherit',
  },
  toolbar: {
    display: 'flex',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    width: '100%',
    paddingLeft: '32px',
    paddingBottom: '8px',
  },
  pageButton: {
    width: '100%',
  },
  item: {
    height: '60px',
  },
  itemIcon: {
    justifyContent: 'center',
  },
  itemText: {
    '& span': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
    },
    width: '160px',
    textTransform: 'initial',
  },
}));
