import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteById,
  loadAllUsers,
  loadById,
  updateById,
  freeze,
  loadUserAuth,
  newPromo,
  updateSponsor,
  updatePassword,
} from 'store/reducers/users/api';
import Snackbar from 'services/Snackbar';
import { AxiosError } from 'axios';
import { ActionsTypes } from 'store/reducers/users/types';
import { serverErrorText } from 'constants/ServerCode';
import { PasswordChange, User } from 'interfaces/User';
import { Promotion } from 'interfaces/Promotions';
import { Sponsor } from 'interfaces/Sponsors';

export const loadUsers = createAsyncThunk(ActionsTypes.LOAD_ALL_USERS, async (any, { rejectWithValue }) => {
  try {
    const response = await loadAllUsers();
    return response.data;
  } catch (err) {
    return validateError(err, rejectWithValue);
  }
});

export const loadUserByMe = createAsyncThunk(ActionsTypes.LOAD_ME, async (any, { rejectWithValue }) => {
  try {
    const response = await loadById('me');
    return response.data;
  } catch (err) {
    return validateError(err, rejectWithValue);
  }
});

export const updateUserByMe = createAsyncThunk(ActionsTypes.UPDATE_ME, async (user: User, { rejectWithValue }) => {
  try {
    const response = await updateById('me', user);
    return response.data;
  } catch (err) {
    return validateError(err, rejectWithValue);
  }
});

export const deleteUserByMe = createAsyncThunk(ActionsTypes.DELETE_ME, async (any, { rejectWithValue }) => {
  try {
    const response = await deleteById('me');
    return response.data;
  } catch (err) {
    return validateError(err, rejectWithValue);
  }
});

export const freezeUserById = createAsyncThunk(ActionsTypes.FREEZE_USER, async (id: string, { rejectWithValue }) => {
  try {
    const response = await freeze(id);
    return response.data;
  } catch (err) {
    return validateError(err, rejectWithValue);
  }
});

export const getUserAuth = createAsyncThunk(ActionsTypes.LOAD_USER_AUTH, async (any, { rejectWithValue }) => {
  try {
    const response = await loadUserAuth();
    return response.data;
  } catch (err) {
    return validateError(err, rejectWithValue);
  }
});

export const createNewPromo = createAsyncThunk(
  ActionsTypes.NEW_PROMO,
  async (promotion: Promotion, { rejectWithValue }) => {
    try {
      const response = await newPromo({ data: promotion });
      return response.data;
    } catch (err) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateMeSponsor = createAsyncThunk(
  ActionsTypes.UPDATE_ME_SPONSOR,
  async (sponsor: Sponsor, { rejectWithValue }) => {
    try {
      const response = await updateSponsor({ data: sponsor });
      return response.data;
    } catch (err) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateUserPassword = createAsyncThunk(
  ActionsTypes.UPDATE_USER_PASSWORD,
  async (passwordData: PasswordChange, { rejectWithValue }) => {
    try {
      const response = await updatePassword(passwordData);
      return response.status;
    } catch (err) {
      const error = err as AxiosError;
      validateError(error, rejectWithValue);
      return error?.response?.data;
    }
  },
);

const validateError = (err: any, rejectWithValue: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }
  const errorCode = error.response.status;
  // @ts-ignore
  const errorMessage: string = serverErrorText[errorCode] || error.message;
  Snackbar.show(errorMessage, 'error');
  return rejectWithValue(errorMessage);
};
