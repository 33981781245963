import { axiosClient } from 'services/ApiClient';
import { AxiosResponse } from 'axios';
import { ISponsorsFilters, Sponsor } from 'interfaces/Sponsors';
import { Promotion } from 'interfaces/Promotions';

const sponsorsUrl = '/sponsors';

type CreateSponsor = {
  data: Sponsor;
};

type UpdateSponsor = {
  data: Partial<Sponsor>;
};

export const updateById = (id: string, fields: UpdateSponsor): Promise<AxiosResponse> =>
  axiosClient.put(`${sponsorsUrl}/${id}`, fields);

export const loadAll = (filters: ISponsorsFilters): Promise<AxiosResponse> =>
  axiosClient.get(`${sponsorsUrl}`, { params: filters });

export const loadById = (id: string): Promise<AxiosResponse> => axiosClient.get(`${sponsorsUrl}/${id}`);
export const deleteById = (id: string): Promise<AxiosResponse> => axiosClient.delete(`${sponsorsUrl}/${id}`);
export const create = (fields: CreateSponsor): Promise<AxiosResponse> => axiosClient.post(`${sponsorsUrl}`, fields);

export const createPromotionById = (id: string, promotion: Promotion): Promise<AxiosResponse> =>
  axiosClient.post(`${sponsorsUrl}/${id}/newpromo`, promotion);
