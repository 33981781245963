const PROTOCOL = process.env.REACT_APP_PROTOCOL || 'https';
const API = process.env.REACT_APP_HOST || 'rekcar-server.psrv5.citronium.com';
const API_PORT = process.env.REACT_APP_PORT || 443;

const API_HOST = `${PROTOCOL}://${API}:${API_PORT}`;

const env = { API_HOST };

export const yandexApiKey = '11e4520e-ef3c-4c46-9fe0-fc31c2b04fc6';

export default env;
