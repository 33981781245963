import { createSlice } from '@reduxjs/toolkit';
import { login, logout, registration } from './actions';
import { initialState } from 'store/reducers/auth/types';
import { authService } from 'services/AuthService';
import { TState } from '../../index';

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.loaded = false;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      authService.signIn(payload.data.token);
      state.token = payload.data.token;
      state.loading = false;
      state.loaded = true;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(registration.pending, (state) => {
      state.loading = true;
      state.loaded = false;
    });
    builder.addCase(registration.fulfilled, (state, { payload }) => {
      authService.signIn(payload.data.token);
      state.token = payload;
      state.loading = false;
      state.loaded = true;
    });
    builder.addCase(registration.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(logout.fulfilled, (state) => {
      authService.signOut();
      state.token = null;
      state.loading = false;
      state.loaded = true;
    });
  },
});

export default authSlice.reducer;
export const getToken = (state: TState) => state.auth.token;
