import React, { ReactElement, useCallback, useEffect } from 'react';
import AcceptButton from 'components/shared/buttons/AcceptButton';
import ClearButton from 'components/shared/buttons/ClearButton';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { IDriversFilters } from 'interfaces/Driver';
import { Controller, useForm } from 'react-hook-form';
import { initialDriversFilters } from 'store/reducers/drivers/types';
import CustomAutocompleteSelect from 'components/shared/select/CustomAutocompleteSelect';
import { loadCarCitiesHelper } from 'store/reducers/helpers/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getCities } from 'store/reducers/helpers';
import { getDriversFilters } from 'store/reducers/drivers';

export type TFiltersProps = {
  filters: IDriversFilters;
  onChangeFilters: (value: IDriversFilters) => void;
};

const STATUSES_OPTIONS = [
  {
    displayName: 'активен',
    id: 0,
  },
  {
    displayName: 'заморожен',
    id: 1,
  },
];

const DriversFilters = ({ filters, onChangeFilters }: TFiltersProps): ReactElement => {
  const classes = useStyles();
  const { handleSubmit, control, errors, reset } = useForm<IDriversFilters>({
    defaultValues: initialDriversFilters,
  });

  const dispatch = useDispatch();

  const { name } = useSelector(getDriversFilters);

  useEffect(() => {
    onChangeFilters(initialDriversFilters);
  }, [onChangeFilters]);

  useEffect(() => {
    dispatch(loadCarCitiesHelper());
  }, [dispatch]);

  const helperCities = useSelector(getCities);

  const handleChangeFilters = useCallback(
    (data) => {
      if (data) {
        onChangeFilters({
          status: data.status?.displayName,
          city: data.city?.displayName,
          offset: undefined,
        });
      }
    },
    [onChangeFilters],
  );

  const handleResetFilters = useCallback(() => {
    onChangeFilters({ ...initialDriversFilters, name });
    reset({ ...initialDriversFilters, status: '', city: '' });
  }, [name, reset, onChangeFilters]);

  return (
    <div>
      <form className={classes.container}>
        <Controller
          name="status"
          control={control}
          render={(props) => (
            <CustomAutocompleteSelect
              name="status"
              value={props.value}
              options={STATUSES_OPTIONS}
              title="Статус:"
              errors={errors}
              onChange={props.onChange}
            />
          )}
        />
        <Controller
          name="city"
          control={control}
          render={(props) => (
            <CustomAutocompleteSelect
              name="city"
              value={props.value}
              options={helperCities.entities}
              title="Город:"
              errors={errors}
              onChange={props.onChange}
            />
          )}
        />
      </form>
      <div className={classes.containerButton}>
        <ClearButton onClick={handleResetFilters} />
        <AcceptButton onClick={handleSubmit(handleChangeFilters)} />
      </div>
    </div>
  );
};

export default React.memo(DriversFilters);

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    '& > :nth-child(n)': {
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  containerButton: {
    display: 'flex',
    width: 222,
  },
}));
