import React, { MutableRefObject, useRef } from 'react';
import Avatar from 'components/shared/avatar';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FieldViewRow from 'components/shared/inputs/FieldViewRow';
import SimpleStatus from 'components/shared/status/SimpleStatus';
import { Car } from 'interfaces/Car';
import { Card } from '@material-ui/core';

type TCarCardProps = {
  car: Car;
  onClick: (id: string) => void;
};

const CarCard: React.FC<TCarCardProps> = ({ car, onClick }) => {
  const classes = useStyles();
  const componentRef = useRef() as MutableRefObject<HTMLDivElement>;

  const onClickCar = () => {
    onClick(car.id);
  };

  return (
    <Card ref={componentRef} classes={{ root: classes.container }} onClick={onClickCar}>
      <Avatar url={car.avatar} />
      <div className={classes.textContainer}>
        <FieldViewRow title="Марка:" value={car.mark} />
        <FieldViewRow title="Модель: " value={car.model} />
        <FieldViewRow title="Класс:" value={car.autoClass} />
        <FieldViewRow title="Год выпуска:" value={car.year} />
        <FieldViewRow title="Город:" value={car.city} />
      </div>
      <div className={classes.status}>
        <SimpleStatus status={car.status} />
      </div>
    </Card>
  );
};

export default React.memo(CarCard);

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
    border: '1px solid #F2F2F2',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    width: 550,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
    boxShadow: 'none',
    position: 'relative',
    flexWrap: 'wrap',
    '& > :first-child': {
      marginRight: theme.spacing(1),
    },
    '&:hover': {
      boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.1)',
    },
  },
  textContainer: {
    flexDirection: 'column',
    flex: '1 1 55%',
  },
  status: {
    position: 'absolute',
    right: theme.spacing(2),
  },
}));
