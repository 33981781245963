import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import CustomAutocompleteSelect from 'components/shared/select/CustomAutocompleteSelect';
import AcceptButton from 'components/shared/buttons/AcceptButton';
import ClearButton from 'components/shared/buttons/ClearButton';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ICarsFilters, initialCarsFilters } from 'interfaces/Car';
import { Controller, useForm } from 'react-hook-form';
import { options, crashOptions } from 'utils/options';
import {
  loadCarBodiesHelper,
  loadCarCitiesHelper,
  loadCarClassesHelper,
  loadCarColorsHelper,
  loadCarMarksHelper,
} from '../../../../../../store/reducers/helpers/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getBodies, getCities, getClasses, getColors, getMarks } from '../../../../../../store/reducers/helpers';

export type TFiltersProps = {
  initial?: ICarsFilters;
  filters: ICarsFilters;
  isSelect?: boolean;
  onChangeFilters: (value: ICarsFilters) => void;
};

const STATUSES_OPTIONS = [
  {
    displayName: 'на рассмотрении',
    value: 0,
  },
  {
    displayName: 'в работе',
    value: 1,
  },
  {
    displayName: 'в ожидании',
    value: 2,
  },
  {
    displayName: 'отклонён',
    value: 3,
  },
  {
    displayName: 'на паузе',
    value: 4,
  },
];

const CarsFilters = ({ initial = initialCarsFilters, isSelect, onChangeFilters }: TFiltersProps): ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getYearValue = (value: number) => ({
    value: value,
    title: value?.toString(),
  });

  const getStatusValue = (status: string) => STATUSES_OPTIONS.find((item) => item.displayName === status) ?? null;

  const getCrashValue = (crash: boolean) => crashOptions.find((item) => item.value === crash) ?? null;

  const helperCities = useSelector(getCities);

  const getCityValue = (city: string) => helperCities.entities.find((item) => item.displayName === city) ?? null;

  const helperClasses = useSelector(getClasses);

  const getAutoClassValue = (autoClass: string) =>
    helperClasses.entities.find((item) => item.displayName === autoClass) ?? null;

  const helperColors = useSelector(getColors);

  const getColorValue = (autoClass: string) =>
    helperColors.entities.find((item) => item.displayName === autoClass) ?? null;

  const helperBodies = useSelector(getBodies);

  const getBodyTypeValue = (bodyType: string) =>
    helperBodies.entities.find((item) => item.displayName === bodyType) ?? null;

  const helperMarks = useSelector(getMarks);

  const getMarkValue = (mark: string) => ({
    id: mark,
    displayName: mark,
  });

  const { handleSubmit, control, errors, reset } = useForm<any>({
    defaultValues: { ...initial, mark: initial.mark ? getMarkValue(initial.mark) : null },
  });

  const isFirstMount = useRef(true);

  useEffect(() => {
    if (isFirstMount.current) {
      onChangeFilters(initial);
      isFirstMount.current = false;
    }
  }, [initial, onChangeFilters]);

  useEffect(() => {
    dispatch(loadCarCitiesHelper());
    dispatch(loadCarClassesHelper());
    dispatch(loadCarMarksHelper());
    dispatch(loadCarBodiesHelper());
    dispatch(loadCarColorsHelper());
  }, [dispatch]);

  const handleChangeFilters = useCallback(
    (data) => {
      if (data) {
        onChangeFilters({
          status: typeof data.status === 'string' ? data.status : data.status?.displayName,
          autoClass: typeof data.autoClass === 'string' ? data.autoClass : data.autoClass?.displayName,
          mark: typeof data.mark === 'string' ? data.mark : data.mark?.displayName,
          bodyType: typeof data.bodyType === 'string' ? data.bodyType : data.bodyType?.displayName,
          color: typeof data.color === 'string' ? data.color : data.color?.displayName,
          year: typeof data.year === 'number' ? data.year : data.year?.value,
          city: typeof data.city === 'string' ? data.city : data.city?.displayName,
          crash: typeof data.crash === 'boolean' ? data.crash : data.crash?.value,
          offset: undefined,
        });
      }
    },
    [onChangeFilters],
  );

  const handleResetFilters = useCallback(() => {
    onChangeFilters(initialCarsFilters);
    reset({ ...initialCarsFilters, status: '', autoClass: '', bodyType: '', city: '', color: '', mark: '', model: '' });
  }, [reset, onChangeFilters]);

  return (
    <div>
      <form className={classes.container}>
        {!isSelect && (
          <Controller
            name="status"
            control={control}
            render={(props) => (
              <CustomAutocompleteSelect
                name="status"
                value={typeof props.value === 'object' ? props.value : getStatusValue(props.value)}
                options={STATUSES_OPTIONS}
                title="Статус:"
                onChange={props.onChange}
              />
            )}
          />
        )}
        <Controller
          name="autoClass"
          control={control}
          render={(props) => (
            <CustomAutocompleteSelect
              name="autoClass"
              value={typeof props.value === 'object' ? props.value : getAutoClassValue(props.value)}
              options={helperClasses.entities}
              title="Класс авто:"
              onChange={props.onChange}
            />
          )}
        />
        <Controller
          name="mark"
          control={control}
          render={(props) => (
            <CustomAutocompleteSelect
              name="mark"
              value={props.value}
              options={helperMarks.entities}
              title="Марка:"
              onChange={props.onChange}
            />
          )}
        />
        <Controller
          name="bodyType"
          control={control}
          render={(props) => (
            <CustomAutocompleteSelect
              name="bodyType"
              value={typeof props.value === 'object' ? props.value : getBodyTypeValue(props.value)}
              options={helperBodies.entities}
              title="Тип кузова:"
              onChange={props.onChange}
            />
          )}
        />
        <Controller
          name="color"
          control={control}
          render={(props) => (
            <CustomAutocompleteSelect
              name="color"
              value={typeof props.value === 'object' ? props.value : getColorValue(props.value)}
              options={helperColors.entities}
              title="Цвет:"
              onChange={props.onChange}
            />
          )}
        />
        <Controller
          name="year"
          control={control}
          render={(props) => (
            <CustomAutocompleteSelect
              value={typeof props.value === 'object' ? props.value : getYearValue(props.value)}
              options={options()}
              title="Год выпуска"
              onChange={props.onChange}
              errors={errors}
              name="year"
            />
          )}
        />
        <Controller
          name="city"
          control={control}
          render={(props) => (
            <CustomAutocompleteSelect
              name="city"
              value={typeof props.value === 'object' ? props.value : getCityValue(props.value)}
              options={helperCities.entities}
              title="Город:"
              errors={errors}
              onChange={props.onChange}
            />
          )}
        />
        <Controller
          name="crash"
          control={control}
          render={(props) => (
            <CustomAutocompleteSelect
              value={typeof props.value === 'object' ? props.value : getCrashValue(props.value)}
              options={crashOptions}
              title="Участвовал в ДТП"
              onChange={props.onChange}
              errors={errors}
              name="crash"
            />
          )}
        />
      </form>
      <div className={classes.containerButton}>
        <ClearButton onClick={handleResetFilters} />
        <AcceptButton onClick={handleSubmit(handleChangeFilters)} />
      </div>
    </div>
  );
};

export default React.memo(CarsFilters);

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    '& > :nth-child(n)': {
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  containerButton: {
    display: 'flex',
    width: 222,
  },
}));
