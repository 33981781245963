import React from 'react';
import { OnReview, OnDecline, OnHold, OnPause, OnWork } from 'assets/cars';
import { OnFrozen } from 'assets/drivers';
import { New, OnDone, OnWork as OnPromoWork } from 'assets/promotions';
import { CarStatus } from 'interfaces/Car';
import { DriverStatus } from 'interfaces/Driver';
import { PromotionStatus } from 'interfaces/Promotions';

type TStatusProps = {
  status: CarStatus | DriverStatus | PromotionStatus | undefined;
};

const Status: React.FC<TStatusProps> = ({ status }) => {
  if (status === CarStatus.Busy) return <OnWork height="24" width="24" />;
  if (status === CarStatus.Review) return <OnReview height="24" width="24" />;
  if (status === CarStatus.Hold) return <OnHold height="24" width="24" />;
  if (status === CarStatus.Pause) return <OnPause height="24" width="24" />;
  if (status === CarStatus.Reject) return <OnDecline height="24" width="24" />;
  if (status === PromotionStatus.new) return <New height="24" width="24" />;
  if (status === PromotionStatus.work) return <OnPromoWork height="24" width="24" />;
  if (status === PromotionStatus.done) return <OnDone height="24" width="24" />;
  if (status === DriverStatus.frozen) return <OnFrozen height="24" width="24" />;
  return null;
};

export default React.memo(Status);
