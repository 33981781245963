import { createAsyncThunk } from '@reduxjs/toolkit';
import Snackbar from 'services/Snackbar';
import { AxiosError } from 'axios';
import { ActionsTypes } from './types';
import { serverErrorText } from 'constants/ServerCode';
import {
  loadAllCarBodiesHelper,
  loadAllCarCitiesHelper,
  loadAllCarClassesHelper,
  loadAllCarColorsHelper,
  loadAllCarMarksHelper,
  loadAllCarModelsHelper,
  loadAllLandingCitiesHelper,
  loadAllTaxStatusesHelper,
  loadAllModelsByMarkHelper,
  findCarMarksEndpoint,
} from './api';

const validateError = (err: any, rejectWithValue: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }
  const errorCode = error.response.status;
  // @ts-ignore
  const errorMessage: string = serverErrorText[errorCode] || error.message;
  Snackbar.show(errorMessage, 'error');
  return rejectWithValue(errorMessage);
};

export const loadCarCitiesHelper = createAsyncThunk(
  ActionsTypes.LOAD_ALL_CAR_CITIES_HELPER,
  async (any, { rejectWithValue }) => {
    try {
      const response = await loadAllCarCitiesHelper();
      return response.data;
    } catch (err) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const loadCarMarksHelper = createAsyncThunk(
  ActionsTypes.LOAD_ALL_CAR_MARKS_HELPER,
  async (any, { rejectWithValue }) => {
    try {
      const response = await loadAllCarMarksHelper();
      return response.data;
    } catch (err) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const loadCarModelsHelper = createAsyncThunk(
  ActionsTypes.LOAD_ALL_CAR_MODELS_HELPER,
  async (any, { rejectWithValue }) => {
    try {
      const response = await loadAllCarModelsHelper();
      return response.data;
    } catch (err) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const loadCarColorsHelper = createAsyncThunk(
  ActionsTypes.LOAD_ALL_CAR_COLORS_HELPER,
  async (any, { rejectWithValue }) => {
    try {
      const response = await loadAllCarColorsHelper();
      return response.data;
    } catch (err) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const loadCarBodiesHelper = createAsyncThunk(
  ActionsTypes.LOAD_ALL_CAR_BODIES_HELPER,
  async (any, { rejectWithValue }) => {
    try {
      const response = await loadAllCarBodiesHelper();
      return response.data;
    } catch (err) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const loadCarClassesHelper = createAsyncThunk(
  ActionsTypes.LOAD_ALL_CAR_CLASSES_HELPER,
  async (any, { rejectWithValue }) => {
    try {
      const response = await loadAllCarClassesHelper();
      return response.data;
    } catch (err) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const loadLandingCitiesHelper = createAsyncThunk(
  ActionsTypes.LOAD_ALL_LANDING_CITIES_HELPER,
  async (any, { rejectWithValue }) => {
    try {
      const response = await loadAllLandingCitiesHelper();
      return response.data;
    } catch (err) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const loadTaxStatusesHelper = createAsyncThunk(
  ActionsTypes.LOAD_ALL_TAX_STATUSES_HELPER,
  async (any, { rejectWithValue }) => {
    try {
      const response = await loadAllTaxStatusesHelper();
      return response.data;
    } catch (err) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const loadModelsByMarkHelper = createAsyncThunk(
  ActionsTypes.LOAD_ALL_MODELS_BY_MARK_HELPER,
  async (modelId: number, { rejectWithValue }) => {
    try {
      const response = await loadAllModelsByMarkHelper(modelId);
      return response.data;
    } catch (err) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const findCarMarks = createAsyncThunk(
  ActionsTypes.FIND_CAR_MARKS,
  async (query: string, { rejectWithValue }) => {
    try {
      const response = await findCarMarksEndpoint(query);

      return response.data.data.map((suggestion: any) => ({
        id: suggestion.value,
        displayName: suggestion.dataName,
      })) as any;
    } catch (err) {
      return validateError(err, rejectWithValue);
    }
  },
);
