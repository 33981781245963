export const ServerCode = {
  success: 200,
  Unauthorized: 401,
  InternalServerError: 500,
  InvalidCredentials: 1001,
  ValidationError: 400,
  UserNotFound: 1006,
} as const;

export const ServerError = {
  emailExist: 'Users email already registered in system',
  InvalidCredentials: 'Invalid credentials',
} as const;

export const serverErrorText: Record<string | number, string> = {
  [ServerCode.InternalServerError]: 'Ошибка доступа',
  [ServerCode.Unauthorized]: 'Пользователь не авторизован',
  [ServerCode.success]: 'Данные успешно обновлены',
  [ServerCode.InvalidCredentials]: 'Пользователя с такими данными не существует',
  [ServerCode.ValidationError]: 'Ошибка валидации',
  [ServerError.emailExist]: 'Пользователь с таким email уже зарегестрирован',
  [ServerError.InvalidCredentials]: 'Неверные логин и пароль',
  [ServerCode.UserNotFound]: 'Пользователь не найден',
};
