import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TState } from '../index';

type TSideBarState = { page: number; open: boolean };

const initialState: TSideBarState = {
  page: 1,
  open: false,
};

const sideBarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    changePage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    changeOpenSideBar(state) {
      state.open = !state.open;
    },
  },
});

export const { changePage, changeOpenSideBar } = sideBarSlice.actions;
export const getSideBar = (state: TState) => state.sideBar;
export default sideBarSlice.reducer;
