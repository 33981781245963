import React, { FC } from 'react';
import { createStyles, Modal, Theme, Backdrop, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

type TProps = {
  url: string;
  isOpen: boolean;
  handleClose: () => void;
};

export const ImageModal: FC<TProps> = ({ url, isOpen, handleClose }) => {
  const classes = useStyles();
  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <img src={url} alt="" className={classes.img} />
        </div>
      </Fade>
    </Modal>
  );
};

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: `translate(-50%, -50%)`,
      outline: '0',
      height: 'auto',
    },
    img: {
      maxWidth: '90vw',
      maxHeight: '90vh',
    },
  }),
);
