import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

type TFieldViewProps = {
  title: string;
  value: string | number | undefined;
};

const FieldView: FC<TFieldViewProps> = ({ title, value }) => {
  const classes = useStyles();

  return (
    <div className={classes.fieldDescription}>
      <div className={classes.fieldDescriptionName}>{title}</div>
      <div className={classes.fieldDescriptionValue}>{value}</div>
    </div>
  );
};

export default React.memo(FieldView);

const useStyles = makeStyles((theme) => ({
  fieldDescription: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  fieldDescriptionName: {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '19px',
    marginBottom: '2px',
    color: '#7D7D7D',
  },
  fieldDescriptionValue: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#000000',
  },
}));
