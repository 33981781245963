import React, { FC, useCallback, useState } from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DoneGeneratePassword } from './elements/doneGeneratePassword';
import { useForm } from 'react-hook-form';
import { resetPassword } from 'store/reducers/auth/actions';
import { Routes } from 'constants/Routes';
import { useAppDispatch } from 'store';
import { useHistory, useLocation } from 'react-router-dom';

type IProps = {};

interface IFormInputs {
  password: string;
  password_repeat: string;
}

export const NewPassword: FC<IProps> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  const code = query.get('resetCode');
  const [openModalStatus, setOpenModalStatus] = useState(false);
  const { register, handleSubmit, errors, setError } = useForm<IFormInputs>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
    defaultValues: {},
  });

  const handleButton = () => setOpenModalStatus(!openModalStatus);

  const onSubmit = useCallback(
    async (data: IFormInputs) => {
      if (data.password === data.password_repeat) {
        await dispatch(resetPassword({ resetCode: code, password: data.password })).then(() => {
          handleButton();
          history.push(Routes.auth);
        });
      } else {
        setError('password', {
          type: 'manual',
          message: 'Пароли не совпадают',
        });
      }
    },
    [useForm],
  );

  return (
    <Grid container spacing={0} alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
      <div className={classes.paper}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <h1 className={classes.hOne}>Придумайте новый пароль</h1>
          <div className={classes.textUnderField}>Новый пароль</div>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            size="small"
            name="password"
            type="password"
            id="password"
            autoComplete="current-password"
            className={classes.textInput}
            inputRef={register({ required: true })}
          />
          <div className={classes.textUnderField}>Подтвердите пароль</div>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            size="small"
            name="password_repeat"
            type="password"
            id="password_repeat"
            autoComplete="current-password"
            className={classes.textInput}
            inputRef={register({ required: true })}
          />
          <div className={classes.error}>{errors?.password?.message}</div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            size="small"
            color="primary"
            classes={{ containedPrimary: classes.containedPrimary, root: classes.buttonText }}
            className={classes.submit}
          >
            Сохранить
          </Button>
        </form>
      </div>
      <DoneGeneratePassword openModalStatus={openModalStatus} buttonClick={handleButton} />
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    marginTop: theme.spacing(2),
    height: '32px',
  },
  containedPrimary: {
    backgroundColor: '#2D9CDB',
    '&:hover': {
      backgroundColor: '#2D9CDB',
    },
  },
  textInfoBlock: {
    marginBottom: theme.spacing(4),
  },
  textInput: {
    '& .MuiOutlinedInput-root': {
      height: '32px',
      '&.Mui-focused fieldset': {
        borderColor: '#2D9CDB',
      },
    },
    marginTop: 0,
    marginBottom: theme.spacing(2),
  },
  textUnderField: {
    color: '#7D7D7D',
    fontWidth: '300',
    fontSize: '16px',
    lineHeight: '18.5px',
    fontStyle: 'normal',
    fontFamily: 'Roboto, sans-serif',
    marginBottom: '2px',
  },
  hOne: {
    color: 'black',
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '28px',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    marginTop: 0,
  },
  buttonText: {
    textTransform: 'inherit',
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '18px',
  },
  error: {
    color: 'red',
  },
}));
