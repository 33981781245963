import React, { ReactElement } from 'react';
import { Car } from 'interfaces/Car';
import Status from 'components/shared/status';
import carsStyles from './styles';

export type OnClickListItem = {
  onEdit: (id: string) => void;
};

export type PropsListItem = {
  item: Car;
} & OnClickListItem;

const CarListItem = ({
  item: { id, mark, city, year, autoClass, driver, status },
  onEdit,
}: PropsListItem): ReactElement | null => {
  const classes = carsStyles();
  const onClickEdit = () => {
    onEdit(id);
  };

  const driverName = driver?.name;

  return (
    <div className={classes.container} role="presentation" onClick={onClickEdit}>
      <div className={classes.infoContainer}>
        <div className={classes.item}>
          <div>{mark}</div>
        </div>
        <div className={classes.item}>
          <div>{city}</div>
        </div>
        <div className={classes.item}>
          <div>{year}</div>
        </div>
        <div className={classes.item}>
          <div>{autoClass}</div>
        </div>
        <div className={classes.item}>
          <div>{driverName}</div>
        </div>
        <div className={classes.item}>
          <Status status={status} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(CarListItem);
