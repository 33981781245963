import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import DeleteButton from 'components/shared/buttons/DeleteButton';

type TProfileHeaderProps = {
  onClickDelete: () => void;
  title: string;
};

export const ProfileHeader: FC<TProfileHeaderProps> = ({ title, onClickDelete }) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <Typography variant="h1">{title}</Typography>
      <div className={classes.buttons}>
        <DeleteButton title="Удалить аккаунт" onClick={onClickDelete} />
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    flexWrap: 'wrap',
  },
  titleContainer: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
  },
  buttons: {
    display: 'flex',
  },
}));
