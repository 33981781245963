import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loadSponsorById, loadSponsors, updateSponsorById } from './actions';
import { initialSponsor, initialState } from './types';
import { TState } from 'store/index';
import { ISponsorsFilters } from 'interfaces/Sponsors';

const sponsorsSlice = createSlice({
  name: 'sponsors',
  initialState,
  reducers: {
    changeFilters(state, action: PayloadAction<ISponsorsFilters>) {
      state.listing.filters = { ...state.listing.filters, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadSponsors.pending, (state) => {
      state.listing.entities = [];
      state.listing.loading = true;
      state.listing.loaded = false;
    });
    builder.addCase(loadSponsors.fulfilled, (state, { payload }) => {
      state.listing.entities = payload.data[0];
      state.listing.count = payload.data[1];
      state.listing.loading = false;
      state.listing.loaded = true;
    });
    builder.addCase(loadSponsors.rejected, (state, action) => {
      state.listing.loading = false;
      state.listing.error = action.error.message;
    });

    builder.addCase(loadSponsorById.pending, (state) => {
      state.item.entity = initialSponsor;
      state.item.loading = true;
      state.item.loaded = false;
    });
    builder.addCase(loadSponsorById.fulfilled, (state, { payload }) => {
      state.item.entity = payload.data;
      state.item.loading = false;
      state.item.loaded = true;
    });
    builder.addCase(loadSponsorById.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error.message;
    });

    builder.addCase(updateSponsorById.pending, (state) => {
      state.item.loading = true;
      state.item.loaded = false;
    });
    builder.addCase(updateSponsorById.fulfilled, (state, { payload }) => {
      state.item.entity = payload.data;
      state.item.loading = false;
      state.item.loaded = true;
    });
    builder.addCase(updateSponsorById.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error.message;
    });
  },
});

export const { changeFilters } = sponsorsSlice.actions;
export const getSponsors = (state: TState) => state.sponsors.listing.entities;
export const getCountSponsors = (state: TState) => state.sponsors.listing.count;
export const getSponsorsFilters = (state: TState) => state.sponsors.listing.filters;
export const getSponsorsLoading = (state: TState) => state.sponsors.listing.loading;
export const getSponsor = (state: TState) => state.sponsors.item.entity;
export const getLoadingSponsor = (state: TState) => state.sponsors.item.loading;
export default sponsorsSlice.reducer;
