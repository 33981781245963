import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LandingLayout, { CONST_LIST_CITY, maxIPadMiniSize, maxIPadProSize, maxIphoneSize } from '../index';
import {
  AllTimeSupport,
  CreativeStickers,
  DiscountBonusesGifts,
  DownloadAndroid,
  DownloadiOS,
  EasyMoney,
  Formalization,
  SafePasting,
  SmartPhones,
  SmartPhonesForPhone,
} from 'assets/landing';
import LayoutHeader from '../elements/header';
import LayoutFooter from '../elements/footer';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { IPromotionsFilters } from '../../../interfaces/Promotions';
import { loadLandingCitiesHelper } from '../../../store/reducers/helpers/actions';
import { getLandingCities } from '../../../store/reducers/helpers';
import CitySelectLanding from '../elements/select/CitySelectLanding';

const LandingDrivers: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };
    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  const { control, errors } = useForm<IPromotionsFilters>({});

  useEffect(() => {
    dispatch(loadLandingCitiesHelper());
  }, []);

  const helperCities = useSelector(getLandingCities);

  return (
    <LandingLayout>
      <Grid className={classes.root}>
        <LayoutHeader />
        <Grid className={classes.howItWork}>Как это работает</Grid>
        <Grid>
          <Typography className={classes.descriptionHowItWork}>
            Мобильное приложение Rekcar позволяет автовладельцам зарабатывать на рекламе и экономить на содержании
            автомобиля. Управляйте сразу несколькими авто благодаря простому интерфейсу. Скачайте и установите
            приложение Rekcar на ваш гаджет из AppStore или Play Market, пройдите регистрацию, заполнив необходимую
            информацию о транспортном средстве и себе. Отправьте заявку на участие в рекламной кампании, после чего вы
            получите подтверждение, если ваш автомобиль соответствует требованиям. Запишетесь в авторизованный центр для
            оклейки авто. Создавайте и отправляйте фотоотчёты о состоянии наклейки, проезжайте установленный километраж
            согласно условиям рекламодателя и после завершения рекламной кампании вы получите денежное вознаграждение на
            ваш счёт или карту.
          </Typography>
        </Grid>
        <Grid className={classes.howItWork}>Где это работает</Grid>
        <Grid className={classes.descriptionWhereItWork}>Мы работаем по всей России</Grid>
        <Grid>
          <form className={classes.formControl}>
            <Controller
              name="city"
              control={control}
              render={(props) => (
                <CitySelectLanding name="city" value={props.value} options={CONST_LIST_CITY} errors={errors} />
              )}
            />
          </form>
        </Grid>
        <div className={classes.plus}>
          <div className={classes.plusItem}>
            <div className={classes.plusLogo}>
              <EasyMoney />
            </div>
            <div className={classes.plusHeader}>Лёгкий заработок</div>
            <div className={classes.plusDescription}>
              Оклейте авто и получайте каждый месяц деньги на карточку. Вы отобьете расходы на бензин и обслуживание
              машины.
            </div>
          </div>
          <div className={classes.plusItem}>
            <div className={classes.plusLogo}>
              <SafePasting />
            </div>
            <div className={classes.plusHeader}>Безопасная оклейка</div>
            <div className={classes.plusDescription}>
              Наши партнеры бережно нанесут и демонтируют оклейку на ваш автомобиль.
            </div>
          </div>
          <div className={classes.plusItem}>
            <div className={classes.plusLogo}>
              <CreativeStickers />
            </div>
            <div className={classes.plusHeader}>Креативные наклейки</div>
            <div className={classes.plusDescription}>
              Дизайнеры ведущих рекламных агентств создадут неповторимый стиль и сделают ваш автомобиль заметным.
            </div>
          </div>
          <div className={classes.plusItem}>
            <div className={classes.plusLogo}>
              <Formalization />
            </div>
            <div className={classes.plusHeader}>Официальное оформление</div>
            <div className={classes.plusDescription}>Заключаем договор</div>
          </div>
          <div className={classes.plusItem}>
            <div className={classes.plusLogo}>
              <AllTimeSupport />
            </div>
            <div className={classes.plusHeader}>Поддержка 24/7</div>
            <div className={classes.plusDescription}>Наши специалисты всегда на связи.</div>
          </div>
          <div className={classes.plusItem}>
            <div className={classes.plusLogo}>
              <DiscountBonusesGifts />
            </div>
            <div className={classes.plusHeader}>Скидки, бонусы, подарки</div>
            <div className={classes.plusDescription}>Получайте дополнительные комплименты от любимых брендов.</div>
          </div>
        </div>
      </Grid>
      <Grid className={classes.lowerBanner} container direction="row">
        <Grid xs justify="center" item container direction="column" wrap="nowrap">
          <Grid item className={classes.lowerBannerText}>
            Действуй — заработай на авто
          </Grid>
          <Grid className={classes.mobilePNG} item container direction="row">
            <DownloadiOS className={classes.downloadIOS} />
            <DownloadAndroid className={classes.downloadAndroid} />
          </Grid>
        </Grid>
        <Grid xs item container justify="flex-end">
          <img
            src={width > maxIPadProSize ? SmartPhones : SmartPhonesForPhone}
            className={classes.smartPhoto}
            alt="layoutPhoto"
          />
        </Grid>
      </Grid>
      <LayoutFooter />
    </LandingLayout>
  );
};

export default LandingDrivers;

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
  formControl: {
    marginLeft: 128,
    marginBottom: 104,
    [theme.breakpoints.up(maxIPadProSize)]: {
      margin: '0 128px',
      marginBottom: 104,
    },
    [theme.breakpoints.between(maxIPadMiniSize, maxIPadProSize)]: {
      margin: '0 72px',
      marginBottom: 64,
    },
    [theme.breakpoints.between(maxIphoneSize, maxIPadMiniSize)]: {
      margin: '0 16px',
      marginBottom: 48,
    },
    [theme.breakpoints.down(maxIphoneSize)]: {
      margin: '0 16px',
      marginBottom: 48,
    },
  },
  howItWork: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      margin: '0 128px',
      fontSize: 64,
      marginBottom: 48,
    },
    [theme.breakpoints.between(maxIPadMiniSize, maxIPadProSize)]: {
      margin: '0 72px',
      fontSize: 40,
      marginBottom: 16,
    },
    [theme.breakpoints.down(maxIPadMiniSize)]: {
      margin: '0 16px',
      fontSize: 32,
      marginBottom: 16,
    },
    fontFamily: ['Golos'].join(','),
    fontStyle: 'normal',
    fontWeight: 'bold',
  },
  descriptionHowItWork: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      margin: '0 128px',
      fontSize: 24,
      lineHeight: '29px',
      marginBottom: 104,
    },
    [theme.breakpoints.between(maxIPadMiniSize, maxIPadProSize)]: {
      margin: '0 72px',
      fontSize: 16,
      lineHeight: '19px',
      marginBottom: 64,
    },
    [theme.breakpoints.between(maxIphoneSize, maxIPadMiniSize)]: {
      margin: '0 16px',
      fontSize: 16,
      lineHeight: '19px',
      marginBottom: 48,
    },
    [theme.breakpoints.down(maxIphoneSize)]: {
      margin: '0 16px',
      fontSize: 16,
      lineHeight: '19px',
      marginBottom: 48,
    },
    fontFamily: ['Golos'].join(','),
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  descriptionWhereItWork: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      margin: '0 128px',
      fontSize: 24,
      lineHeight: '29px',
      marginBottom: 32,
    },
    [theme.breakpoints.between(maxIPadMiniSize, maxIPadProSize)]: {
      margin: '0 72px',
      fontSize: 16,
      lineHeight: '19px',
      marginBottom: 16,
    },
    [theme.breakpoints.between(maxIphoneSize, maxIPadMiniSize)]: {
      margin: '0 16px',
      fontSize: 16,
      lineHeight: '19px',
      marginBottom: 16,
    },
    [theme.breakpoints.down(maxIphoneSize)]: {
      margin: '0 16px',
      fontSize: 16,
      lineHeight: '19px',
      marginBottom: 16,
    },
    fontFamily: ['Golos'].join(','),
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  lowerBanner: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      paddingLeft: 128,
    },
    [theme.breakpoints.between(maxIPadMiniSize, maxIPadProSize)]: {
      paddingLeft: 72,
    },
    [theme.breakpoints.between(maxIphoneSize, maxIPadMiniSize)]: {
      paddingLeft: 16,
    },
    [theme.breakpoints.down(maxIphoneSize)]: {
      paddingLeft: 16,
    },
    background: '#CC2631',
    minHeight: 549,
  },
  lowerBannerText: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      fontSize: 64,
      lineHeight: '77px',
      paddingTop: 126,
    },
    [theme.breakpoints.between(maxIPadMiniSize, maxIPadProSize)]: {
      fontSize: 40,
      lineHeight: '48px',
      paddingTop: 32,
    },
    [theme.breakpoints.between(maxIphoneSize, maxIPadMiniSize)]: {
      fontSize: 32,
      lineHeight: '38px',
      paddingTop: 32,
    },
    [theme.breakpoints.down(maxIphoneSize)]: {
      fontSize: 24,
      lineHeight: '24px',
      paddingTop: 32,
    },
    fontFamily: 'Golos',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
  smartPhoto: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      width: 679,
      height: '100%',
    },
    [theme.breakpoints.between(maxIPadMiniSize, maxIPadProSize)]: {
      width: 517,
      height: 605,
    },
    [theme.breakpoints.between(maxIphoneSize, maxIPadMiniSize)]: {
      width: 433,
      height: 513,
    },
    [theme.breakpoints.down(maxIphoneSize)]: {
      width: 381,
      height: 466,
    },
  },
  plus: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      margin: '0 128px',
    },
    [theme.breakpoints.between(maxIPadMiniSize, maxIPadProSize)]: {
      margin: '0 72px',
    },
    [theme.breakpoints.between(maxIphoneSize, maxIPadMiniSize)]: {
      margin: '0 16px',
    },
    [theme.breakpoints.down(maxIphoneSize)]: {
      margin: '0 16px',
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  plusItem: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      width: 450,
      marginBottom: 64,
    },
    [theme.breakpoints.between(maxIphoneSize, maxIPadProSize)]: {
      width: 267,
      marginBottom: 64,
    },
    [theme.breakpoints.down(maxIphoneSize)]: {
      width: 343,
      marginBottom: 48,
    },
    display: 'flex',
    flexDirection: 'column',
  },
  plusLogo: {
    display: 'flex',
    justifyContent: 'center',
  },
  plusHeader: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      fontSize: 32,
      lineHeight: '38px',
      marginTop: 23.48,
      marginBottom: 32,
    },
    [theme.breakpoints.between(maxIPadMiniSize, maxIPadProSize)]: {
      fontSize: 20,
      lineHeight: '24px',
      margin: '16px 0',
    },
    [theme.breakpoints.down(maxIPadMiniSize)]: {
      fontSize: 24,
      lineHeight: '29px',
      margin: '16px 0',
    },
    fontFamily: ['Golos'].join(','),
    fontStyle: 'normal',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  plusDescription: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      fontSize: 24,
      lineHeight: '29px',
    },
    [theme.breakpoints.down(maxIPadProSize)]: {
      fontSize: 16,
      lineHeight: '19px',
    },
    fontFamily: ['Golos'].join(','),
    fontStyle: 'normal',
    fontWeight: 'normal',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  mobilePNG: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      maxWidth: 394,
      marginRight: 128,
      marginTop: 88,
      marginBottom: 28,
    },
    [theme.breakpoints.down(maxIPadProSize)]: {
      maxWidth: 288,
      marginRight: 16,
      marginBottom: 28,
      marginTop: 28,
    },
    display: 'flex',
    flexDirection: 'row',
    height: 96,
    justifyContent: 'space-between',
    alignContent: 'space-between',
  },
  downloadIOS: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      height: 55,
      width: 189.01,
    },
    [theme.breakpoints.down(maxIPadProSize)]: {
      height: 40,
      width: 136,
    },
  },
  downloadAndroid: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      height: 55,
      width: 185.62,
    },
    [theme.breakpoints.down(maxIPadProSize)]: {
      height: 40,
      width: 135,
    },
  },
}));
