import * as Yup from 'yup';
import VALIDATION_MESSAGES, { DEFAULT_MAX_TEXT_LENGTH } from 'constants/ValidationsMessage';

export const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required(VALIDATION_MESSAGES.required)
    .nullable()
    .min(1, VALIDATION_MESSAGES.minLength(1))
    .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength()),
  city: Yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return Yup.object().required(VALIDATION_MESSAGES.required).nullable();
      case 'string':
        return Yup.string()
          .required(VALIDATION_MESSAGES.required)
          .nullable()
          .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength());
      default:
        return Yup.mixed();
    }
  }),
  autoClass: Yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return Yup.object().required(VALIDATION_MESSAGES.required).nullable();
      case 'string':
        return Yup.string()
          .required(VALIDATION_MESSAGES.required)
          .nullable()
          .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength());
      default:
        return Yup.mixed();
    }
  }),
  distribution: Yup.number()
    .required(VALIDATION_MESSAGES.required)
    .nullable()
    .test('minLength', VALIDATION_MESSAGES.minLength(1), (val: any) => val && val.toString().length >= 1)
    .test('maxLength', VALIDATION_MESSAGES.maxLength(), (val: any) => val && val.toString().length <= 100)
    .transform((val) => val || null),
  start: Yup.string()
    .required(VALIDATION_MESSAGES.required)
    .nullable()
    .min(1, VALIDATION_MESSAGES.minLength(1))
    .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength()),
  finish: Yup.string()
    .required(VALIDATION_MESSAGES.required)
    .nullable()
    .min(1, VALIDATION_MESSAGES.minLength(1))
    .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength()),
  budget: Yup.number()
    .required(VALIDATION_MESSAGES.required)
    .nullable()
    .test('minLength', VALIDATION_MESSAGES.minLength(1), (val: any) => val && val.toString().length >= 1)
    .test('maxLength', VALIDATION_MESSAGES.maxLength(), (val: any) => val && val.toString().length <= 100)
    .transform((val) => val || null),
  reportPeriod: Yup.number()
    .required(VALIDATION_MESSAGES.required)
    .nullable()
    .test('minLength', VALIDATION_MESSAGES.minLength(1), (val: any) => val && val.toString().length >= 1)
    .test('maxLength', VALIDATION_MESSAGES.maxLength(), (val: any) => val && val.toString().length <= 100)
    .transform((val) => val || null),
  eMail: Yup.string()
    .required(VALIDATION_MESSAGES.required)
    .nullable()
    .email(VALIDATION_MESSAGES.incorrectEmail)
    .min(1, VALIDATION_MESSAGES.minLength(1))
    .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength()),
  description: Yup.string()
    .required(VALIDATION_MESSAGES.required)
    .nullable()
    .min(1, VALIDATION_MESSAGES.minLength(1))
    .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength()),
});
