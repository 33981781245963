import React, { ReactElement } from 'react';
import AdCompaniesListTitle from 'components/containers/adCompanies/listing/elements/list/AdCompaniesListTitle';
import AdCompaniesListItem from 'components/containers/adCompanies/listing/elements/list/AdCompaniesListItem';
import carsStyles from './styles';
import { Promotion } from 'interfaces/Promotions';

export type OnClickListItem = {
  onEdit: (id: string) => void;
};

export type PropsListItem = {
  items: Promotion[];
} & OnClickListItem;

const AdCompaniesList = ({ items, onEdit }: PropsListItem): ReactElement | null => {
  const classes = carsStyles();

  return (
    <>
      <AdCompaniesListTitle />
      {items.map((promotion: Promotion) => (
        <div key={promotion.id} className={classes.tileItem} aria-hidden="true">
          <AdCompaniesListItem onEdit={onEdit} item={promotion} />
        </div>
      ))}
    </>
  );
};

export default React.memo(AdCompaniesList);
