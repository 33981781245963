import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from 'constants/Routes';
import { useDispatch, useSelector } from 'react-redux';
import BaseLayout from 'components/baseLayout';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CarHeader from 'components/containers/cars/item/elements/header';
import ColumnContainer from 'components/layouts/columnContainer';
import CarView from 'components/containers/cars/item/elements/view';
import CarAds from 'components/containers/cars/item/elements/ads';
import DriverView from 'components/containers/cars/item/elements/driver';
import Modal from 'components/shared/modal';
import CarEdit from 'components/containers/cars/item/elements/edit';
import { CarEditForm, DriverEditForm } from 'constants/Forms';
import ConfirmActionModal from 'components/shared/modal/ConfirmActionModal';
import { DriverEdit } from 'components/containers/drivers/item/elements/edit';
import {
  loadCarById,
  deleteCarById,
  pauseCar,
  loadCarPhotoReport,
  rejectCarById,
  restoreCarById,
} from 'store/reducers/cars/actions';
import { getCar, getCarPhotoReport, getLoadingCar } from 'store/reducers/cars';
import Loader from 'components/shared/loader';
import { Controller, useForm } from 'react-hook-form';
import CustomAutocompleteSelect from 'components/shared/select/CustomAutocompleteSelect';
import { busyCar, holdCar, loadPromotions, rejectCar } from 'store/reducers/promotions/actions';
import { getPromotions } from 'store/reducers/promotions';
import { initialPromotionsFilters } from 'store/reducers/promotions/types';
import ControlledRangeDatePicker from 'components/shared/pickers/ControlledRangeDatePicker';
import { format } from 'date-fns';
import {
  loadCarCitiesHelper,
  loadCarClassesHelper,
  loadCarBodiesHelper,
  loadCarColorsHelper,
  loadTaxStatusesHelper,
  loadCarMarksHelper,
} from 'store/reducers/helpers/actions';
import { changeFilters } from 'store/reducers/cars';
import { initialCarsFilters } from 'interfaces/Car';
import { getUserMe } from '../../../../store/reducers/users';

const titles = {
  descriptions: 'Описание транспортного средства',
  ads: 'Рекламные кампании',
  driver: 'Водитель',
};

const CarContainer: React.FC = () => {
  const classes = useStyles();
  const { handleSubmit, control, errors } = useForm<any>();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const car = useSelector(getCar);
  const isLoadingCar = useSelector(getLoadingCar);
  const carTitle = `${car.mark} ${car.model}`;
  const promotions = useSelector(getPromotions);
  const photoReports = useSelector(getCarPhotoReport);
  const userMe = useSelector(getUserMe);

  const [openEditModal, setOpenEditModal] = React.useState(false);
  const handleCloseEditModal = () => setOpenEditModal(false);
  const handleOpenEditModal = () => setOpenEditModal(true);

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const [openEditDriverModal, setOpenEditDriverModal] = React.useState(false);
  const handleCloseEditDriverModal = () => setOpenEditDriverModal(false);
  const handleOpenEditDriverModal = () => setOpenEditDriverModal(true);

  const [openDeclineModal, setOpenDeclineModal] = React.useState(false);
  const handleOpenDeclineModal = () => setOpenDeclineModal(true);
  const handleCloseDeclineModal = () => setOpenDeclineModal(false);

  const [openSignUpAdModal, setOpenSignUpAdModal] = React.useState(false);
  const handleOpenSignUpAdModal = () => setOpenSignUpAdModal(true);
  const handleCloseSignUpAdModal = () => setOpenSignUpAdModal(false);

  const [openPauseModal, setOpenPauseModal] = React.useState(false);
  const handleClosePauseModal = () => setOpenPauseModal(false);
  const handleOpenPauseModal = () => setOpenPauseModal(true);

  const onClickBack = () => {
    history.goBack();
  };

  const onClickDelete = useCallback(async () => {
    await dispatch(deleteCarById(id));
    dispatch(changeFilters(initialCarsFilters));
    history.push(Routes.cars);
  }, [history, id, dispatch]);

  const onClickDecline = useCallback(async () => {
    if (car.promotion) {
      dispatch(rejectCar({ carId: id, promoId: car.promotion.id }));
    } else {
      dispatch(rejectCarById(id));
    }
    handleCloseDeclineModal();
  }, [dispatch, id, car.promotion]);

  const onClickReview = useCallback(async () => {
    dispatch(restoreCarById(id));
  }, [dispatch, id]);

  const onClickPauseStop = useCallback(async () => {
    dispatch(busyCar({ carId: id, promoId: car?.promotion?.id || '' }));
  }, [dispatch, id, car?.promotion?.id]);

  const onClickPause = useCallback(
    (data) => {
      const on = data.start ? `${format(data.start, 'MM/dd/yyyy')}` : `${format(new Date(), 'MM/dd/yyyy')}`;
      const off = data.finish ? `${format(data.finish, 'MM/dd/yyyy')}` : `${format(new Date(), 'MM/dd/yyyy')}`;
      dispatch(pauseCar({ carId: id, on, off }));
      handleClosePauseModal();
    },
    [id, dispatch],
  );

  useEffect(() => {
    dispatch(loadPromotions({ ...initialPromotionsFilters, limit: undefined, offset: undefined }));
    dispatch(loadCarById(id));
  }, [id, dispatch]);

  useEffect(() => {
    car.promotion && dispatch(loadCarPhotoReport(id));
  }, [id, dispatch, car.promotion]);

  useEffect(() => {
    dispatch(loadCarCitiesHelper());
    dispatch(loadCarClassesHelper());
    dispatch(loadCarBodiesHelper());
    dispatch(loadCarColorsHelper());
    dispatch(loadTaxStatusesHelper());
    dispatch(loadCarMarksHelper());
  }, [dispatch]);

  const onSaveDriver = useCallback(async () => {
    handleCloseEditDriverModal();
    await dispatch(loadCarById(id));
  }, [id, dispatch]);

  const onClickSignUpAd = useCallback(
    (data) => {
      dispatch(holdCar({ carId: id, promoId: data.promos.id }));
      handleCloseSignUpAdModal();
    },
    [dispatch, id],
  );

  return (
    <BaseLayout>
      <Grid>
        <Grid item container xs={12} className={classes.padding}>
          <CarHeader title={carTitle} onClickBack={onClickBack} onClickDelete={handleOpenDeleteModal} />
        </Grid>
        <Grid item container xs={12}>
          <Grid item lg={4} xs={12} className={classes.borderTop}>
            <ColumnContainer title={titles.descriptions} onClickIcon={handleOpenEditModal} enableButton={userMe?.admin}>
              {isLoadingCar ? (
                <Loader show />
              ) : (
                <CarView
                  car={car}
                  onClickReview={onClickReview}
                  onClickPauseStart={handleOpenPauseModal}
                  onClickPauseStop={onClickPauseStop}
                  onClickDecline={handleOpenDeclineModal}
                />
              )}
            </ColumnContainer>
          </Grid>
          <Grid item lg={4} xs={12} className={classes.border}>
            <ColumnContainer title={titles.ads} onClick={handleOpenSignUpAdModal} enableButton={userMe?.admin}>
              <CarAds ad={car?.promotion} carId={id} photoReports={photoReports} />
            </ColumnContainer>
          </Grid>
          <Grid item lg={4} xs={12} className={classes.borderTop}>
            <ColumnContainer title={titles.driver} onClickIcon={handleOpenEditDriverModal} enableButton={userMe?.admin}>
              <DriverView driver={car?.driver || car} />
            </ColumnContainer>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        actionTitle="Сохранить"
        formId={CarEditForm}
        modalTitle="Транспортное средство"
      >
        {isLoadingCar ? (
          <Loader show />
        ) : (
          <CarEdit driverId={car?.driver?.id} onSave={handleCloseEditModal} car={car} />
        )}
      </Modal>
      <Modal
        open={openEditDriverModal}
        onClose={handleCloseEditDriverModal}
        actionTitle="Сохранить"
        formId={DriverEditForm}
        modalTitle="Водитель"
      >
        {isLoadingCar ? <Loader show /> : <DriverEdit driver={car.driver} onSave={onSaveDriver} />}
      </Modal>
      <ConfirmActionModal
        openModalStatus={openDeleteModal}
        buttonClose={handleCloseDeleteModal}
        buttonDelete={onClickDelete}
        actionText="Удалить"
        text="транспортное средство"
      />
      <ConfirmActionModal
        openModalStatus={openDeclineModal}
        buttonClose={handleCloseDeclineModal}
        buttonDelete={onClickDecline}
        actionText="Отклонить"
        text="транспортное средство"
      />
      <Modal
        open={openSignUpAdModal}
        onClose={handleCloseSignUpAdModal}
        actionTitle="Записать на рекламу"
        modalTitle="Запись на рекламу"
        onClick={handleSubmit(onClickSignUpAd)}
      >
        <Grid item container xs={12} justify="center">
          <form style={{ width: '100%' }}>
            <Controller
              name="promos"
              control={control}
              render={(props) => (
                <CustomAutocompleteSelect
                  value={props.value}
                  options={promotions}
                  title="Рекламная кампания"
                  onChange={props.onChange}
                  errors={errors}
                  name="PromotionsId"
                />
              )}
            />
          </form>
        </Grid>
      </Modal>
      <Modal
        open={openPauseModal}
        onClose={handleClosePauseModal}
        actionTitle="Поставить на паузу"
        modalTitle="Планирование паузы"
        onClick={handleSubmit(onClickPause)}
      >
        <Grid container justify="center">
          <form>
            <ControlledRangeDatePicker
              label="Сроки паузы"
              startTimeName="start"
              endTimeName="finish"
              control={control}
              errors={errors}
            />
          </form>
        </Grid>
      </Modal>
    </BaseLayout>
  );
};

export default CarContainer;

const useStyles = makeStyles((theme: Theme) => ({
  padding: {
    padding: 16,
  },
  borderTop: {
    borderTop: '1px solid #F2F2F2',
  },
  border: {
    borderLeft: '1px solid #F2F2F2',
    borderRight: '1px solid #F2F2F2',
    borderTop: '1px solid #F2F2F2',
    [theme.breakpoints.down('md')]: {
      borderLeft: 'none',
      borderRight: 'none',
    },
  },
}));
