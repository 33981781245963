import React, { ReactElement } from 'react';
import AdvertisersStyles from './styles';
import AdvertiserListTitle from 'components/containers/advertisers/listing/elements/list/AdvertiserListTitle';
import AdvertiserListItem from 'components/containers/advertisers/listing/elements/list/AdvertiserListItem';
import { Sponsor } from 'interfaces/Sponsors';

export type OnClickListItem = {
  onEdit: (id: string) => void;
};

export type PropsListItem = {
  items: Sponsor[];
} & OnClickListItem;

const AdvertiserList = ({ items, onEdit }: PropsListItem): ReactElement | null => {
  const classes = AdvertisersStyles();

  return (
    <>
      <AdvertiserListTitle />
      {items.map((advertiser: Sponsor) => (
        <div key={advertiser.id} className={classes.tileItem} aria-hidden="true">
          <AdvertiserListItem onEdit={onEdit} item={advertiser} />
        </div>
      ))}
    </>
  );
};

export default React.memo(AdvertiserList);
