import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import AcceptButton from 'components/shared/buttons/AcceptButton';
import ClearButton from 'components/shared/buttons/ClearButton';

type TModalProps = {
  open: boolean;
  onClose(): void;
  onClick?: () => void;
  children: React.ReactNode;
  actionClassName?: string;
  actionTitle: string;
  modalTitle: string;
  formId?: string;
};

type DialogTitleProps = {
  children: React.ReactNode;
  onClose(): void;
};

type DialogActionsProps = {
  onClose(): void;
  onClick?: () => void;
  actionClassName?: string;
  actionTitle: string;
  formId?: string;
};

const DialogTitle = (props: DialogTitleProps) => {
  const classes = useStyles();
  const { children, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h2">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const Actions = (props: DialogActionsProps) => {
  const { onClose, onClick, actionTitle, formId } = props;
  const handleClick = () => {
    onClick && onClick();
  };
  return (
    <>
      <ClearButton title="Отмена" onClick={onClose} />
      <AcceptButton className={props.actionClassName} formId={formId} title={actionTitle} onClick={handleClick} />
    </>
  );
};

const Modal: React.FC<TModalProps> = ({
  actionClassName,
  open,
  onClick,
  onClose,
  actionTitle,
  modalTitle,
  formId,
  children,
}) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose} classes={{ paperFullWidth: classes.paper }} fullWidth>
      <DialogTitle onClose={onClose}>{modalTitle}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Actions
          onClose={onClose}
          onClick={onClick}
          actionClassName={actionClassName}
          actionTitle={actionTitle}
          formId={formId}
        />
      </DialogActions>
    </Dialog>
  );
};

export default Modal;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 4,
  },
  paper: {
    maxWidth: 370,
  },
  dialogActions: {
    justifyContent: 'center',
  },
}));
