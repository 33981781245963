import React, { ReactElement } from 'react';
import driversStyles from './styles';
import classnames from 'classnames';

const DriverListTitle = (): ReactElement | null => {
  const classes = driversStyles();
  return (
    <div className={classes.infoContainer} style={{ paddingTop: 0 }}>
      <div className={classnames(classes.item, classes.title)}>
        <div>ФИО</div>
      </div>
      <div className={classnames(classes.item, classes.title)}>
        <div>Город</div>
      </div>
      <div className={classnames(classes.item, classes.title)}>
        <div>E-mail</div>
      </div>
      <div className={classnames(classes.item, classes.title)}>
        <div>Статус</div>
      </div>
    </div>
  );
};

export default React.memo(DriverListTitle);
