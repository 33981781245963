import { createSlice } from '@reduxjs/toolkit';
import { TState } from 'store/index';
import { initialState, initialUser } from './types';
import { getUserAuth, loadUserByMe, loadUsers, updateMeSponsor, updateUserByMe } from './actions';

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetMe(state) {
      state.me.entity = initialState.me.entity;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadUsers.pending, (state) => {
      state.listing.entities = [];
      state.listing.loading = true;
      state.listing.loaded = false;
    });
    builder.addCase(loadUsers.fulfilled, (state, { payload }) => {
      state.listing.entities = payload.data;
      state.listing.loading = false;
      state.listing.loaded = true;
    });
    builder.addCase(loadUsers.rejected, (state, action) => {
      state.listing.loading = false;
      state.listing.error = action.error.message;
    });

    builder.addCase(loadUserByMe.pending, (state) => {
      state.me.entity = initialUser;
      state.me.loading = true;
      state.me.loaded = false;
    });
    builder.addCase(loadUserByMe.fulfilled, (state, { payload }) => {
      state.me.entity = payload.data;
      state.me.loading = false;
      state.me.loaded = true;
    });
    builder.addCase(loadUserByMe.rejected, (state, action) => {
      state.me.loading = false;
      state.me.error = action.error.message;
    });

    builder.addCase(updateUserByMe.pending, (state) => {
      state.me.loading = true;
      state.me.loaded = false;
    });
    builder.addCase(updateUserByMe.fulfilled, (state, { payload }) => {
      state.me.entity = payload.data;
      state.me.loading = false;
      state.me.loaded = true;
    });
    builder.addCase(updateUserByMe.rejected, (state, action) => {
      state.me.loading = false;
      state.me.error = action.error.message;
    });

    builder.addCase(updateMeSponsor.pending, (state) => {
      state.me.loading = true;
      state.me.loaded = false;
    });
    builder.addCase(updateMeSponsor.fulfilled, (state, { payload }) => {
      state.me.entity = { ...state.me.entity, sponsor: payload.data };
      state.me.loading = false;
      state.me.loaded = true;
    });
    builder.addCase(updateMeSponsor.rejected, (state, action) => {
      state.me.loading = false;
      state.me.error = action.error.message;
    });
  },
});

export const { resetMe } = usersSlice.actions;
export const getUsers = (state: TState) => state.users.listing.entities;
export const getUserMe = (state: TState) => state.users.me.entity;
export const getLoadingUserMe = (state: TState) => state.users.me.loading;
export const getLoadedUserMe = (state: TState) => state.users.me.loaded;
export default usersSlice.reducer;
