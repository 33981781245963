import React from 'react';
import { Grid } from '@material-ui/core';
import FieldView from 'components/shared/inputs/FieldView';
import { User } from 'interfaces/User';

type TProfileViewProps = {
  profile: User;
};

export const ProfileView: React.FC<TProfileViewProps> = ({ profile }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <FieldView title="ФИО:" value={profile?.name} />
        <FieldView title="E-mail:" value={profile?.email} />
      </Grid>
    </Grid>
  );
};
