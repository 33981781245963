import React, { ReactNode } from 'react';
import cn from 'classnames';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Back } from 'assets/images';
import { useHistory } from 'react-router';

type Props = {
  className?: string;
  title: string;
  back?: boolean;
  children?: ReactNode;
};

export const Header = ({ className, title, back = false, children }: Props) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={cn(classes.root, className)}>
      {back && <Back className={classes.back} onClick={history.goBack} />}
      <Typography className={classes.title} component="h2">
        {title}
      </Typography>
      <div className={classes.actions}>{children}</div>
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2),
    },
    back: {
      flex: '0 0 auto',
      marginRight: theme.spacing(1.5),
      cursor: 'pointer',
    },
    title: {
      fontWeight: 300,
      fontSize: 24,
      lineHeight: '28px',
      color: '#7d7d7d',
    },
    actions: {
      flex: '0 0 auto',
      display: 'flex',
      marginLeft: 'auto',

      '& > *': {
        marginRight: theme.spacing(1),
      },

      '& > *:last-of-type': {
        marginRight: 0,
      },
    },
  }),
);
