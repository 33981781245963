import React, { ReactElement, useCallback, useEffect } from 'react';
import ControlledRangeDatePicker from 'components/shared/pickers/ControlledRangeDatePicker';
import AcceptButton from 'components/shared/buttons/AcceptButton';
import ClearButton from 'components/shared/buttons/ClearButton';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Controller, useForm } from 'react-hook-form';
import { IPromotionsFilters } from 'interfaces/Promotions';
import { initialPromotionsFilters } from 'store/reducers/promotions/types';
import { useDispatch, useSelector } from 'react-redux';
import { getCities, getClasses } from 'store/reducers/helpers';
import CustomAutocompleteSelect from 'components/shared/select/CustomAutocompleteSelect';
import { loadCarCitiesHelper, loadCarClassesHelper } from 'store/reducers/helpers/actions';
import { loadSponsors } from 'store/reducers/sponsors/actions';
import { getSponsors } from 'store/reducers/sponsors';
import { format } from 'date-fns';
import { getLoadedUserMe, getLoadingUserMe, getUserMe } from '../../../../../../store/reducers/users';
import { loadUserByMe } from '../../../../../../store/reducers/users/actions';
import { getPromotionsFilters } from 'store/reducers/promotions';

export type TFiltersProps = {
  filters: IPromotionsFilters;
  onChangeFilters: (value: any) => void;
};

const initialFilters = {
  status: '',
  city: '',
  autoClass: '',
  sponsorName: '',
  startTime: null,
  endTime: null,
  searchStr: '',
};

const STATUSES_OPTIONS = [
  {
    displayName: 'новая',
    id: 0,
  },
  {
    displayName: 'активная',
    id: 1,
  },
  {
    displayName: 'завершенная',
    id: 2,
  },
];

const AdCompaniesFilters = ({ filters, onChangeFilters }: TFiltersProps): ReactElement => {
  const classes = useStyles();
  const { handleSubmit, control, errors, reset } = useForm<IPromotionsFilters>({
    defaultValues: initialPromotionsFilters,
  });

  const { admin: isAdmin } = useSelector(getUserMe);
  const { name } = useSelector(getPromotionsFilters);

  const dispatch = useDispatch();

  useEffect(() => {
    onChangeFilters(initialPromotionsFilters);
  }, [onChangeFilters]);

  useEffect(() => {
    dispatch(loadCarCitiesHelper());
    dispatch(loadCarClassesHelper());
  }, [dispatch]);

  useEffect(() => {
    if (isAdmin) dispatch(loadSponsors({ limit: undefined, offset: undefined }));
  }, [dispatch, isAdmin]);

  const helperCities = useSelector(getCities);
  const helperClasses = useSelector(getClasses);
  const sponsors = useSelector(getSponsors);

  const handleChangeFilters = useCallback(
    (data) => {
      if (data) {
        onChangeFilters({
          status: data.status?.displayName,
          autoClass: data.autoClass?.displayName,
          sponsorName: data.sponsorName?.name,
          city: data.city?.displayName,
          start: data.start && format(data.start, 'MM/dd/yyyy'),
          finish: data.finish && format(data.finish, 'MM/dd/yyyy'),
          offset: undefined,
        });
      }
    },
    [onChangeFilters],
  );

  const handleResetFilters = useCallback(() => {
    onChangeFilters({ ...initialPromotionsFilters, name });
    reset({ ...initialPromotionsFilters, ...initialFilters });
  }, [name, reset, onChangeFilters]);

  return (
    <div>
      <form className={classes.container}>
        <Controller
          name="status"
          control={control}
          render={(props) => (
            <CustomAutocompleteSelect
              name="status"
              value={props.value}
              options={STATUSES_OPTIONS}
              title="Статус:"
              onChange={props.onChange}
            />
          )}
        />
        <Controller
          name="autoClass"
          control={control}
          render={(props) => (
            <CustomAutocompleteSelect
              name="autoClass"
              value={props.value}
              options={helperClasses.entities}
              title="Класс авто:"
              onChange={props.onChange}
            />
          )}
        />
        {isAdmin && (
          <Controller
            name="sponsorName"
            control={control}
            render={(props) => (
              <CustomAutocompleteSelect
                name="sponsorName"
                value={props.value}
                options={sponsors}
                title="Организация:"
                onChange={props.onChange}
              />
            )}
          />
        )}
        <Controller
          name="city"
          control={control}
          render={(props) => (
            <CustomAutocompleteSelect
              name="city"
              value={props.value}
              options={helperCities.entities}
              title="Город:"
              onChange={props.onChange}
            />
          )}
        />
        <ControlledRangeDatePicker
          label="Сроки проведения:"
          startTimeName="start"
          endTimeName="finish"
          control={control}
          errors={errors}
        />
      </form>
      <div className={classes.containerButton}>
        <ClearButton onClick={handleResetFilters} />
        <AcceptButton onClick={handleSubmit(handleChangeFilters)} />
      </div>
    </div>
  );
};

export default AdCompaniesFilters;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    '& > :nth-child(n)': {
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  containerButton: {
    display: 'flex',
    width: 222,
  },
}));
