import { MENU_LIST_LANDING } from '../../index';
import Button from '@material-ui/core/Button';
import ListItemLink from '@material-ui/core/ListItem';
import { NavLink, useLocation } from 'react-router-dom';
import { DotNotSelected, DotSelected } from '../../../../assets/landing';
import ListItemText from '@material-ui/core/ListItemText';
import classnames from 'classnames';
import { List } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const MenuList = () => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <List>
      {MENU_LIST_LANDING.map(({ id, title, route }) => (
        <Button key={id} className={classes.pageButton}>
          <ListItemLink component={NavLink} to={route} className={classes.item}>
            {location.pathname.split('/')[2] === route.split('/')[2] ? (
              <>
                <DotSelected className={classes.dot} />
                <ListItemText className={classnames(classes.itemText, classes.itemTextColorSelected)} primary={title} />
              </>
            ) : (
              <>
                <DotNotSelected className={classes.dot} />
                <ListItemText
                  className={classnames(classes.itemText, classes.itemTextColorNotSelected)}
                  primary={title}
                />
              </>
            )}
          </ListItemLink>
        </Button>
      ))}
    </List>
  );
};

export default MenuList;

const useStyles = makeStyles((theme) => ({
  pageButton: {
    width: '100%',
  },
  dot: {
    marginRight: 10,
  },
  item: {
    height: 28,
  },
  itemText: {
    '& span': {
      width: '160px',
      textTransform: 'initial',
      fontFamily: 'Golos',
      fontWeight: 'normal',
      fontSize: 18,
    },
  },
  itemTextColorSelected: {
    color: '#000000',
  },
  itemTextColorNotSelected: {
    color: 'rgba(0, 0, 0, 0.5);',
  },
}));
