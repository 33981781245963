import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteById,
  loadAll,
  loadById,
  updateById,
  create,
  reject,
  hold,
  busy,
  loadFreePromoCar,
  deleteFile,
  loadPromoCars,
  uploadMaketById,
  start,
  finish,
  maps,
} from 'store/reducers/promotions/api';
import Snackbar from 'services/Snackbar';
import { AxiosError } from 'axios';
import { ActionsTypes } from 'store/reducers/promotions/types';
import { Promotion, IPromotionsFilters, ILoadPromotionCars, IUploadPromoMaket } from 'interfaces/Promotions';
import { serverErrorText } from 'constants/ServerCode';
import { ICarsFilters, SignCarForPromo } from 'interfaces/Car';

const validateError = (err: AxiosError) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }
  const errorCode = error.response.status;
  // @ts-ignore
  const errorMessage: string = serverErrorText[errorCode] || error.message;
  Snackbar.show(errorMessage, 'error');
  return errorMessage;
};

export const loadPromotions = createAsyncThunk(
  ActionsTypes.LOAD_ALL_PROMOTIONS,
  async (filters: IPromotionsFilters, { rejectWithValue }) => {
    try {
      const response = await loadAll(filters);
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);

export const loadPromotionById = createAsyncThunk(
  ActionsTypes.LOAD_PROMOTION,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await loadById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);

export const updatePromotionById = createAsyncThunk(
  ActionsTypes.UPDATE_PROMOTION,
  async (promotion: Promotion, { rejectWithValue }) => {
    try {
      const { id, ...fields } = promotion;
      const response = await updateById(id, { data: fields });
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);

export const deletePromotionById = createAsyncThunk(
  ActionsTypes.DELETE_PROMOTION,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await deleteById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);

export const createPromotion = createAsyncThunk(
  ActionsTypes.CREATE_PROMOTION,
  async (promotion: Promotion, { rejectWithValue }) => {
    try {
      const response = await create({ data: promotion });
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);

export const loadPromotionCarsForAdd = createAsyncThunk(
  ActionsTypes.LOAD_PROMO_FREE_CAR,
  async (filters: ICarsFilters, { rejectWithValue }) => {
    try {
      const response = await loadFreePromoCar(filters);
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);

export const DeleteFileById = createAsyncThunk(ActionsTypes.DELETE_FILE, async (id: string, { rejectWithValue }) => {
  try {
    const response = await deleteFile(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(validateError(err));
  }
});

export const rejectCar = createAsyncThunk(
  ActionsTypes.REJECT_PROMO_CAR,
  async (data: SignCarForPromo, { rejectWithValue }) => {
    try {
      const response = await reject(data.promoId, data.carId);
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);

export const holdCar = createAsyncThunk(ActionsTypes.HOLD_CAR, async (data: SignCarForPromo, { rejectWithValue }) => {
  try {
    const response = await hold(data.promoId, data.carId);
    return response.data;
  } catch (err) {
    return rejectWithValue(validateError(err));
  }
});

export const busyCar = createAsyncThunk(ActionsTypes.BUSY_CAR, async (data: SignCarForPromo, { rejectWithValue }) => {
  try {
    const response = await busy(data.promoId, data.carId);
    return response.data;
  } catch (err) {
    return rejectWithValue(validateError(err));
  }
});

export const loadPromotionCars = createAsyncThunk(
  ActionsTypes.LOAD_PROMO_CAR,
  async (data: ILoadPromotionCars, { rejectWithValue }) => {
    const { id, ...filters } = data;
    try {
      const response = await loadPromoCars(id, filters);
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);

export const uploadMaket = createAsyncThunk(
  ActionsTypes.UPLOAD_MAKET,
  async (data: IUploadPromoMaket, { rejectWithValue }) => {
    const { id, ...maket } = data;
    try {
      const response = await uploadMaketById(id, maket);
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);

export const startPromo = createAsyncThunk(ActionsTypes.START_PROMO, async (id: string, { rejectWithValue }) => {
  try {
    const response = await start(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(validateError(err));
  }
});

export const finishPromo = createAsyncThunk(ActionsTypes.FINISH_PROMO, async (id: string, { rejectWithValue }) => {
  try {
    const response = await finish(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(validateError(err));
  }
});

export const getMaps = createAsyncThunk(ActionsTypes.GET_MAPS, async (id: string, { rejectWithValue }) => {
  try {
    const response = await maps(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(validateError(err));
  }
});
