import { createAsyncThunk } from '@reduxjs/toolkit';
import { signin, restorePassword, signup, reset, removeToken } from 'store/reducers/auth/api';
import Snackbar from 'services/Snackbar';
import { AxiosError } from 'axios';
import { ActionsTypes, TCredential, TResetPassword } from 'store/reducers/auth/types';
import { serverErrorText } from 'constants/ServerCode';

const validateError = (err: AxiosError) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }
  const statusCode = error.response.data.statusCode;
  const errorText = error.response.data.error;
  const errorCode = error.response.status;
  // @ts-ignore
  const errorMessage: string = serverErrorText[statusCode || errorText || errorCode] || error.message;
  Snackbar.show(errorMessage, 'error');
  return errorMessage;
};

export const login = createAsyncThunk(ActionsTypes.LOGIN, async (credential: TCredential, { rejectWithValue }) => {
  try {
    const response = await signin(credential);
    return response.data;
  } catch (err) {
    return rejectWithValue(validateError(err));
  }
});

export const logout = createAsyncThunk(ActionsTypes.LOGOUT, async (any, { rejectWithValue }) => {
  try {
    const response = await removeToken();
    return response.data;
  } catch (err) {
    return rejectWithValue(validateError(err));
  }
});

export const registration = createAsyncThunk(
  ActionsTypes.SIGNUP,
  async (credential: TCredential, { rejectWithValue }) => {
    try {
      const response = await signup(credential);
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);

export const resetPassword = createAsyncThunk(
  ActionsTypes.RESET_PASSWORD,
  async (credential: TResetPassword, { rejectWithValue }) => {
    try {
      const response = await reset(credential);
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);

export const restore = createAsyncThunk(
  ActionsTypes.RESTORE,
  async (email: Partial<TCredential>, { rejectWithValue }) => {
    try {
      const response = await restorePassword(email);
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);
