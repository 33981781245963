import React, { FC } from 'react';
import classnames from 'classnames';
import selectCarsStyles from './styles';

const SelectCarListTitle: FC = () => {
  const classes = selectCarsStyles();
  return (
    <div className={classes.infoContainer} style={{ paddingLeft: 66, paddingTop: 0 }}>
      <div className={classnames(classes.item, classes.title)}>
        <div>Марка</div>
      </div>
      <div className={classnames(classes.item, classes.title)}>
        <div>Город</div>
      </div>
      <div className={classnames(classes.item, classes.title)}>
        <div>Тип кузова</div>
      </div>
      <div className={classnames(classes.item, classes.title)}>
        <div>Цвет</div>
      </div>
      <div className={classnames(classes.item, classes.title)}>
        <div>Год выпуска</div>
      </div>
      <div className={classnames(classes.item, classes.title)}>
        <div>Класс авто</div>
      </div>
      <div className={classnames(classes.item, classes.title)}>
        <div>Участвовал в ДТП</div>
      </div>
    </div>
  );
};

export default React.memo(SelectCarListTitle);
