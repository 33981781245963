import { axiosClient } from 'services/ApiClient';
import { AxiosResponse } from 'axios';
import { Driver, IDriversFilters } from 'interfaces/Driver';
import { Car, ICarsFilters } from 'interfaces/Car';

const driversUrl = '/drivers';

type CreateDriver = {
  data: Driver;
};

type UpdateDriver = {
  data: Partial<Driver>;
};

export const updateById = (id: string, fields: UpdateDriver): Promise<AxiosResponse> =>
  axiosClient.put(`${driversUrl}/${id}`, fields);

export const loadAll = (filters: IDriversFilters): Promise<AxiosResponse> =>
  axiosClient.get(`${driversUrl}`, { params: filters });

export const loadById = (id: string): Promise<AxiosResponse> => axiosClient.get(`${driversUrl}/${id}`);
export const deleteById = (id: string): Promise<AxiosResponse> => axiosClient.delete(`${driversUrl}/${id}`);
export const create = (fields: CreateDriver): Promise<AxiosResponse> => axiosClient.post(`${driversUrl}`, fields);

export const createCarById = (id: string, car: Car): Promise<AxiosResponse> =>
  axiosClient.post(`${driversUrl}/${id}/newcar`, car);

export const loadDriverCarsById = (driverId: string, filters: ICarsFilters): Promise<AxiosResponse> =>
  axiosClient.get(`${driversUrl}/${driverId}/cars`, { params: filters });

export const freeze = (id: string): Promise<AxiosResponse> => axiosClient.post(`${driversUrl}/${id}/freeze`);
