import React, { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import classnames from 'classnames';
import { Edit } from 'assets/images';
import CreateButton from 'components/shared/buttons/CreateButton';

type TProps = {
  title: string;
  onClickIcon?: () => void;
  onClick?: () => void;
  onClickCreate?: () => void;
  page?: number;
  handlePage?: (event: React.ChangeEvent<unknown>, value: number) => void;
  actionText?: string;
  count?: number | string;
  enableButton?: boolean;
};

const ColumnContainer: FC<TProps> = ({
  children,
  title,
  onClickIcon,
  onClick,
  onClickCreate,
  actionText,
  count,
  enableButton = true,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid item className={classnames(classes.padding, classes.title)}>
        <Typography variant="h2">
          {title}
          {!!count && <span className={classes.count}>({count})</span>}
        </Typography>
        {enableButton && (
          <>
            {onClickIcon && <Edit onClick={onClickIcon} style={{ cursor: 'pointer' }} />}
            {onClick && (
              <Typography variant="h3" onClick={onClick} style={{ cursor: 'pointer' }}>
                {actionText || 'Записать на рекламу'}
              </Typography>
            )}
            {onClickCreate && <CreateButton onClick={onClickCreate} />}
          </>
        )}
      </Grid>
      <Grid className={classes.padding}>{children}</Grid>
    </>
  );
};

export default ColumnContainer;

const useStyles = makeStyles((theme: Theme) => ({
  padding: {
    padding: 16,
    position: 'relative',
  },
  title: {
    borderBottom: '1px solid #F2F2F2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 55,
  },
  pagination: {
    float: 'right',
  },
  count: {
    paddingLeft: 5,
    color: theme.palette.primary.main,
  },
}));
