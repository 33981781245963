import Snackbar from 'services/Snackbar';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { serverErrorText } from 'constants/ServerCode';
import {
  createPromoEndpoint,
  deletePromoEndpoint,
  editPromoEndpoint,
  getPromoEndpoint,
  getPromoListEndpoint,
} from './api';
import { ActionsTypes, Promo } from './types';

const validateError = (error: any) => {
  if (!error?.response) throw error;
  const errorCode: string | number = error.response.status;
  const message = serverErrorText[errorCode];
  const errorMessage = message ?? error.message;
  Snackbar.show(errorMessage, 'error');
  return errorMessage;
};

export const getPromoList = createAsyncThunk(
  ActionsTypes.GET_PROMO_LIST,
  async ({ offset, limit }: { offset?: number; limit?: number }, { rejectWithValue }) => {
    try {
      const response = await getPromoListEndpoint(offset, limit);
      return response.data;
    } catch (error) {
      return rejectWithValue(validateError(error));
    }
  },
);

export const getPromo = createAsyncThunk(
  ActionsTypes.GET_PROMO,
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const response = await getPromoEndpoint(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(validateError(error));
    }
  },
);

export const createPromo = createAsyncThunk(
  ActionsTypes.CREATE_PROMO,
  async (data: Partial<Promo>, { rejectWithValue }) => {
    try {
      const response = await createPromoEndpoint(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(validateError(error));
    }
  },
);

export const editPromo = createAsyncThunk(
  ActionsTypes.EDIT_PROMO,
  async (data: Partial<Promo>, { rejectWithValue }) => {
    try {
      const response = await editPromoEndpoint(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(validateError(error));
    }
  },
);

export const deletePromo = createAsyncThunk(
  ActionsTypes.DELETE_PROMO,
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const response = await deletePromoEndpoint(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(validateError(error));
    }
  },
);
