import {
  IPromotionsFilters,
  MAX_NUMBER_OF_PROMOS_PER_REQUEST,
  Promotion,
  PromotionsCars,
  PromotionsMaps,
} from 'interfaces/Promotions';
import { IBaseState } from 'store/baseTypes';
import { initialSponsor } from '../sponsors/types';
import { initialCarsFilters } from 'interfaces/Car';

export const initialPromotionsFilters: IPromotionsFilters = {
  autoClass: null,
  sponsorName: null,
  status: null,
  city: null,
  period: null,
  start: null,
  finish: null,
  offset: undefined,
  limit: MAX_NUMBER_OF_PROMOS_PER_REQUEST,
  name: '',
};

export const initialPromotion: Promotion = {
  id: '',
  name: '',
  city: '',
  distribution: 1,
  period: 1,
  autoClass: '',
  budget: 1,
  eMail: '',
  description: '',
  phone: '',
  start: new Date().toISOString(),
  finish: new Date().toISOString(),
  sponsor: initialSponsor,
  files: [],
  cars: [],
  reportPeriod: 1,
};

export enum ActionsTypes {
  LOAD_ALL_PROMOTIONS = 'LOAD_ALL_PROMOTIONS',
  LOAD_PROMOTION = 'LOAD_PROMOTION',
  CREATE_PROMOTION = 'CREATE_PROMOTION',
  UPDATE_PROMOTION = 'UPDATE_PROMOTION',
  DELETE_PROMOTION = 'DELETE_PROMOTION',
  SIGN_PROMO_CAR = 'SIGN_PROMO_CAR',
  LOAD_PROMO_FREE_CAR = 'LOAD_PROMO_FREE_CAR',
  DELETE_FILE = 'DELETE_FILE',
  REJECT_PROMO_CAR = 'REJECT_PROMO_CAR',
  HOLD_CAR = 'HOLD_CAR',
  BUSY_CAR = 'BUSY_CAR',
  LOAD_PROMO_CAR = 'LOAD_PROMO_CAR',
  UPLOAD_MAKET = 'UPLOAD_MAKET',
  START_PROMO = 'START_PROMO',
  FINISH_PROMO = 'FINISH_PROMO',
  GET_MAPS = 'GET_MAPS',
}

export type PromotionsState = IBaseState<Promotion, IPromotionsFilters> & PromotionsCars & PromotionsMaps;

export const initialState: PromotionsState = {
  reviewCarsFilters: initialCarsFilters,
  listing: {
    entities: [],
    count: 0,
    filters: initialPromotionsFilters,
    loading: false,
    loaded: false,
    error: null,
  },
  item: {
    entity: initialPromotion,
    loading: false,
    loaded: false,
    error: null,
  },
  cars: {
    cars: [],
    filters: initialCarsFilters,
    count: 0,
    loading: false,
    loaded: false,
    error: null,
  },
  reviewCars: {
    cars: [],
    count: 0,
    loading: false,
    loaded: false,
    error: null,
  },
  maps: {
    maps: '',
    loading: false,
    loaded: false,
    error: null,
  },
};
