import { axiosClient } from 'services/ApiClient';
import { AxiosResponse } from 'axios';
import { TCredential, TResetPassword } from 'store/reducers/auth/types';

const usersUrl = '/users';

export const signin = (credential: TCredential): Promise<AxiosResponse> =>
  axiosClient.post(`${usersUrl}/signin/email`, credential);

export const removeToken = (): Promise<AxiosResponse> => axiosClient.post(`${usersUrl}/logout`);

export const restorePassword = (email: Partial<TCredential>): Promise<AxiosResponse> =>
  axiosClient.post(`${usersUrl}/password/restore`, email);

export const signup = (credential: TCredential): Promise<AxiosResponse> =>
  axiosClient.post(`${usersUrl}/signup/email`, credential);

export const reset = (credential: TResetPassword): Promise<AxiosResponse> =>
  axiosClient.post(`${usersUrl}/password/reset`, credential);
