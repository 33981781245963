import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from 'constants/Routes';
import { useDispatch, useSelector } from 'react-redux';
import BaseLayout from 'components/baseLayout';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ColumnContainer from 'components/layouts/columnContainer';
import ConfirmActionModal from 'components/shared/modal/ConfirmActionModal';
import Modal from 'components/shared/modal';
import { loadSponsorById, deleteSponsorById } from 'store/reducers/sponsors/actions';
import Loader from 'components/shared/loader';
import { getSponsor, getLoadingSponsor } from 'store/reducers/sponsors';
import AdvertiserHeader from 'components/containers/advertisers/item/elements/header';
import { AdCompanyEditForm, AdvertiserEditForm } from 'constants/Forms';
import AdvertiserView from 'components/containers/advertisers/item/elements/view';
import AdvertiserEdit from 'components/containers/advertisers/item/elements/edit';
import AdCompanyEdit from 'components/containers/adCompanies/item/elements/edit';
import AdCompaniesList from 'components/containers/advertisers/item/elements/promotions/AdCompaniesList';
import { initialPromotion } from 'store/reducers/promotions/types';
import { loadCarCitiesHelper, loadCarClassesHelper } from 'store/reducers/helpers/actions';
import { changeFilters } from 'store/reducers/sponsors';
import { initialSponsorFilters } from 'store/reducers/sponsors/types';

const titles = {
  info: 'Общие данные',
  promotions: 'Рекламные кампании',
};

const AdvertiserContainer: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const sponsor = useSelector(getSponsor);
  const isLoadingSponsor = useSelector(getLoadingSponsor);

  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openCreatePromotionModal, setOpenCreatePromotionModal] = React.useState(false);
  const [page, setPage] = useState(1);

  const handleCloseEditModal = () => setOpenEditModal(false);
  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);
  const handleOpenCreatePromotionModal = () => setOpenCreatePromotionModal(true);
  const handleCloseCreatePromotionModal = () => setOpenCreatePromotionModal(false);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const onClickBack = () => {
    history.push(Routes.advertisers);
  };

  const onClickDelete = useCallback(async () => {
    await dispatch(deleteSponsorById(id));
    dispatch(changeFilters(initialSponsorFilters));
    history.push(Routes.advertisers);
  }, [history, id, dispatch]);

  const onEditClick = useCallback(
    (id: string) => {
      history.push(`${Routes.adCompanies}/${id}`);
    },
    [history],
  );

  useEffect(() => {
    dispatch(loadSponsorById(id));
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(loadCarCitiesHelper());
    dispatch(loadCarClassesHelper());
  }, [dispatch]);

  const onCreateAdCompany = useCallback(async () => {
    handleCloseCreatePromotionModal();
    dispatch(loadSponsorById(id));
  }, [id, dispatch]);

  return (
    <BaseLayout>
      <Grid>
        <Grid item container xs={12} className={classes.padding}>
          <AdvertiserHeader title={sponsor.name} onClickBack={onClickBack} onClickDelete={handleOpenDeleteModal} />
        </Grid>
        <Grid item container xs={12}>
          <Grid item lg={4} xs={12} className={classes.borderTop}>
            <ColumnContainer title={titles.info} onClickIcon={handleOpenEditModal}>
              {isLoadingSponsor ? <Loader show /> : <AdvertiserView advertiser={sponsor} />}
            </ColumnContainer>
          </Grid>
          <Grid item lg={8} xs={12} className={classes.border}>
            <ColumnContainer
              title={titles.promotions}
              count={sponsor?.__promotions__.length.toString()}
              page={page}
              onClickCreate={handleOpenCreatePromotionModal}
              handlePage={handleChange}
            >
              {isLoadingSponsor ? (
                <Loader show />
              ) : (
                <AdCompaniesList onEdit={onEditClick} items={sponsor?.__promotions__} />
              )}
            </ColumnContainer>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        actionTitle="Сохранить"
        formId={AdvertiserEditForm}
        modalTitle="Рекламодатель"
      >
        {isLoadingSponsor ? <Loader show /> : <AdvertiserEdit onSave={handleCloseEditModal} advertiser={sponsor} />}
      </Modal>
      <ConfirmActionModal
        openModalStatus={openDeleteModal}
        buttonClose={handleCloseDeleteModal}
        buttonDelete={onClickDelete}
        actionText="Удалить"
        text="рекламодателя"
      />
      <Modal
        open={openCreatePromotionModal}
        onClose={handleCloseCreatePromotionModal}
        actionTitle="Сохранить"
        formId={AdCompanyEditForm}
        modalTitle="Рекламная кампания"
      >
        <AdCompanyEdit isCreate onSave={onCreateAdCompany} sponsorId={sponsor.id} adCompany={initialPromotion} />
      </Modal>
    </BaseLayout>
  );
};

export default AdvertiserContainer;

const useStyles = makeStyles((theme: Theme) => ({
  padding: {
    padding: 16,
  },
  borderTop: {
    borderTop: '1px solid #F2F2F2',
  },
  border: {
    borderLeft: '1px solid #F2F2F2',
    borderRight: '1px solid #F2F2F2',
    borderTop: '1px solid #F2F2F2',
    [theme.breakpoints.down('md')]: {
      borderLeft: 'none',
      borderRight: 'none',
    },
  },
}));
