export type Route =
  | 'auth'
  | 'signup'
  | 'restore'
  | 'reset'
  | 'cars'
  | 'car'
  | 'carsNew'
  | 'drivers'
  | 'driver'
  | 'driversNew'
  | 'adCompany'
  | 'adCompanies'
  | 'advertisers'
  | 'advertiser'
  | 'advertisersNew'
  | 'profile'
  | 'layoutDrivers'
  | 'layoutAdCompanies'
  | 'termsAndConditions'
  | 'consentToDataProcessing'
  | 'privacyPolicy'
  | 'promos'
  | 'promo';

export const Routes: Record<Route, string> = {
  auth: '/auth',
  signup: '/signup',
  restore: '/restore',
  reset: '/reset',
  cars: '/cars',
  car: '/cars/:id',
  carsNew: '/cars/new',
  drivers: '/drivers',
  driver: '/drivers/:id',
  driversNew: '/drivers/new',
  adCompanies: '/ads',
  adCompany: '/ads/:id',
  advertisers: '/advertisers',
  advertiser: '/advertisers/:id',
  advertisersNew: '/advertisers/new',
  profile: '/profile',
  layoutDrivers: '/landing/drivers',
  layoutAdCompanies: '/landing/ads',
  termsAndConditions: '/terms-and-conditions',
  consentToDataProcessing: '/consent-to-data-processing',
  privacyPolicy: '/terms-and-conditions#privacy-policy',
  promos: '/promo',
  promo: '/promo/:id',
};

export const RouteNames: { [key: string]: string } = {
  [Routes.auth]: 'Вход',
  [Routes.signup]: 'Регистрация',
  [Routes.restore]: 'Восстановление пароля',
  [Routes.reset]: 'Подтверждение пароля',
  [Routes.cars]: 'Транспортные средства',
  [Routes.carsNew]: 'Новое транспортное средство',
  [Routes.adCompanies]: 'Рекламные кампании',
  [Routes.adCompany]: 'Рекламная кампания',
  [Routes.advertisers]: 'Рекламодатели',
  [Routes.carsNew]: 'Новый рекламодатель',
  [Routes.profile]: 'Личный кабинет',
  [Routes.layoutDrivers]: 'Водителям',
  [Routes.layoutAdCompanies]: 'Рекламодателям',
  [Routes.termsAndConditions]: 'Пользовательское соглашение',
  [Routes.consentToDataProcessing]: 'Согласие на обработку персональных данных',
  [Routes.privacyPolicy]: 'Политика конфиденциальности',
};

export const AdminRoutes = ['drivers', 'advertisers'];
