import { axiosClient } from 'services/ApiClient';
import { AxiosResponse } from 'axios';

const carCitiesUrl = '/helpers/city';
const carMarksUrl = '/helpers/car/mark';
const carModelsUrl = '/helpers/car/model';
const carColorsUrl = '/helpers/car/color';
const carBodiesUrl = '/helpers/car/body';
const carClassesUrl = '/helpers/car/class';
const landingClassesUrl = '/landing/city';
const taxStatusesUrl = '/helpers/taxstatus';
const modelsByMarkUrl = '/helpers/car/model/marks';

export const loadAllCarCitiesHelper = (): Promise<AxiosResponse> => axiosClient.get(`${carCitiesUrl}`);
export const loadAllCarMarksHelper = (): Promise<AxiosResponse> => axiosClient.get(`${carMarksUrl}`);
export const loadAllCarModelsHelper = (): Promise<AxiosResponse> => axiosClient.get(`${carModelsUrl}`);
export const loadAllCarColorsHelper = (): Promise<AxiosResponse> => axiosClient.get(`${carColorsUrl}`);
export const loadAllCarBodiesHelper = (): Promise<AxiosResponse> => axiosClient.get(`${carBodiesUrl}`);
export const loadAllCarClassesHelper = (): Promise<AxiosResponse> => axiosClient.get(`${carClassesUrl}`);
export const loadAllLandingCitiesHelper = (): Promise<AxiosResponse> => axiosClient.get(`${landingClassesUrl}`);
export const loadAllTaxStatusesHelper = (): Promise<AxiosResponse> => axiosClient.get(`${taxStatusesUrl}`);
export const loadAllModelsByMarkHelper = (markId: number): Promise<AxiosResponse> =>
  axiosClient.get(`${modelsByMarkUrl}/${markId}`);

export const findCarMarksEndpoint = (query: string): Promise<AxiosResponse> => {
  return axiosClient.post('/helpers/car/mark/dadata', { query });
};
