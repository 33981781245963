import React, { useCallback, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Controller, useForm, useWatch } from 'react-hook-form';
import TextFieldInput from 'components/shared/inputs/TextFieldInput';
import FileInput from 'components/shared/inputs/FileInput';
import ControlledRangeDatePicker from 'components/shared/pickers/ControlledRangeDatePicker';
import { useAppDispatch } from 'store';
import { updatePromotionById, DeleteFileById, uploadMaket } from 'store/reducers/promotions/actions';
import { createSponsorPromotion } from 'store/reducers/sponsors/actions';
import { AdCompanyEditForm } from 'constants/Forms';
import { Promotion } from 'interfaces/Promotions';
import { yupResolver } from '@hookform/resolvers/yup';
import { ValidationSchema } from 'components/containers/adCompanies/item/elements/edit/ValidationSchema';
import { UploadImage } from 'store/reducers/promotions';
import { axiosClient } from 'services/ApiClient';
import { IPhoto } from 'interfaces/Media';
import CustomAutocompleteSelect from 'components/shared/select/CustomAutocompleteSelect';
import { useSelector } from 'react-redux';
import { getCities, getClasses } from 'store/reducers/helpers';
import { differenceInDays, startOfDay } from 'date-fns';
import { createNewPromo } from 'store/reducers/users/actions';

type TAdCompanyEditProps = {
  adCompany: Promotion;
  onSave: () => void;
  sponsorId?: string;
  isCreate?: boolean;
  isSponsorCreate?: boolean;
};

const AdCompanyEdit: React.FC<TAdCompanyEditProps> = ({ adCompany, isCreate, onSave, sponsorId, isSponsorCreate }) => {
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState(adCompany.files);
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const { handleSubmit, control, errors } = useForm<Promotion>({
    defaultValues: adCompany,
    resolver: yupResolver(ValidationSchema),
  });

  const startDate = useWatch({
    control,
    name: 'start',
    defaultValue: adCompany.start || new Date(),
  });

  const finishDate = useWatch({
    control,
    name: 'finish',
    defaultValue: adCompany.finish || new Date(),
  });
  const adPeriod = differenceInDays(startOfDay(new Date(finishDate)), startOfDay(new Date(startDate))) + 1;

  const helperCities = useSelector(getCities);
  const getCityValue = (city: string) => helperCities.entities.find((item) => item.displayName === city);

  const helperAutoClasses = useSelector(getClasses);
  const getAutoClassValue = (autoClass: string) =>
    helperAutoClasses.entities.find((item) => item.displayName === autoClass);

  const onSubmit = useCallback(
    async (data) => {
      data.city = data.city.displayName || adCompany.city;
      data.autoClass = data.autoClass.displayName || adCompany.autoClass;
      if (isCreate) {
        const newAdCompany = await dispatch(
          createSponsorPromotion({
            sponsorId: sponsorId || '',
            promotion: {
              ...data,
              start: new Date(data.start).toISOString(),
              finish: new Date(data.finish).toISOString(),
              period: adPeriod,
            },
          }),
        );
        files.map((item: IPhoto) => {
          dispatch(uploadMaket({ id: newAdCompany.payload.id, maketId: item.id, comment: item.comment }));
          dispatch(UploadImage(item));
        });
        onSave();
      } else if (isSponsorCreate) {
        const newAdCompany = await dispatch(
          createNewPromo({
            ...data,
            start: new Date(data.start).toISOString(),
            finish: new Date(data.finish).toISOString(),
            period: adPeriod,
          }),
        );
        files.map((item: IPhoto) => {
          dispatch(uploadMaket({ id: newAdCompany.payload.data.id, maketId: item.id, comment: item.comment }));
          dispatch(UploadImage(item));
        });
        onSave();
      } else {
        await dispatch(
          updatePromotionById({
            ...data,
            id: adCompany.id,
            start: new Date(data.start).toISOString(),
            finish: new Date(data.finish).toISOString(),
            period: adPeriod,
          }),
        );
        files.map((item: IPhoto) => {
          dispatch(uploadMaket({ id: adCompany.id, maketId: item.id, comment: item.comment }));
          dispatch(UploadImage(item));
        });
        onSave();
      }
    },
    [
      adPeriod,
      adCompany.autoClass,
      adCompany.city,
      isSponsorCreate,
      files,
      sponsorId,
      adCompany.id,
      onSave,
      isCreate,
      dispatch,
    ],
  );

  const onDeleteImage = (id: string) => {
    dispatch(DeleteFileById(id));
    setFiles((prevState) => prevState.filter((item) => item.id !== id));
  };

  const photoInputChangeHandler = async (e: React.SyntheticEvent<EventTarget>) => {
    const formData = new FormData();
    const formInput = (e.target as HTMLFormElement).files[0];
    setIsUploadingImage(true);
    formData.append('file', formInput);
    formData.append('comment', 'avatar');
    const response = await axiosClient.post(`/makets`, formData);
    setFiles((prevState) => [...prevState, { ...response.data }]);
    setIsUploadingImage(false);
  };

  return (
    <form id={AdCompanyEditForm} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            as={TextFieldInput}
            field={{ title: 'Кампания:' }}
            name="name"
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="city"
            control={control}
            render={(props) => (
              <CustomAutocompleteSelect
                name="city"
                value={typeof props.value === 'object' ? props.value : getCityValue(props.value)}
                options={helperCities.entities}
                title="Город:"
                errors={errors}
                onChange={props.onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="autoClass"
            control={control}
            render={(props) => (
              <CustomAutocompleteSelect
                name="autoClass"
                value={typeof props.value === 'object' ? props.value : getAutoClassValue(props.value)}
                options={helperAutoClasses.entities}
                title="Класс авто:"
                errors={errors}
                onChange={props.onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={TextFieldInput}
            field={{ title: 'Тираж:', type: 'number' }}
            name="distribution"
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledRangeDatePicker
            label="Сроки проведения:"
            startTimeName="start"
            endTimeName="finish"
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={TextFieldInput}
            field={{ title: 'Бюджет:', type: 'number' }}
            name="budget"
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller as={TextFieldInput} field={{ title: 'E-mail:' }} name="eMail" errors={errors} control={control} />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={TextFieldInput}
            field={{ title: 'Описание:', multiline: true }}
            name="description"
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="period"
            control={control}
            render={(props) => (
              <TextFieldInput
                {...props}
                name="period"
                value={adPeriod}
                field={{ title: 'Срок рекламной кампании:', type: 'number', disabled: true }}
                errors={errors}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={TextFieldInput}
            field={{ title: 'Периодичность фотоотчета:', type: 'number', disabled: !(isCreate || isSponsorCreate) }}
            name="reportPeriod"
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <FileInput
            files={files}
            onDeleteImage={onDeleteImage}
            loading={isUploadingImage}
            onChange={photoInputChangeHandler}
            title="Макет"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default AdCompanyEdit;
