import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import BaseLayout from 'components/baseLayout';
import AdCompaniesList from 'components/containers/adCompanies/listing/elements/list/AdCompaniesList';
import { useHistory } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import AdCompaniesHeader from 'components/containers/adCompanies/listing/elements/header';
import AdCompaniesFilters from 'components/containers/adCompanies/listing/elements/filters';
import classnames from 'classnames';
import { loadPromotions } from 'store/reducers/promotions/actions';
import { useSelector } from 'react-redux';
import {
  changeFilters,
  getCountPromotions,
  getPromotions,
  getPromotionsFilters,
  getPromotionsLoading,
} from 'store/reducers/promotions';
import { useAppDispatch } from 'store';
import Loader from 'components/shared/loader';
import { MAX_NUMBER_OF_PROMOS_PER_REQUEST } from 'interfaces/Promotions';
import { getFilterOffset, getPageByOffset } from 'utils/common';
import Modal from 'components/shared/modal';
import { AdCompanyEditForm } from 'constants/Forms';
import AdCompanyEdit from 'components/containers/adCompanies/item/elements/edit';
import { initialPromotion } from 'store/reducers/promotions/types';
import { getUserMe } from 'store/reducers/users';

const AdCompaniesContainer: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const promotions = useSelector(getPromotions);
  const promotionsLoading = useSelector(getPromotionsLoading);
  const filters = useSelector(getPromotionsFilters);
  const promosCount = useSelector(getCountPromotions);
  const userMe = useSelector(getUserMe);
  const pageCount = Math.ceil(promosCount / MAX_NUMBER_OF_PROMOS_PER_REQUEST);
  const [page, setPage] = useState<number>(getPageByOffset(filters.offset));

  const handleChangePage = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
      const offset = getFilterOffset(value, MAX_NUMBER_OF_PROMOS_PER_REQUEST);
      dispatch(changeFilters({ ...filters, offset }));
    },
    [dispatch, filters],
  );

  const [openCreatePromotionModal, setOpenCreatePromotionModal] = React.useState(false);
  const handleOpenCreatePromotionModal = () => setOpenCreatePromotionModal(true);
  const handleCloseCreatePromotionModal = () => setOpenCreatePromotionModal(false);

  useEffect(() => {
    dispatch(loadPromotions(filters));
  }, [dispatch, filters]);

  const onEditClick = useCallback(
    (id: string) => {
      history.push(`ads/${id}`);
    },
    [history],
  );

  const onChangeSearch = useCallback(
    (searchValue) => {
      dispatch(changeFilters({ ...filters, name: searchValue, offset: undefined }));
    },
    [filters, dispatch],
  );

  const onChangeFilters = useCallback(
    (newFilters) => {
      dispatch(changeFilters(newFilters));
    },
    [dispatch],
  );

  const onPromoSave = useCallback(() => {
    dispatch(loadPromotions(filters));
    handleCloseCreatePromotionModal();
  }, [filters, dispatch]);

  return (
    <BaseLayout>
      <Grid>
        <Grid item container xs={12} className={classes.padding}>
          <AdCompaniesHeader
            isSponsor={!userMe.admin}
            onClickCreate={handleOpenCreatePromotionModal}
            onChangeSearch={onChangeSearch}
          />
        </Grid>
        <Grid item container xs={12} className={classes.borderTop}>
          <Grid item lg={9} xs={12} className={classnames(classes.padding, classes.borderRight)}>
            {promotionsLoading ? <Loader show /> : <AdCompaniesList items={promotions} onEdit={onEditClick} />}
            {pageCount > 1 && (
              <Pagination
                className={classes.pagination}
                count={pageCount}
                shape="rounded"
                page={page}
                onChange={handleChangePage}
              />
            )}
          </Grid>
          <Grid item lg={3} xs={12}>
            <Grid item className={classnames(classes.padding, classes.title)}>
              <Typography variant="h2">Фильтр</Typography>
            </Grid>
            <Grid className={classes.padding}>
              <AdCompaniesFilters filters={filters} onChangeFilters={onChangeFilters} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={openCreatePromotionModal}
        onClose={handleCloseCreatePromotionModal}
        actionTitle="Сохранить"
        formId={AdCompanyEditForm}
        modalTitle="Рекламная кампания"
      >
        <AdCompanyEdit isSponsorCreate onSave={onPromoSave} adCompany={initialPromotion} />
      </Modal>
    </BaseLayout>
  );
};

export default AdCompaniesContainer;

const useStyles = makeStyles((_theme: Theme) => ({
  pagination: {
    float: 'right',
  },
  padding: {
    padding: 16,
  },
  borderTop: {
    borderTop: '1px solid #F2F2F2',
    flexWrap: 'wrap-reverse',
  },
  borderRight: {
    borderRight: '1px solid #F2F2F2',
  },
  title: {
    borderBottom: '1px solid #F2F2F2',
  },
}));
