import React, { FC } from 'react';
import { IconButton, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';

type TProps = {
  openModalStatus: boolean;
  buttonClick: () => void;
};

export const DoneGeneratePassword: FC<TProps> = (TProps) => {
  const classes = useStyles();

  return (
    <Modal open={TProps.openModalStatus} onClose={TProps.buttonClick}>
      <div className={classes.paper}>
        <div className={classes.iconButtonDiv}>
          <IconButton color="inherit" onClick={TProps.buttonClick}>
            <ClearIcon />
          </IconButton>
        </div>
        <div>
          <p className={classes.textInfo}>Готово! Данные успешно</p>
          <p className={classes.textInfo}>изменены</p>
        </div>
      </div>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    height: '140px',
    width: '297px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    boxSizing: 'border-box',
    border: '1px solid #FFFFFF',
    outline: '0',
  },
  textInfo: {
    color: 'black',
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '22px',
    textAlign: 'center',
    alignItem: 'center',
    margin: 4,
  },
  iconButtonDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
  },
}));
