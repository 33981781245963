import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import BaseLayout from 'components/baseLayout';
import { useHistory } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'components/shared/modal';
import { AdvertiserEditForm } from 'constants/Forms';
import AdvertisersHeader from 'components/containers/advertisers/listing/elements/header';
import Loader from 'components/shared/loader';
import AdvertiserList from 'components/containers/advertisers/listing/elements/list/AdvertiserList';
import { loadSponsors } from 'store/reducers/sponsors/actions';
import {
  getSponsors,
  getSponsorsFilters,
  getSponsorsLoading,
  changeFilters,
  getCountSponsors,
} from 'store/reducers/sponsors/index';
import { initialSponsor } from 'store/reducers/sponsors/types';
import AdvertiserEdit from 'components/containers/advertisers/item/elements/edit';
import { MAX_NUMBER_OF_SPONSORS_PER_REQUEST } from 'interfaces/Sponsors';
import { getFilterOffset, getPageByOffset } from 'utils/common';
import { getUserMe } from '../../../../store/reducers/users';

const AdvertisersContainer: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const sponsors = useSelector(getSponsors);
  const isLoadingSponsors = useSelector(getSponsorsLoading);
  const filters = useSelector(getSponsorsFilters);
  const sponsorsCount = useSelector(getCountSponsors);
  const pageCount = Math.ceil(sponsorsCount / MAX_NUMBER_OF_SPONSORS_PER_REQUEST);
  const [page, setPage] = useState<number>(getPageByOffset(filters.offset));
  const { admin: isAdmin } = useSelector(getUserMe);

  const handleChangePage = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
      const offset = getFilterOffset(value, MAX_NUMBER_OF_SPONSORS_PER_REQUEST);
      dispatch(changeFilters({ ...filters, offset }));
    },
    [dispatch, filters],
  );

  const [openEditModal, setOpenEditModal] = React.useState(false);

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
  };

  const onEditClick = useCallback(
    (id: string) => {
      history.push(`advertisers/${id}`);
    },
    [history],
  );

  useEffect(() => {
    if (isAdmin) dispatch(loadSponsors(filters));
  }, [filters, dispatch, isAdmin]);

  return (
    <BaseLayout>
      <Grid>
        <Grid item container xs={12} className={classes.padding}>
          <AdvertisersHeader onClickCreate={handleOpenEditModal} />
        </Grid>
        <Grid item container xs={12} className={classes.borderTop}>
          <Grid item xs={12} className={classnames(classes.padding, classes.borderRight)}>
            {isLoadingSponsors ? <Loader show /> : <AdvertiserList items={sponsors} onEdit={onEditClick} />}
            {pageCount > 1 && (
              <Pagination
                className={classes.pagination}
                count={pageCount}
                shape="rounded"
                page={page}
                onChange={handleChangePage}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        actionTitle="Сохранить"
        formId={AdvertiserEditForm}
        modalTitle="Рекламодатель"
      >
        <AdvertiserEdit isCreate onSave={handleCloseEditModal} advertiser={initialSponsor} />
      </Modal>
    </BaseLayout>
  );
};

export default AdvertisersContainer;

const useStyles = makeStyles((_theme: Theme) => ({
  pagination: {
    float: 'right',
  },
  padding: {
    padding: 16,
  },
  borderTop: {
    borderTop: '1px solid #F2F2F2',
    flexWrap: 'wrap-reverse',
  },
  borderRight: {
    borderRight: '1px solid #F2F2F2',
  },
  title: {
    borderBottom: '1px solid #F2F2F2',
  },
}));
