import { Driver, DriverCars, IDriversFilters, MAX_NUMBER_OF_DRIVER_PER_REQUEST } from 'interfaces/Driver';
import { IBaseState } from 'store/baseTypes';
import { Car, initialCarsFilters } from 'interfaces/Car';

export type TCreateDriverCar = {
  driverId: string;
  car: Car;
};

export enum TaxStatuses {
  naturalPerson = 'Физ. лицо',
  legalEntity = 'Юр. лицо',
}

export const initialDriversFilters: IDriversFilters = {
  city: null,
  status: null,
  offset: undefined,
  limit: MAX_NUMBER_OF_DRIVER_PER_REQUEST,
  name: '',
};

export const initialDriver: Driver = {
  id: '',
  name: '',
  firstName: '',
  lastName: '',
  fatherName: '',
  eMail: '',
  city: '',
  phone: '',
  taxStatus: 'Физ. лицо',
  beneficiary: '',
  inn: '',
  kpp: '',
  bik: '',
  account: '',
  license: '',
  licenseYear: '',
  birthday: '',
  __cars__: [],
};

export enum ActionsTypes {
  LOAD_ALL_DRIVERS = 'LOAD_ALL_DRIVERS',
  LOAD_DRIVER = 'LOAD_DRIVER',
  CREATE_DRIVER = 'CREATE_DRIVER',
  UPDATE_DRIVER = 'UPDATE_DRIVER',
  DELETE_DRIVER = 'DELETE_DRIVER',
  CREATE_DRIVER_CAR = 'CREATE_DRIVER_CAR',
  LOAD_DRIVER_CARS = 'LOAD_DRIVER_CARS',
  FREEZE_DRIVER = 'FREEZE_DRIVER',
}

export type DriversState = IBaseState<Driver, IDriversFilters> & DriverCars;

export const initialState: DriversState = {
  listing: {
    entities: [],
    count: 0,
    filters: initialDriversFilters,
    loading: false,
    loaded: false,
    error: null,
  },
  item: {
    entity: initialDriver,
    loading: false,
    loaded: false,
    error: null,
  },
  cars: {
    cars: [],
    filters: initialCarsFilters,
    count: 0,
    loading: false,
    loaded: false,
    error: null,
  },
};
