import React, { ReactElement } from 'react';
import classnames from 'classnames';
import carsStyles from './styles';

const CarListTitle = (): ReactElement | null => {
  const classes = carsStyles();
  return (
    <div className={classes.infoContainer} style={{ paddingTop: 0 }}>
      <div className={classnames(classes.item, classes.title)}>
        <div>Марка</div>
      </div>
      <div className={classnames(classes.item, classes.title)}>
        <div>Город</div>
      </div>
      <div className={classnames(classes.item, classes.title)}>
        <div>Год выпуска</div>
      </div>
      <div className={classnames(classes.item, classes.title)}>
        <div>Класс авто</div>
      </div>
      <div className={classnames(classes.item, classes.title)}>
        <div>Водитель</div>
      </div>
      <div className={classnames(classes.item, classes.title)}>
        <div>Статус</div>
      </div>
    </div>
  );
};

export default React.memo(CarListTitle);
