import React, { useEffect } from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';
import { AdminRoutes, Routes } from 'constants/Routes';
import { authService } from 'services/AuthService';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from 'store/reducers/auth';
import { getUserMe } from 'store/reducers/users';
import { loadUserByMe } from '../../../store/reducers/users/actions';

const PrivateRoute: React.FC<RouteProps> = ({ children, ...props }) => {
  const dispatch = useDispatch();

  const { token } = authService;
  const user = useSelector(getToken);

  const userMe = useSelector(getUserMe);
  const location = useLocation();

  const isAuthenticated = token && user;

  const currentUrl = location.pathname.split('/')[1];

  const isAdminUrl = AdminRoutes.includes(currentUrl);

  useEffect(() => {
    dispatch(loadUserByMe());
  }, [dispatch]);

  return (
    <Route {...props}>
      {({ location }): React.ReactElement => (
        <>
          {isAuthenticated && children}

          {isAdminUrl && !userMe?.admin && (
            <Redirect
              to={{
                pathname: Routes.adCompanies,
                state: { from: location },
              }}
            />
          )}

          {!isAuthenticated && (
            <Redirect
              to={{
                pathname: Routes.auth,
                state: { from: location },
              }}
            />
          )}
        </>
      )}
    </Route>
  );
};

export default PrivateRoute;
