import React from 'react';
import { Grid } from '@material-ui/core';
import FieldView from 'components/shared/inputs/FieldView';
import Divider from '@material-ui/core/Divider';
import { Driver, DriverStatus } from 'interfaces/Driver';
import Status from 'components/shared/status';
import { TaxStatuses } from '../../../../../../store/reducers/drivers/types';

type TDriverViewProps = {
  driver: Driver;
};

const DriverView: React.FC<TDriverViewProps> = ({ driver }) => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <FieldView title="ФИО:" value={driver?.name} />
        {/*        <FieldView title="Фамилия:" value={driver.lastName} />
        <FieldView title="Имя:" value={driver.firstName} />
        <FieldView title="Отчество:" value={driver.fatherName} />*/}
        <FieldView title="Телефон:" value={driver?.phone} />
        <FieldView title="E-mail:" value={driver?.eMail} />
        <Divider component="div" style={{ marginBottom: 16 }} />
        <FieldView title="Налоговый статус:" value={driver?.taxStatus} />
        <FieldView title="Получатель:" value={driver?.beneficiary} />
        <FieldView title="ИНН:" value={driver?.inn} />
        {driver.taxStatus === TaxStatuses.legalEntity && <FieldView title="КПП:" value={driver?.kpp} />}
        <FieldView title="БИК:" value={driver?.bik} />
        <FieldView title="Р/С:" value={driver?.account} />
      </Grid>
      {driver.status === DriverStatus.frozen && (
        <Grid item container xs={6} justify="flex-end">
          <Status status={driver.status} />
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(DriverView);
