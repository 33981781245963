import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import CustomAutocompleteSelect from 'components/shared/select/CustomAutocompleteSelect';
import { FilterIcon } from 'assets/images';
import { Grid } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import ClearButton from 'components/shared/buttons/ClearButton';
import AcceptButton from 'components/shared/buttons/AcceptButton';
import Skeleton from '@material-ui/lab/Skeleton';
import { ICarsFilters, initialCarsFilters } from 'interfaces/Car';
import { crashOptions, options } from 'utils/options';
import { isEqual } from 'lodash';
import {
  loadCarBodiesHelper,
  loadCarCitiesHelper,
  loadCarClassesHelper,
  loadCarColorsHelper,
  loadCarMarksHelper,
} from 'store/reducers/helpers/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getBodies, getCities, getClasses, getColors, getMarks } from '../../../../../../../store/reducers/helpers';

type TFiltersProps = {
  filters: ICarsFilters;
  onChangeFilters: (value: ICarsFilters) => void;
  isPromos?: boolean;
};

const STATUSES_OPTIONS = [
  {
    displayName: 'на рассмотрении',
    value: 0,
  },
  {
    displayName: 'в работе',
    value: 1,
  },
  {
    displayName: 'в ожидании',
    value: 2,
  },
  {
    displayName: 'отклонён',
    value: 3,
  },
  {
    displayName: 'на паузе',
    value: 4,
  },
];

const STATUSES_OPTIONS_PROMOS = [
  {
    displayName: 'в работе',
    value: 1,
  },
  {
    displayName: 'в ожидании',
    value: 2,
  },
  {
    displayName: 'на паузе',
    value: 4,
  },
];

const Filters: React.FC<TFiltersProps> = ({ filters, onChangeFilters, isPromos }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { handleSubmit, control, errors, reset } = useForm<ICarsFilters>({
    defaultValues: initialCarsFilters,
  });

  const [expanded, setExpanded] = React.useState(false);

  useEffect(() => {
    onChangeFilters(initialCarsFilters);
  }, [onChangeFilters]);

  useEffect(() => {
    dispatch(loadCarCitiesHelper());
    dispatch(loadCarClassesHelper());
    dispatch(loadCarMarksHelper());
    dispatch(loadCarBodiesHelper());
    dispatch(loadCarColorsHelper());
  }, [dispatch]);

  const helperClasses = useSelector(getClasses);
  const helperMarks = useSelector(getMarks);
  const helperBodies = useSelector(getBodies);
  const helperColors = useSelector(getColors);
  const helperCities = useSelector(getCities);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleChangeFilters = useCallback(
    (data) => {
      if (data) {
        onChangeFilters({
          status: data.status?.displayName,
          autoClass: data.autoClass?.displayName,
          mark: data.mark?.displayName,
          bodyType: data.bodyType?.displayName,
          color: data.color?.displayName,
          year: data.year?.value,
          city: data.city?.displayName,
          crash: data.crash?.value,
        });
      }
    },
    [onChangeFilters],
  );

  const handleResetFilters = useCallback(() => {
    onChangeFilters(initialCarsFilters);
    reset({ ...initialCarsFilters, status: '', autoClass: '', bodyType: '', city: '', color: '', mark: '', model: '' });
  }, [reset, onChangeFilters]);

  return (
    <div className={classes.container}>
      <div className={classes.buttonContainer} onClick={handleExpandClick}>
        <FilterIcon />
        {!isEqual(initialCarsFilters, filters) && (
          <Skeleton className={classes.icon} animation="pulse" variant="text" width={88} height={44} />
        )}
        <span>Фильтр</span>
      </div>
      <form>
        <Collapse in={expanded} timeout="auto">
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4}>
              <Controller
                name="status"
                control={control}
                render={(props) => (
                  <CustomAutocompleteSelect
                    name="status"
                    value={props.value}
                    options={isPromos ? STATUSES_OPTIONS_PROMOS : STATUSES_OPTIONS}
                    title="Статус:"
                    onChange={props.onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <Controller
                name="autoClass"
                control={control}
                render={(props) => (
                  <CustomAutocompleteSelect
                    name="autoClass"
                    value={props.value}
                    options={helperClasses.entities}
                    title="Класс авто:"
                    onChange={props.onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <Controller
                name="mark"
                control={control}
                render={(props) => (
                  <CustomAutocompleteSelect
                    name="mark"
                    value={props.value}
                    options={helperMarks.entities}
                    title="Марка:"
                    onChange={props.onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <Controller
                name="bodyType"
                control={control}
                render={(props) => (
                  <CustomAutocompleteSelect
                    name="bodyType"
                    value={props.value}
                    options={helperBodies.entities}
                    title="Тип кузова:"
                    onChange={props.onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <Controller
                name="color"
                control={control}
                render={(props) => (
                  <CustomAutocompleteSelect
                    name="color"
                    value={props.value}
                    options={helperColors.entities}
                    title="Цвет:"
                    onChange={props.onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <Controller
                name="year"
                control={control}
                render={(props) => (
                  <CustomAutocompleteSelect
                    value={props.value}
                    options={options()}
                    title="Год выпуска"
                    onChange={props.onChange}
                    errors={errors}
                    name="year"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <Controller
                name="city"
                control={control}
                render={(props) => (
                  <CustomAutocompleteSelect
                    name="city"
                    value={props.value}
                    options={helperCities.entities}
                    title="Город:"
                    errors={errors}
                    onChange={props.onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <Controller
                name="crash"
                control={control}
                render={(props) => (
                  <CustomAutocompleteSelect
                    value={props.value}
                    options={crashOptions}
                    title="Участвовал в ДТП"
                    onChange={props.onChange}
                    errors={errors}
                    name="crash"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'end' }}>
              <ClearButton onClick={handleResetFilters} />
              <AcceptButton onClick={handleSubmit(handleChangeFilters)} />
            </Grid>
          </Grid>
        </Collapse>
      </form>
    </div>
  );
};

export default React.memo(Filters);

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
    paddingTop: 0,
    fontSize: 16,
    lineHeight: '19px',
    color: theme.palette.text.primary,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: 550,
    cursor: 'pointer',
    alignItems: 'center',
  },
  icon: {
    backgroundColor: 'rgba(45, 156, 219, 0.1)',
    transformOrigin: 'center',
    position: 'absolute',
  },
}));
