import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteById,
  loadAll,
  loadById,
  updateById,
  create,
  createCarById,
  loadDriverCarsById,
  freeze,
} from 'store/reducers/drivers/api';
import Snackbar from 'services/Snackbar';
import { AxiosError } from 'axios';
import { ActionsTypes, TCreateDriverCar } from 'store/reducers/drivers/types';
import { Driver, IDriversFilters } from 'interfaces/Driver';
import { serverErrorText } from 'constants/ServerCode';
import { ILoadDriverCars } from 'interfaces/Driver';

const validateError = (err: AxiosError) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }
  const errorCode = error.response.status;
  // @ts-ignore
  const errorMessage: string = serverErrorText[errorCode] || error.message;
  Snackbar.show(errorMessage, 'error');
  return errorMessage;
};

export const loadDrivers = createAsyncThunk(
  ActionsTypes.LOAD_ALL_DRIVERS,
  async (filters: IDriversFilters, { rejectWithValue }) => {
    try {
      const response = await loadAll(filters);
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);

export const loadDriverById = createAsyncThunk(ActionsTypes.LOAD_DRIVER, async (id: string, { rejectWithValue }) => {
  try {
    const response = await loadById(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(validateError(err));
  }
});

export const updateDriverById = createAsyncThunk(
  ActionsTypes.UPDATE_DRIVER,
  async (driver: Driver, { rejectWithValue }) => {
    try {
      const { id, ...fields } = driver;
      const response = await updateById(id, { data: fields });
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);

export const deleteDriverById = createAsyncThunk(
  ActionsTypes.DELETE_DRIVER,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await deleteById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);

export const createDriver = createAsyncThunk(
  ActionsTypes.CREATE_DRIVER,
  async (driver: Driver, { rejectWithValue }) => {
    try {
      const response = await create({ data: driver });
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);

export const createDriverCar = createAsyncThunk(
  ActionsTypes.CREATE_DRIVER_CAR,
  async (data: TCreateDriverCar, { rejectWithValue }) => {
    try {
      const { driverId, ...fields } = data;
      const response = await createCarById(driverId, fields.car);
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);

export const loadDriverCars = createAsyncThunk(
  ActionsTypes.LOAD_DRIVER_CARS,
  async (data: ILoadDriverCars, { rejectWithValue }) => {
    const { id, ...filters } = data;
    try {
      const response = await loadDriverCarsById(id, filters);
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);

export const freezeDriverById = createAsyncThunk(
  ActionsTypes.FREEZE_DRIVER,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await freeze(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(validateError(err));
    }
  },
);
