import { Promotion } from 'interfaces/Promotions';

export const MAX_NUMBER_OF_SPONSORS_PER_REQUEST = 10;

export interface ISponsorsFilters {
  limit?: number;
  offset?: number;
}

export type TCreateSponsorPromotion = {
  sponsorId: string;
  promotion: Promotion;
};

export interface Sponsor {
  readonly id: string;
  name: string;
  city: string;
  activeCompanies: string;
  phone?: string;
  eMail: string;
  taxStatus?: string;
  beneficiary?: string;
  inn?: string;
  kpp?: string;
  bik?: string;
  account?: string;
  phoneExt?: string;
  __promotions__: Promotion[];
}
