import { createSelector, createSlice } from '@reduxjs/toolkit';
import { TState } from 'store';
import { createPromo, deletePromo, editPromo, getPromo, getPromoList } from './actions';
import { PromoState } from './types';

const initialState: PromoState = {
  list: [],
  item: null,
  loading: false,
  error: '',
  count: 0,
};

const promoSlice = createSlice({
  name: 'promo',
  initialState,
  reducers: {
    resetPromoList: (state) => {
      state.list = [];
      state.count = 0;
    },
    resetPromo: (state) => {
      state.item = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPromoList.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getPromoList.fulfilled, (state, action) => {
      const [list, count] = action.payload.data;
      state.list = list;
      state.count = count;
      state.loading = false;
    });
    builder.addCase(getPromoList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? '';
    });

    builder.addCase(getPromo.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getPromo.fulfilled, (state, action) => {
      state.item = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getPromo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? '';
    });

    builder.addCase(createPromo.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(createPromo.fulfilled, (state, action) => {
      state.list = [action.payload.data, ...state.list];
      state.count += 1;
      state.loading = false;
    });
    builder.addCase(createPromo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? '';
    });

    builder.addCase(editPromo.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(editPromo.fulfilled, (state, action) => {
      state.item = action.payload.data;
      state.loading = false;

      const id = action.meta.arg.id;
      const index = state.list.findIndex((item) => item.id === id);

      if (!index) return;

      state.list = [...state.list.slice(0, index), action.payload.data, ...state.list.slice(index + 1)];
    });
    builder.addCase(editPromo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? '';
    });

    builder.addCase(deletePromo.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(deletePromo.fulfilled, (state, action) => {
      state.item = null;
      state.count -= 1;
      state.loading = false;

      const id = action.meta.arg.id;
      const index = state.list.findIndex((item) => item.id === id);

      if (!index) return;

      state.list = [...state.list.slice(0, index), ...state.list.slice(index + 1)];
    });
    builder.addCase(deletePromo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? '';
    });
  },
});

const self = (state: TState) => state.promo;

export const promoListSelector = createSelector(self, (state) => state.list);

export const promoListCountSelector = createSelector(self, (state) => state.count);

export const promoItemSelector = createSelector(self, (state) => state.item);

export const promoIsLoadingSelector = createSelector(self, (state) => state.loading);

export const promoErrorSelector = createSelector(self, (state) => state.error);

export const { resetPromoList, resetPromo } = promoSlice.actions;

export default promoSlice.reducer;
