import { ISponsorsFilters, MAX_NUMBER_OF_SPONSORS_PER_REQUEST, Sponsor } from 'interfaces/Sponsors';
import { IBaseState } from 'store/baseTypes';

export const initialSponsorFilters: ISponsorsFilters = {
  offset: undefined,
  limit: MAX_NUMBER_OF_SPONSORS_PER_REQUEST,
};

export const initialSponsor: Sponsor = {
  id: '',
  name: '',
  city: '',
  activeCompanies: '',
  eMail: '',
  __promotions__: [],
};

export enum ActionsTypes {
  LOAD_ALL_SPONSORS = 'LOAD_ALL_SPONSORS',
  LOAD_SPONSOR = 'LOAD_SPONSOR',
  CREATE_SPONSOR = 'CREATE_SPONSOR',
  UPDATE_SPONSOR = 'UPDATE_SPONSOR',
  DELETE_SPONSOR = 'DELETE_SPONSOR',
  CREATE_SPONSOR_PROMOTION = 'CREATE_SPONSOR_PROMOTION',
}

export type SponsorsState = IBaseState<Sponsor, ISponsorsFilters>;

export const initialState: SponsorsState = {
  listing: {
    entities: [],
    count: 0,
    filters: initialSponsorFilters,
    loading: false,
    loaded: false,
    error: null,
  },
  item: {
    entity: initialSponsor,
    loading: false,
    loaded: false,
    error: null,
  },
};
