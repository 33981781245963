import React, { useCallback, useMemo } from 'react';
import { FullscreenControl, Map, YMaps, ZoomControl } from 'react-yandex-maps';
import { loadJS } from 'utils/loadJs';
import { makeStyles } from '@material-ui/core/styles';
import { yandexApiKey } from 'config/settings';

type TMapsProps = {
  coordinates: string;
};

const RouteMaps: React.FC<TMapsProps> = ({ coordinates }) => {
  const classes = useStyles();
  const mapRef = React.createRef();

  const getNormalizeData = (data: string) => {
    const arrayData = data.split(' ');
    const arrayToStringData = arrayData.map((item: string) => {
      return item.slice(0, -1).substring(1).split(',');
    });
    return arrayToStringData.map((item) => {
      return item.map((item2) => {
        return parseFloat(item2);
      });
    });
  };

  const getMemoizedData = useMemo(() => getNormalizeData(coordinates), [coordinates]);

  const mapState = {
    center: getMemoizedData[0],
    zoom: 15,
  };

  const getData = useCallback(
    (ymaps: any) => {
      if (mapRef.current) {
        ymaps.modules.require(['Heatmap'], (Heatmap: any) => {
          const data = getMemoizedData,
            heatmap = new Heatmap(data);
          heatmap.options.set('radius', 20);
          heatmap.setMap(mapRef.current);
          console.log('ref', mapRef.current);
        });
      }
    },
    [getMemoizedData, mapRef],
  );

  const getDeps = useCallback(
    (ymaps: any) => {
      loadJS('heatmap', 'https://yastatic.net/s3/mapsapi-jslibs/heatmap/0.0.1/heatmap.min.js', document.head, () =>
        getData(ymaps),
      );
    },
    [getData],
  );

  return (
    <div>
      {coordinates ? (
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
          <YMaps
            query={{
              ns: 'ymaps',
              apikey: yandexApiKey,
            }}
            enterprise
          >
            <Map
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100vh',
              }}
              // @ts-ignore
              instanceRef={(yaMap) => {
                if (yaMap) {
                  // @ts-ignore
                  mapRef.current = yaMap;
                }
              }}
              state={mapState}
              onLoad={(ymaps) => getDeps(ymaps)}
            >
              <FullscreenControl />
              <ZoomControl options={{ float: 'right' }} />
            </Map>
          </YMaps>
        </div>
      ) : (
        <span className={classes.noAdsText}>Здесь будут отображаться области в которых ездили ТС</span>
      )}
    </div>
  );
};

export default RouteMaps;

const useStyles = makeStyles((theme) => ({
  noAdsText: {
    fontWeight: 300,
    fontSize: 16,
    lineHeight: '19px',
    color: theme.palette.primary.dark,
  },
}));
