export interface IHelper {
  id: number;
  displayName: string;
}

export type THelperObject = {
  entities: IHelper[];
  loading: boolean;
  loaded: boolean;
  error: string | null | undefined;
};

export type THelpers = {
  helperCitiesItem: THelperObject;
  helperMarksItem: THelperObject;
  helperModelsItem: THelperObject;
  helperColorsItem: THelperObject;
  helperBodiesItem: THelperObject;
  helperClassesItem: THelperObject;
  helperLandingCitiesItem: THelperObject;
  helperTaxStatusesItem: THelperObject;
};

export const initialState: THelpers = {
  helperCitiesItem: { entities: [{ id: 0, displayName: '' }], loading: false, loaded: false, error: null },
  helperMarksItem: { entities: [{ id: 0, displayName: '' }], loading: false, loaded: false, error: null },
  helperModelsItem: { entities: [{ id: 0, displayName: '' }], loading: false, loaded: false, error: null },
  helperColorsItem: { entities: [{ id: 0, displayName: '' }], loading: false, loaded: false, error: null },
  helperBodiesItem: { entities: [{ id: 0, displayName: '' }], loading: false, loaded: false, error: null },
  helperClassesItem: { entities: [{ id: 0, displayName: '' }], loading: false, loaded: false, error: null },
  helperLandingCitiesItem: { entities: [{ id: 0, displayName: '' }], loading: false, loaded: false, error: null },
  helperTaxStatusesItem: { entities: [{ id: 0, displayName: '' }], loading: false, loaded: false, error: null },
};

export enum ActionsTypes {
  LOAD_ALL_CAR_CITIES_HELPER = 'LOAD_ALL_CAR_CITIES_HELPER',
  LOAD_ALL_CAR_MARKS_HELPER = 'LOAD_ALL_CAR_MARKS_HELPER',
  LOAD_ALL_CAR_MODELS_HELPER = 'LOAD_ALL_CAR_MODELS_HELPER',
  LOAD_ALL_CAR_COLORS_HELPER = 'LOAD_ALL_CAR_COLORS_HELPER',
  LOAD_ALL_CAR_BODIES_HELPER = 'LOAD_ALL_CAR_BODIES_HELPER',
  LOAD_ALL_CAR_CLASSES_HELPER = 'LOAD_ALL_CAR_CLASSES_HELPER',
  LOAD_ALL_LANDING_CITIES_HELPER = 'LOAD_ALL_LANDING_CITIES_HELPER',
  LOAD_ALL_TAX_STATUSES_HELPER = 'LOAD_ALL_TAX_STATUSES_HELPER',
  LOAD_ALL_MODELS_BY_MARK_HELPER = 'LOAD_ALL_MODELS_BY_MARK_HELPER',
  FIND_CAR_MARKS = 'FIND_CAR_MARKS',
}
