import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from 'constants/Routes';
import { useSelector } from 'react-redux';
import BaseLayout from 'components/baseLayout';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ColumnContainer from 'components/layouts/columnContainer';
import Modal from 'components/shared/modal';
import { AdCompanyEditForm } from 'constants/Forms';
import ConfirmActionModal from 'components/shared/modal/ConfirmActionModal';
import {
  loadPromotionById,
  deletePromotionById,
  loadPromotionCars,
  holdCar,
  finishPromo,
  startPromo,
  getMaps,
} from 'store/reducers/promotions/actions';
import {
  getPromotion,
  getPromotionLoading,
  changeCarsFilters,
  getPromotionCarsFilter,
  getPromotionCarsLoading,
  getPromotionCars,
  getCarsCount,
  getPromoMaps,
  getPromoMapsLoading,
} from 'store/reducers/promotions';
import Loader from 'components/shared/loader';
import AdCompanyHeader from 'components/containers/adCompanies/item/elements/header';
import Filters from 'components/containers/drivers/item/elements/cars/filters';
import CarsListing from 'components/containers/drivers/item/elements/cars';
import AdCompanyView from 'components/containers/adCompanies/item/elements/view';
import AdCompanyEdit from 'components/containers/adCompanies/item/elements/edit';
import SelectCarsContainer from 'components/containers/cars/selectListing';
import RouteMaps from 'components/containers/adCompanies/item/elements/maps';
import { useAppDispatch } from 'store';
import { getFilterOffset, getPageByOffset } from 'utils/common';
import { MAX_NUMBER_OF_CAR_PER_REQUEST } from 'interfaces/Car';
import Pagination from '@material-ui/lab/Pagination';
import { loadCarCitiesHelper, loadCarClassesHelper } from 'store/reducers/helpers/actions';
import { changeFilters } from 'store/reducers/promotions';
import { initialPromotionsFilters } from 'store/reducers/promotions/types';
import { getUserMe } from '../../../../store/reducers/users';

const titles = {
  descriptions: 'Описание кампании',
  cars: 'Транспортные средства',
  maps: 'Карта GPS',
};

const AdCompanyContainer: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const promotion = useSelector(getPromotion);
  const isLoadingPromotion = useSelector(getPromotionLoading);
  const isLoadingPromotionCars = useSelector(getPromotionCarsLoading);
  const promotionCars = useSelector(getPromotionCars);
  const carsFilters = useSelector(getPromotionCarsFilter);
  const mapsCoordinates = useSelector(getPromoMaps);
  const isLoadingMapsCoordinates = useSelector(getPromoMapsLoading);
  const carsCount = useSelector(getCarsCount);
  const pageCount = Math.ceil(carsCount / MAX_NUMBER_OF_CAR_PER_REQUEST);

  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openDoneModal, setOpenDoneModal] = React.useState(false);
  const [openStartModal, setOpenStartModal] = React.useState(false);
  const [isAddCar, setIsAddCar] = React.useState(false);
  const [page, setPage] = useState<number>(getPageByOffset(carsFilters.offset));
  const userMe = useSelector(getUserMe);

  const handleChangePage = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
      const offset = getFilterOffset(value, MAX_NUMBER_OF_CAR_PER_REQUEST);
      dispatch(changeCarsFilters({ ...carsFilters, offset }));
    },
    [dispatch, carsFilters],
  );

  const handleCloseEditModal = () => setOpenEditModal(false);
  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);
  const handleCloseDoneModal = () => setOpenDoneModal(false);
  const handleOpenDoneModal = () => setOpenDoneModal(true);

  const handleCloseStartModal = () => setOpenStartModal(false);
  const handleOpenStartModal = () => setOpenStartModal(true);

  const handleOpenAddCar = () => setIsAddCar(true);
  const handleCloseAddCar = () => setIsAddCar(false);

  const onClickDelete = useCallback(async () => {
    await dispatch(deletePromotionById(id));
    dispatch(changeFilters(initialPromotionsFilters));
    history.push(Routes.adCompanies);
  }, [history, id, dispatch]);

  const onClickDone = useCallback(async () => {
    dispatch(finishPromo(id));
    handleCloseDoneModal();
  }, [dispatch, id]);

  const onClickStart = useCallback(async () => {
    dispatch(startPromo(id));
    handleCloseStartModal();
  }, [dispatch, id]);

  const onClickAddCars = useCallback(
    async (data: string[]) => {
      data.map((item: string) => dispatch(holdCar({ carId: item, promoId: id })));
      handleCloseAddCar();
    },
    [dispatch, id],
  );

  const onChangeFilters = useCallback(
    (filters) => {
      dispatch(changeCarsFilters(filters));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(loadPromotionById(id));
    dispatch(getMaps(id));
    dispatch(loadCarCitiesHelper());
    dispatch(loadCarClassesHelper());
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(loadPromotionCars({ id, ...carsFilters }));
  }, [carsFilters, id, dispatch]);

  if (isAddCar) {
    return <SelectCarsContainer onClickAddCars={onClickAddCars} onClickBack={handleCloseAddCar} />;
  }

  return (
    <BaseLayout>
      <Grid>
        <Grid item container xs={12} className={classes.padding}>
          <AdCompanyHeader
            title={promotion.name}
            onClickDone={handleOpenDoneModal}
            onClickStart={handleOpenStartModal}
            status={promotion.status}
            onClickDelete={handleOpenDeleteModal}
          />
        </Grid>
        <Grid item container xs={12}>
          <Grid item lg={4} xs={12} className={classes.borderTop}>
            <ColumnContainer title={titles.descriptions} onClickIcon={handleOpenEditModal}>
              {isLoadingPromotion ? <Loader show /> : <AdCompanyView adCompany={promotion} />}
            </ColumnContainer>
          </Grid>
          <Grid item lg={4} xs={12} className={classes.border}>
            <ColumnContainer
              title={titles.cars}
              count={carsCount}
              actionText="+ Добавить ТС"
              onClick={handleOpenAddCar}
              enableButton={userMe?.admin}
            >
              <Filters filters={carsFilters} onChangeFilters={onChangeFilters} isPromos />
              {isLoadingPromotionCars ? <Loader show /> : <CarsListing cars={promotionCars} />}
              {pageCount > 1 && (
                <Pagination
                  className={classes.pagination}
                  count={pageCount}
                  shape="rounded"
                  page={page}
                  onChange={handleChangePage}
                />
              )}
            </ColumnContainer>
          </Grid>
          <Grid item lg={4} xs={12} className={classes.borderTop}>
            <ColumnContainer title={titles.maps}>
              {isLoadingMapsCoordinates ? <Loader show /> : <RouteMaps coordinates={mapsCoordinates} />}
            </ColumnContainer>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        actionTitle="Сохранить"
        formId={AdCompanyEditForm}
        modalTitle="Рекламная кампания"
      >
        {isLoadingPromotion ? (
          <Loader show />
        ) : (
          <AdCompanyEdit onSave={handleCloseEditModal} sponsorId={promotion?.sponsor?.id} adCompany={promotion} />
        )}
      </Modal>
      <ConfirmActionModal
        openModalStatus={openDeleteModal}
        buttonClose={handleCloseDeleteModal}
        buttonDelete={onClickDelete}
        actionText="Удалить"
        text="рекламную кампанию"
      />
      <ConfirmActionModal
        openModalStatus={openDoneModal}
        buttonClose={handleCloseDoneModal}
        buttonDelete={onClickDone}
        actionText="Завершить"
        text="рекламную кампанию"
      />
      <ConfirmActionModal
        openModalStatus={openStartModal}
        buttonClose={handleCloseStartModal}
        buttonDelete={onClickStart}
        cancelable
        isAccept
        actionText="Одобрить"
        text="рекламную кампанию"
      />
    </BaseLayout>
  );
};

export default AdCompanyContainer;

const useStyles = makeStyles((theme: Theme) => ({
  padding: {
    padding: 16,
  },
  borderTop: {
    borderTop: '1px solid #F2F2F2',
  },
  border: {
    borderLeft: '1px solid #F2F2F2',
    borderRight: '1px solid #F2F2F2',
    borderTop: '1px solid #F2F2F2',
    [theme.breakpoints.down('md')]: {
      borderLeft: 'none',
      borderRight: 'none',
    },
  },
  pagination: {
    float: 'right',
    marginBottom: theme.spacing(1),
  },
}));
