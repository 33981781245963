import React from 'react';
import { Grid } from '@material-ui/core';
import FieldView from 'components/shared/inputs/FieldView';
import Divider from '@material-ui/core/Divider';
import { Driver } from 'interfaces/Driver';
import { TaxStatuses } from '../../../../../../store/reducers/drivers/types';
import { useSelector } from 'react-redux';
import { getUserMe } from '../../../../../../store/reducers/users';

type TCarViewProps = {
  driver: Driver;
};

const DriverView: React.FC<TCarViewProps> = ({ driver }) => {
  const userMe = useSelector(getUserMe);

  return (
    <Grid container>
      <Grid item xs={12}>
        <FieldView title="ФИО:" value={driver?.name || driver?.driverName} />
        {userMe?.admin && (
          <>
            <FieldView title="Телефон: :" value={driver?.phone} />
            <FieldView title="E-mail:" value={driver?.eMail} />
            <Divider component="div" style={{ marginBottom: 16 }} />
            <FieldView title="Налоговый статус:" value={driver?.taxStatus} />
            <FieldView title="Получатель:" value={driver?.beneficiary} />
            <FieldView title="ИНН:" value={driver?.inn} />
            {driver?.taxStatus === TaxStatuses.legalEntity && <FieldView title="КПП:" value={driver?.kpp} />}
            <FieldView title="БИК:" value={driver?.bik} />
            <FieldView title="Р/С:" value={driver?.account} />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(DriverView);
