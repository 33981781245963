import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import classnames from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { format, parseISO } from 'date-fns';
import { ImageModal } from 'components/containers/cars/item/elements/modal';

type TPhotoReportProps = {
  report?: any;
  title?: string;
};

const PhotoReport: React.FC<TPhotoReportProps> = ({ report }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [currentImgUrl, setCurrentImgUrl] = useState('');

  const [openImageModal, setOpenImageModal] = useState(false);
  const handleOpenImageModal = () => setOpenImageModal(true);
  const handleCloseImageModal = () => setOpenImageModal(false);

  const handleImgClick = (img: string) => {
    setCurrentImgUrl(img);
    handleOpenImageModal();
  };

  return (
    <>
      <div className={classes.title} onClick={handleExpandClick}>
        <div>
          {format(parseISO(report.datetime), 'dd.MM.yyyy')}
          <IconButton
            className={classnames(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
        <div className={classes.subTitle}>{report.index === 1 && 'Стартовый фотоотчет'}</div>
      </div>
      <Collapse classes={{ wrapperInner: classes.container }} in={expanded} timeout="auto" unmountOnExit>
        {report && (
          <>
            {report.BackView && (
              <div
                className={classes.photos}
                onClick={() => handleImgClick(report.BackView.URI)}
                style={{
                  backgroundImage: `url(${report.BackView.thumbnail})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            )}
            {report.DriverSideView && (
              <div
                className={classes.photos}
                onClick={() => handleImgClick(report.DriverSideView.URI)}
                style={{
                  backgroundImage: `url(${report.DriverSideView.thumbnail})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            )}
            {report.FrontView && (
              <div
                className={classes.photos}
                onClick={() => handleImgClick(report.FrontView.URI)}
                style={{
                  backgroundImage: `url(${report.FrontView.thumbnail})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            )}
            {report.Odometer && (
              <div
                className={classes.photos}
                onClick={() => handleImgClick(report.Odometer.URI)}
                style={{
                  backgroundImage: `url(${report.Odometer.thumbnail})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            )}
            {report.PassengerSideView && (
              <div
                className={classes.photos}
                onClick={() => handleImgClick(report.PassengerSideView.URI)}
                style={{
                  backgroundImage: `url(${report.PassengerSideView.thumbnail})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            )}
            {report.TopView && (
              <div
                className={classes.photos}
                onClick={() => handleImgClick(report.TopView.URI)}
                style={{
                  backgroundImage: `url(${report.TopView.thumbnail})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            )}
          </>
        )}
      </Collapse>
      <ImageModal isOpen={openImageModal} url={currentImgUrl} handleClose={handleCloseImageModal} />
    </>
  );
};

export default React.memo(PhotoReport);

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 300,
    fontSize: 16,
    lineHeight: '19px',
    cursor: 'pointer',
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  subTitle: {
    fontWeight: 300,
    fontSize: 14,
    lineHeight: '16px',
    cursor: 'pointer',
    color: theme.palette.text.secondary,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    padding: '5px !important',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  photos: {
    width: 76,
    height: 76,
    borderRadius: 4,
    margin: 4,
    flex: '0 0 auto',
  },
  image: {
    position: 'absolute',
    top: '50%',
    lef: '50%',
  },
}));
