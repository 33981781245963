import React, { FC, useCallback, useState } from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EmailWasSend } from './elements/emailWasSend';
import { useForm } from 'react-hook-form';
import { restore } from 'store/reducers/auth/actions';
import { useAppDispatch } from 'store';

interface IFormInputs {
  email: string;
}

type IProps = {};

export const CantAuthorize: FC<IProps> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [openModalStatus, setOpenModalStatus] = useState(false);
  const { register, handleSubmit, errors } = useForm<IFormInputs>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
    defaultValues: {
      email: '',
    },
  });

  const handleButton = () => setOpenModalStatus(!openModalStatus);

  const onSubmit = useCallback(
    async (data: IFormInputs, errors: any) => {
      try {
        await dispatch(restore(data)).then((result) => {
          if (result.payload.success) {
            handleButton();
          }
        });
      } catch (err) {
        console.warn(err);
      }
    },
    [handleButton, dispatch],
  );

  return (
    <Grid container spacing={0} alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
      <div className={classes.paper}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <h1 className={classes.hOne}>Не удалось войти?</h1>
          <div className={classes.textInfoBlock}>
            <p className={classes.textInfo}>Введите свой e-mail и мы отправим вам</p>
            <p className={classes.textInfo}>на почту ссылку для восстановления</p>
            <p className={classes.textInfo}>доступа к аккаунту</p>
          </div>
          <div className={classes.textUnderField}>E-mail</div>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            size="small"
            id="email"
            name="email"
            autoComplete="email"
            autoFocus
            className={classes.textInput}
            inputRef={register({
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Введите e-mail',
              },
              required: true,
            })}
          />
          <div className={classes.error}>{errors?.email?.message}</div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            size="small"
            color="primary"
            classes={{ containedPrimary: classes.containedPrimary, root: classes.buttonText }}
            className={classes.submit}
          >
            Получить ссылку
          </Button>
        </form>
      </div>
      <EmailWasSend openModalStatus={openModalStatus} buttonClick={handleButton} />
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    marginTop: theme.spacing(2),
    height: '32px',
  },
  containedPrimary: {
    backgroundColor: '#2D9CDB',
    '&:hover': {
      backgroundColor: '#2D9CDB',
    },
  },
  textInfoBlock: {
    marginBottom: theme.spacing(4),
  },
  textInput: {
    '& .MuiOutlinedInput-root': {
      height: '32px',
      '&.Mui-focused fieldset': {
        borderColor: '#2D9CDB',
      },
    },
    marginTop: 0,
    marginBottom: theme.spacing(2),
  },
  textUnderField: {
    color: '#7D7D7D',
    fontWidth: '300',
    fontSize: '16px',
    lineHeight: '18.5px',
    fontStyle: 'normal',
    fontFamily: 'Roboto, sans-serif',
    marginBottom: '2px',
  },
  textInfo: {
    color: 'black',
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '18px',
    textAlign: 'center',
  },
  hOne: {
    color: 'black',
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '28px',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    marginTop: 0,
  },
  buttonText: {
    textTransform: 'inherit',
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '18px',
  },
  error: {
    color: 'red',
  },
}));
