import React from 'react';
import { OnWorkDetail, OnPauseDetail, OnDeclineDetail, OnHoldDetail, OnReviewDetail } from 'assets/cars';
import { OnFrozenDetail } from 'assets/drivers';
import { NewDetail, OnDoneDetail, OnWorkDetail as OnWorkPromoDetail } from 'assets/promotions';
import { CarStatus } from 'interfaces/Car';
import { DriverStatus } from 'interfaces/Driver';
import { PromotionStatus } from 'interfaces/Promotions';

type TStatusProps = {
  status: CarStatus | DriverStatus | PromotionStatus | string | undefined;
};

const Status: React.FC<TStatusProps> = ({ status }) => {
  if (status === CarStatus.Busy) return <OnWorkDetail />;
  if (status === CarStatus.Review) return <OnReviewDetail />;
  if (status === CarStatus.Hold) return <OnHoldDetail />;
  if (status === CarStatus.Pause) return <OnPauseDetail />;
  if (status === CarStatus.Reject) return <OnDeclineDetail />;
  if (status === PromotionStatus.new) return <NewDetail />;
  if (status === PromotionStatus.done) return <OnDoneDetail />;
  if (status === PromotionStatus.work) return <OnWorkPromoDetail />;
  if (status === DriverStatus.frozen) return <OnFrozenDetail />;
  return null;
};

export default React.memo(Status);
