import React, { FC, useCallback, useRef } from 'react';
import { ReactComponent as Logo } from 'assets/images/Logo.svg';
import { ReactComponent as OrgName } from 'assets/images/OrgName.svg';
import { Button, Container, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAppDispatch } from 'store';
import { registration } from 'store/reducers/auth/actions';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { Routes } from 'constants/Routes';

interface IFormInputs {
  email: string;
  password: string;
  password_repeat: string;
}

type IProps = {};

export const Registration: FC<IProps> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { register, handleSubmit, errors, watch } = useForm<IFormInputs>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const password = useRef({});
  password.current = watch('password', '');

  const onSubmit = useCallback(
    async (data: IFormInputs, errors: any) => {
      try {
        await dispatch(registration(data)).then((result) => {
          if (result.payload.data.token) {
            history.push(Routes.adCompanies);
          }
        });
      } catch (err) {
        console.warn(err);
      }
    },
    [history, dispatch],
  );

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <div className={classes.logo}>
          <Logo />
        </div>
        <Typography component="h1" variant="h5">
          <OrgName />
        </Typography>
        <h1 className={classes.text}>Регистрация</h1>
        <h2 className={classes.subText}>
          Уже есть аккаунт?{' '}
          <Link className={classes.link} to={Routes.auth}>
            Войти
          </Link>
        </h2>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.textUnderField}>E-mail</div>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            size="small"
            id="email"
            name="email"
            autoComplete="email"
            autoFocus
            className={classes.textInput}
            inputRef={register({
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Введите e-mail',
              },
              required: true,
            })}
          />
          <div className={classes.error}>{errors?.email?.message}</div>
          <div className={classes.textUnderField}>Пароль</div>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            size="small"
            name="password"
            type="password"
            id="password"
            autoComplete="current-password"
            className={classes.textInput}
            inputRef={register({
              minLength: {
                value: 1,
                message: 'Введите пароль',
              },
              required: true,
            })}
          />
          <div className={classes.error}>{errors?.password?.message}</div>
          <div className={classes.textUnderField}>Повторите пароль</div>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            size="small"
            name="password_repeat"
            type="Password"
            id="password_repeat"
            autoComplete="current-password"
            className={classes.textInput}
            inputRef={register({
              minLength: {
                value: 1,
                message: 'Введите пароль',
              },
              required: true,
              validate: (value) => value === password.current || 'Пароли не совпадают',
            })}
          />
          <div className={classes.error}>{errors?.password_repeat?.message}</div>
          <p>
            Продолжая, вы принимаете{' '}
            <Link className={classes.link} to={Routes.termsAndConditions} target="_blank">
              правила пользования приложением
            </Link>{' '}
            и даете согласие на{' '}
            <Link className={classes.link} to={Routes.consentToDataProcessing} target="_blank">
              обработку персональных данных
            </Link>
          </p>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            size="small"
            color="primary"
            classes={{ containedPrimary: classes.containedPrimary, root: classes.buttonText }}
            className={classes.submit}
          >
            Зарегистрироваться
          </Button>
        </form>
      </div>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  logo: {
    marginBottom: theme.spacing(3),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    marginTop: theme.spacing(2),
  },
  containedPrimary: {
    backgroundColor: '#2D9CDB',
    '&:hover': {
      backgroundColor: '#2D9CDB',
    },
  },
  textInput: {
    '& .MuiOutlinedInput-root': {
      height: '32px',
      '&.Mui-focused fieldset': {
        borderColor: '#2D9CDB',
      },
    },
    marginTop: 0,
    marginBottom: theme.spacing(2),
  },
  textUnderField: {
    color: '#7D7D7D',
    fontWidth: '300',
    fontSize: '16px',
    lineHeight: '18.5px',
    fontStyle: 'normal',
    fontFamily: 'Roboto, sans-serif',
    marginBottom: '2px',
  },
  buttonText: {
    textTransform: 'inherit',
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '18px',
  },
  error: {
    color: 'red',
  },
  text: {
    fontSize: 24,
    lineHeight: '28px',
    fontWeight: 'normal',
  },
  subText: {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 18,
    lineHeight: '21px',
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
  },
}));
