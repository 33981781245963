import React from 'react';
import { Driver } from 'interfaces/Driver';
import Status from 'components/shared/status';
import driversStyles from './styles';

export type OnClickListItem = {
  onEdit: (id: string | undefined) => void;
};

export type PropsListItem = {
  item: Partial<Driver>;
} & OnClickListItem;

const DriverListItem = ({ item: { id, name, city, eMail, status }, onEdit }: PropsListItem) => {
  const classes = driversStyles();
  const onClickEdit = () => {
    onEdit(id);
  };

  return (
    <div className={classes.container} role="presentation" onClick={onClickEdit}>
      <div className={classes.infoContainer}>
        <div className={classes.item}>
          <div>{name}</div>
        </div>
        <div className={classes.item}>
          <div>{city}</div>
        </div>
        <div className={classes.item}>
          <div>{eMail}</div>
        </div>
        <div className={classes.item}>
          <Status status={status} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(DriverListItem);
