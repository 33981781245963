import axios from 'axios';
import { authService } from 'services/AuthService';
import env from 'config/settings';

export const axiosClient = axios.create({
  baseURL: `${env.API_HOST}`,
  responseType: 'json',
});

axiosClient.interceptors.request.use(
  (request) => {
    if (request.headers && authService.token) {
      request.headers.Authorization = `Bearer ${authService.token}`;
    }
    return request;
  },
  (error) => Promise.reject(error),
);

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (status === 401) {
      authService.signOut();
    }
    return Promise.reject(error);
  },
);
