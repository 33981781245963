import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import BaseLayout from 'components/baseLayout';
import Pagination from '@material-ui/lab/Pagination';
import CarsHeader from 'components/containers/cars/listing/elements/header';
import CarsFilters from 'components/containers/cars/listing/elements/filters';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import Loader from 'components/shared/loader';
import SelectCarList from 'components/containers/cars/selectListing/list/SelectCarList';
import { loadPromotionCarsForAdd } from 'store/reducers/promotions/actions';
import {
  getReviewCars,
  getReviewCarsLoading,
  getReviewCarsFilters,
  changeReviewCarsFilters,
  getReviewCarsCount,
} from 'store/reducers/promotions';
import { MAX_NUMBER_OF_CAR_PER_REQUEST } from 'interfaces/Car';
import { getFilterOffset, getPageByOffset } from 'utils/common';

type TProps = {
  onClickBack: () => void;
  onClickAddCars: (data: string[]) => void;
};

const SelectCarsContainer: React.FC<TProps> = ({ onClickBack, onClickAddCars }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const cars = useSelector(getReviewCars);
  const carsCount = useSelector(getReviewCarsCount);
  const pageCount = Math.ceil(carsCount / MAX_NUMBER_OF_CAR_PER_REQUEST);
  const carsLoading = useSelector(getReviewCarsLoading);
  const filters = useSelector(getReviewCarsFilters);
  const [page, setPage] = useState<number>(getPageByOffset(filters.offset));

  const handleChangePage = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
      const offset = getFilterOffset(value, MAX_NUMBER_OF_CAR_PER_REQUEST);
      dispatch(changeReviewCarsFilters({ ...filters, offset }));
    },
    [dispatch, filters],
  );

  const [carIds, setCarIds] = useState<string[]>([]);

  const onSelect = useCallback(
    (id: string) => {
      if (carIds.includes(id)) {
        setCarIds((prevState) => prevState.filter((item) => item !== id));
      } else {
        setCarIds((prevState) => [...prevState, id]);
      }
    },
    [carIds],
  );

  useEffect(() => {
    dispatch(loadPromotionCarsForAdd(filters));
  }, [dispatch, filters]);

  const onClickAdd = useCallback(() => {
    onClickAddCars(carIds);
  }, [carIds, onClickAddCars]);

  const onChangeSearch = useCallback(
    (searchValue) => {
      dispatch(changeReviewCarsFilters({ mark: searchValue, offset: undefined }));
    },
    [dispatch],
  );

  const onChangeFilters = useCallback(
    (filters) => {
      dispatch(changeReviewCarsFilters(filters));
    },
    [dispatch],
  );

  return (
    <BaseLayout>
      <Grid>
        <Grid item container xs={12} className={classes.padding}>
          <CarsHeader onClickBack={onClickBack} onChangeSearch={onChangeSearch} onClickAdd={onClickAdd} />
        </Grid>
        <Grid item container xs={12} className={classes.borderTop}>
          <Grid item lg={9} xs={12} className={classnames(classes.padding, classes.borderRight)}>
            {carsLoading ? <Loader show /> : <SelectCarList items={cars} carIds={carIds} onSelect={onSelect} />}
            {pageCount > 1 && (
              <Pagination
                className={classes.pagination}
                count={pageCount}
                shape="rounded"
                page={page}
                onChange={handleChangePage}
              />
            )}
          </Grid>
          <Grid item lg={3} xs={12}>
            <Grid item className={classnames(classes.padding, classes.title)}>
              <Typography variant="h2">Фильтр</Typography>
            </Grid>
            <Grid className={classes.padding}>
              <CarsFilters initial={filters} filters={filters} isSelect onChangeFilters={onChangeFilters} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BaseLayout>
  );
};

export default SelectCarsContainer;

const useStyles = makeStyles((_theme: Theme) => ({
  pagination: {
    float: 'right',
  },
  padding: {
    padding: 16,
  },
  borderTop: {
    borderTop: '1px solid #F2F2F2',
    flexWrap: 'wrap-reverse',
  },
  borderRight: {
    borderRight: '1px solid #F2F2F2',
  },
  title: {
    borderBottom: '1px solid #F2F2F2',
  },
}));
