export const loadJS = (id: string, url: string, location: Node, onLoad: () => void) => {
  const scriptTag = document.createElement('script');
  location.appendChild(scriptTag);
  scriptTag.src = url;
  scriptTag.id = id;
  if (onLoad) {
    onLoad();
    scriptTag.onload = onLoad;
  }
};
