import React, { SyntheticEvent } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Modal from 'components/shared/modal';
import Snackbar from 'services/Snackbar';
import TextFieldInput from 'components/shared/inputs/TextFieldInput';
import PhotoInput from 'components/shared/inputs/PhotoInput';
import { Controller, useForm } from 'react-hook-form';
import { Promo } from 'store/reducers/promo/types';
import { createStyles, makeStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { axiosClient } from 'services/ApiClient';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { endOfDay, format, parse } from 'date-fns';
import VALIDATION_MESSAGES from 'constants/ValidationsMessage';
import { ErrorMessage } from '@hookform/error-message';

type FormDate = Pick<Promo, 'photo' | 'name' | 'annotation' | 'QRCode' | 'promoCode' | 'refUrl' | 'description'> & {
  finish: string | null;
};

const FORM_ID = 'promo-form';

const validationSchema = object({
  photo: object().nullable(),
  name: string().required(VALIDATION_MESSAGES.required),
  annotation: string().required(VALIDATION_MESSAGES.required),
  finish: string().nullable().required(VALIDATION_MESSAGES.required),
  QRCode: string().nullable().required(VALIDATION_MESSAGES.required),
  promoCode: string().required(VALIDATION_MESSAGES.required),
  refUrl: string().required(VALIDATION_MESSAGES.required),
  description: string().required(VALIDATION_MESSAGES.required).max(500, VALIDATION_MESSAGES.maxLength(500)),
});

type Props = {
  promo?: Partial<Promo>;
  open: boolean;
  onClose: () => void;
  onSubmit: (promo: Partial<Promo>) => void;
};

export const PromoEditModal = ({ promo, open, onClose, onSubmit }: Props) => {
  const classes = useStyles();

  const defaultFinish = promo?.finish ? format(new Date(promo.finish), 'dd.MM.yyyy') : null;

  const { control, errors, handleSubmit, watch } = useForm<FormDate>({
    defaultValues: {
      photo: promo?.photo,
      name: promo?.name,
      annotation: promo?.annotation,
      finish: defaultFinish,
      QRCode: promo?.QRCode,
      promoCode: promo?.promoCode,
      refUrl: promo?.refUrl,
      description: promo?.description,
    },
    resolver: yupResolver(validationSchema),
  });

  const handleChangeExpirationDate = (onChange: (value: any) => void) => (
    _date: MaterialUiPickersDate | null,
    value?: string | null,
  ) => onChange(value ?? '');

  const handleChangeImage = (onChange: (value: any) => void) => (event: SyntheticEvent<EventTarget>) => {
    const formData = new FormData();
    const file = (event.target as HTMLFormElement).files[0];
    formData.append('file', file);

    axiosClient
      .post(`/photos`, formData, { responseType: 'text' })
      .then((response) => response.data?.data)
      .then((image) => onChange(image))
      .catch(() => Snackbar.show('Ошибка загрузки фотографии', 'error'));
  };

  const handleRemoveImage = (onChange: (value: any) => void) => () => {
    onChange(null);
  };

  const hangeChangeLogo = (onChange: (value: any) => void) => (value: any) => {
    onChange(value);
  };

  const hangeChangeQrCode = (onChange: (value: any) => void) => (value: any) => {
    onChange(value?.URI);
  };

  const submit = (data: FormDate) => {
    const { finish, ...other } = data;
    const finishDate = finish ? endOfDay(parse(finish, 'dd.MM.yyyy', new Date())).toISOString() : undefined;
    onSubmit({ ...other, finish: finishDate });
  };

  return (
    <Modal open={open} formId={FORM_ID} modalTitle="Промо" actionTitle="Сохранить" onClose={onClose}>
      <Grid component="form" id={FORM_ID} container direction="column" onSubmit={handleSubmit(submit)}>
        <Grid className={classes.row} item>
          <Controller
            as={TextFieldInput}
            field={{ title: 'Организация' }}
            name="name"
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid className={classes.row} item>
          <Controller
            as={TextFieldInput}
            field={{ title: 'Название', multiline: true }}
            name="annotation"
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid className={classes.row} item>
          <Controller
            control={control}
            name={'finish'}
            render={(props) => (
              <>
                <Typography className={classes.dateLabel}>Истекает</Typography>
                <KeyboardDatePicker
                  inputProps={{ className: classes.dateInput }}
                  value={props.value ? parse(props.value, 'dd.MM.yyyy', new Date()) : null}
                  minDate={new Date()}
                  variant="inline"
                  placeholder="ДД.ММ.ГГГГ"
                  inputVariant="outlined"
                  format="dd.MM.yyyy"
                  autoOk
                  disableToolbar
                  fullWidth
                  invalidDateMessage="Неверный формат даты"
                  minDateMessage="Неверная дата завершения промо"
                  onChange={handleChangeExpirationDate(props.onChange)}
                />
                {errors && <ErrorMessage as="span" className={classes.errorText} name="finish" errors={errors} />}
              </>
            )}
          />
        </Grid>
        <Grid className={classes.row} item>
          <Controller
            name="QRCode"
            control={control}
            render={(props) => (
              <>
                <PhotoInput
                  name="QRCode"
                  url={props.value}
                  title="QR-код"
                  label="Добавить изображение"
                  onChange={handleChangeImage(hangeChangeQrCode(props.onChange))}
                  onDeleteImage={handleRemoveImage(props.onChange)}
                />
                {errors && <ErrorMessage as="span" className={classes.errorText} name="QRCode" errors={errors} />}
              </>
            )}
          />
        </Grid>
        <Grid className={classes.row} item>
          <Controller
            as={TextFieldInput}
            field={{ title: 'Промокод' }}
            name="promoCode"
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid className={classes.row} item>
          <Controller
            as={TextFieldInput}
            field={{ title: 'Реферальная ссылка' }}
            name="refUrl"
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid className={classes.row} item>
          <Controller
            as={TextFieldInput}
            field={{ title: 'Описание', multiline: true }}
            name="description"
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid className={classes.row} item>
          <Controller
            name="photo"
            control={control}
            render={(props) => (
              <>
                <PhotoInput
                  name="photo"
                  url={props.value?.URI}
                  title="Изображение"
                  label="Добавить изображение"
                  onChange={handleChangeImage(hangeChangeLogo(props.onChange))}
                  onDeleteImage={handleRemoveImage(props.onChange)}
                />
                {errors && <ErrorMessage as="span" className={classes.errorText} name="photo" errors={errors} />}
              </>
            )}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    row: {
      marginBottom: theme.spacing(2),
    },
    dateLabel: {
      paddingBottom: 2,
      width: '100%',
      fontWeight: 300,
      fontSize: 16,
      lineHeight: '19px',
      color: theme.palette.primary.main,
    },
    dateInput: {
      padding: '6.5px 8px',
      maxHeight: 32,
    },
    errorText: {
      color: theme.palette.secondary.light,
    },
  }),
);
