import * as Yup from 'yup';
import VALIDATION_MESSAGES, { DEFAULT_MAX_TEXT_LENGTH } from 'constants/ValidationsMessage';

export const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required(VALIDATION_MESSAGES.required)
    .nullable()
    .min(1, VALIDATION_MESSAGES.minLength(1))
    .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength()),
  city: Yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return Yup.object().required(VALIDATION_MESSAGES.required).nullable();
      case 'string':
        return Yup.string()
          .required(VALIDATION_MESSAGES.required)
          .nullable()
          .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength());
      default:
        return Yup.mixed();
    }
  }),
  eMail: Yup.string()
    .required(VALIDATION_MESSAGES.required)
    .nullable()
    .email(VALIDATION_MESSAGES.incorrectEmail)
    .min(1, VALIDATION_MESSAGES.minLength(1))
    .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength()),
  phone: Yup.string().required(VALIDATION_MESSAGES.required).nullable().min(1, VALIDATION_MESSAGES.minLength(1)),
  inn: Yup.string()
    .nullable()
    .min(10, VALIDATION_MESSAGES.minLength(10))
    .max(12, VALIDATION_MESSAGES.maxLength(12))
    .transform((val) => val || null),
  kpp: Yup.string()
    .nullable()
    .length(9, VALIDATION_MESSAGES.Length(9))
    .transform((val) => val || null),
  bik: Yup.string()
    .nullable()
    .length(9, VALIDATION_MESSAGES.Length(9))
    .transform((val) => val || null),
  account: Yup.string()
    .nullable()
    .length(20, VALIDATION_MESSAGES.Length(20))
    .transform((val) => val || null),
});
