import { Grid } from '@material-ui/core';
import {
  AdOnAuto,
  DownloadAndroid,
  DownloadiOS,
  LayoutPhoto,
  LayoutPhotoMobile,
  LayoutPhotoTablet,
  Pluses,
  RegIn,
} from 'assets/landing';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink, useLocation } from 'react-router-dom';
import { Routes } from '../../../../constants/Routes';
import { maxIPadMiniSize, maxIPadProSize, maxIphoneSize } from '../../index';

const LayoutHeader = () => {
  const classes = useStyles();
  const location = useLocation();

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };
    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  return (
    <Grid className={classes.mainPic} container direction="row">
      <Grid xs justify="center" item container direction="column" wrap="nowrap">
        <Grid item className={classes.adOnAuto}>
          <AdOnAuto className={classes.adOnAutoSVG} />
        </Grid>
        <Grid item container className={classes.mobile} direction="row">
          {location.pathname.split('/')[2] === 'drivers' ? (
            <>
              <DownloadiOS className={classes.downloadIOS} />
              <DownloadAndroid className={classes.downloadAndroid} />
            </>
          ) : (
            <NavLink to={Routes.auth}>
              <RegIn className={classes.regIn} />
            </NavLink>
          )}
        </Grid>
      </Grid>
      <Grid xs className={classes.layoutPhoto} item container justify="flex-end">
        <img
          src={
            width >= maxIphoneSize ? (width >= maxIPadMiniSize ? LayoutPhoto : LayoutPhotoTablet) : LayoutPhotoMobile
          }
          alt="layoutPhoto"
        />
      </Grid>
      {width < maxIphoneSize && <Pluses className={classes.pluses} />}
    </Grid>
  );
};

export default LayoutHeader;

const useStyles = makeStyles((theme) => ({
  mainPic: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      marginLeft: 128,
      marginBottom: 120,
    },
    [theme.breakpoints.between(maxIPadMiniSize, maxIPadProSize)]: {
      marginLeft: 72,
      marginBottom: 48,
    },
    [theme.breakpoints.down(maxIPadMiniSize)]: {
      marginLeft: 16,
      marginBottom: 16,
    },
  },
  adOnAuto: {
    [theme.breakpoints.up(maxIphoneSize)]: {
      marginTop: 91,
    },
    [theme.breakpoints.down(maxIphoneSize)]: {
      marginTop: 140,
    },
  },
  adOnAutoSVG: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      height: 162,
      width: 353,
      marginBottom: 32,
    },
    [theme.breakpoints.between(maxIPadMiniSize, maxIPadProSize)]: {
      height: 130,
      width: 282,
      marginBottom: 32,
    },
    [theme.breakpoints.between(maxIphoneSize, maxIPadMiniSize)]: {
      height: 150,
      width: 276,
      marginBottom: 32,
    },
    [theme.breakpoints.down(maxIphoneSize)]: {
      height: 150,
      width: 276,
      marginBottom: 24,
    },
  },
  layoutPhoto: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      height: 716,
      paddingRight: 128,
    },
    [theme.breakpoints.between(maxIPadMiniSize, maxIPadProSize)]: {
      height: 432,
      paddingRight: 72,
    },
    [theme.breakpoints.between(maxIphoneSize, maxIPadMiniSize)]: {
      height: 368,
      paddingRight: 16,
    },
    [theme.breakpoints.down(maxIphoneSize)]: {
      height: 386,
      paddingRight: 16,
    },
    marginTop: 91,
    width: '100%',
    '& img': {
      width: 'auto',
      height: '100%',
    },
  },
  mobile: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      maxWidth: 394,
    },
    [theme.breakpoints.down(maxIPadProSize)]: {
      maxWidth: 288,
    },
    justifyContent: 'space-between',
    alignContent: 'space-between',
  },
  downloadIOS: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      height: 55,
      width: 189.01,
    },
    [theme.breakpoints.down(maxIPadProSize)]: {
      height: 40,
      width: 136,
    },
  },
  downloadAndroid: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      height: 55,
      width: 185.62,
    },
    [theme.breakpoints.down(maxIPadProSize)]: {
      height: 40,
      width: 135,
    },
  },
  regIn: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      height: 64,
      width: 333,
    },
    [theme.breakpoints.down(maxIPadProSize)]: {
      height: 40,
      width: 208,
    },
  },
  pluses: {
    position: 'absolute',
    right: 5,
    height: 274.41,
    width: 121.76,
    marginTop: 91,
  },
}));
