import React, { useEffect, useState } from 'react';
import BaseLayout from 'components/baseLayout';
import Divider from '@material-ui/core/Divider';
import DeleteButton from 'components/shared/buttons/DeleteButton';
import { createStyles, makeStyles } from '@material-ui/core';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import { promoIsLoadingSelector, promoItemSelector, resetPromo } from 'store/reducers/promo';
import { deletePromo, editPromo, getPromo } from 'store/reducers/promo/actions';
import { Header } from 'components/shared/header/Header';
import { useHistory, useParams } from 'react-router';
import { PromoDetailsCard } from './details/details-card';
import { PromoEditModal } from './edit/edit-modal';
import { Promo } from 'store/reducers/promo/types';
import { PromoDeletetModal } from './delete/delete-modal';
import Loader from 'components/shared/loader';

export const PromoDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { id } = useParams<{ id: string }>();

  const [isEditModalVisible, setEditModalVisibility] = useState(false);

  const [isDeleteModalVisible, setDeleteModalVisibility] = useState(false);

  const promo = useSelector(promoItemSelector);
  const loading = useSelector(promoIsLoadingSelector);

  const openEditModal = () => setEditModalVisibility(true);

  const closeEditModal = () => setEditModalVisibility(false);

  const openDeleteModal = () => setDeleteModalVisibility(true);

  const closeDeleteModal = () => setDeleteModalVisibility(false);

  const handleEdit = async (data: Partial<Promo>) => {
    const { photo, photoId, ...dataWithoutPhoto } = data;

    const newPhotoId = photo?.id ?? null;

    await dispatch(editPromo({ ...promo, ...dataWithoutPhoto, photo, photoId: newPhotoId }));
    closeEditModal();
  };

  const handleDelete = async () => {
    if (promo?.id) {
      await dispatch(deletePromo({ id: promo.id }));
      closeDeleteModal();
      history.goBack();
    }
  };

  useEffect(() => {
    dispatch(getPromo({ id }));
  }, [dispatch, id]);

  useEffect(() => () => void dispatch(resetPromo()), [dispatch]);

  return (
    <BaseLayout>
      {loading && <Loader show />}
      {!loading && promo && (
        <>
          <div className={classes.root}>
            <Header title={promo.name ?? 'Промо'} back>
              <DeleteButton onClick={openDeleteModal} />
            </Header>
            <Divider className={classes.divider} />
            <PromoDetailsCard className={classes.card} promo={promo} onEdit={openEditModal} />
          </div>
          <PromoEditModal promo={promo} open={isEditModalVisible} onClose={closeEditModal} onSubmit={handleEdit} />
          <PromoDeletetModal open={isDeleteModalVisible} onClose={closeDeleteModal} onDelete={handleDelete} />
        </>
      )}
    </BaseLayout>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
    divider: {
      backgroundColor: '#f2f2f2',
    },
    card: {
      flex: 1,
      borderRight: '1px solid #f2f2f2',
    },
  }),
);
