import { axiosClient } from 'services/ApiClient';
import { AxiosResponse } from 'axios';
import { Promotion, IPromotionsFilters, IPromoMaket } from 'interfaces/Promotions';
import { ICarsFilters } from 'interfaces/Car';

const promotionsUrl = '/promos';

type CreatePromotion = {
  data: Promotion;
};

type UpdatePromotion = {
  data: Partial<Promotion>;
};

export const updateById = (id: string, fields: UpdatePromotion): Promise<AxiosResponse> =>
  axiosClient.put(`${promotionsUrl}/${id}`, fields);

export const loadAll = (filters: IPromotionsFilters): Promise<AxiosResponse> =>
  axiosClient.get(`${promotionsUrl}`, { params: filters });

export const loadById = (id: string): Promise<AxiosResponse> => axiosClient.get(`${promotionsUrl}/${id}`);
export const deleteById = (id: string): Promise<AxiosResponse> => axiosClient.delete(`${promotionsUrl}/${id}`);
export const create = (fields: CreatePromotion): Promise<AxiosResponse> => axiosClient.post(`${promotionsUrl}`, fields);

export const loadFreePromoCar = (filters: ICarsFilters): Promise<AxiosResponse> =>
  axiosClient.get(`${promotionsUrl}/review`, { params: filters });

export const deleteFile = (id: string): Promise<AxiosResponse> => axiosClient.delete(`/makets/${id}`);

export const reject = (promoId: string, carId: string): Promise<AxiosResponse> =>
  axiosClient.post(`${promotionsUrl}/${promoId}/reject`, { id: carId });

export const hold = (promoId: string, carId: string): Promise<AxiosResponse> =>
  axiosClient.post(`${promotionsUrl}/${promoId}/hold`, { id: carId });

export const busy = (promoId: string, carId: string): Promise<AxiosResponse> =>
  axiosClient.post(`${promotionsUrl}/${promoId}/busy`, { id: carId });

export const loadPromoCars = (promoId: string, filters: ICarsFilters): Promise<AxiosResponse> =>
  axiosClient.get(`${promotionsUrl}/${promoId}/cars`, { params: filters });

export const uploadMaketById = (promoId: string, data: IPromoMaket): Promise<AxiosResponse> =>
  axiosClient.post(`${promotionsUrl}/${promoId}/maket`, data);

export const start = (promoId: string): Promise<AxiosResponse> => axiosClient.post(`${promotionsUrl}/${promoId}/start`);

export const finish = (promoId: string): Promise<AxiosResponse> =>
  axiosClient.post(`${promotionsUrl}/${promoId}/finish`);

export const maps = (promoId: string): Promise<AxiosResponse> =>
  axiosClient.get(`${promotionsUrl}/${promoId}/mapcars`, { responseType: 'text' });
