import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import BaseLayout from 'components/baseLayout';
import DriverList from 'components/containers/drivers/listing/elements/list/DriverList';
import { useHistory } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import DriversHeader from 'components/containers/drivers/listing/elements/header';
import { Routes } from 'constants/Routes';
import DriversFilters from 'components/containers/drivers/listing/elements/filters';
import classnames from 'classnames';
import Modal from 'components/shared/modal';
import { DriverEditForm } from 'constants/Forms';
import { DriverEdit } from 'components/containers/drivers/item/elements/edit';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import {
  changeFilters,
  getCountDrivers,
  getDrivers,
  getDriversFilters,
  getDriversLoading,
} from 'store/reducers/drivers';
import { loadDrivers } from 'store/reducers/drivers/actions';
import { initialDriver } from 'store/reducers/drivers/types';
import Loader from 'components/shared/loader';
import { MAX_NUMBER_OF_DRIVER_PER_REQUEST } from 'interfaces/Driver';
import { getFilterOffset, getPageByOffset } from 'utils/common';
import { loadCarCitiesHelper, loadTaxStatusesHelper } from '../../../../store/reducers/helpers/actions';

const DriversContainer: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const drivers = useSelector(getDrivers);
  const isDriversLoading = useSelector(getDriversLoading);
  const filters = useSelector(getDriversFilters);
  const driversCount = useSelector(getCountDrivers);
  const pageCount = Math.ceil(driversCount / MAX_NUMBER_OF_DRIVER_PER_REQUEST);
  const [page, setPage] = useState<number>(getPageByOffset(filters.offset));

  const handleChangePage = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
      const offset = getFilterOffset(value, MAX_NUMBER_OF_DRIVER_PER_REQUEST);
      dispatch(changeFilters({ ...filters, offset }));
    },
    [dispatch, filters],
  );

  const [openEditModal, setOpenEditModal] = React.useState(false);
  const handleCloseEditModal = () => setOpenEditModal(false);
  const handleOpenEditModal = () => setOpenEditModal(true);

  useEffect(() => {
    dispatch(loadTaxStatusesHelper());
    dispatch(loadCarCitiesHelper());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadDrivers(filters));
  }, [dispatch, filters]);

  const onEditClick = useCallback(
    (id: string | undefined) => {
      history.push(`${Routes.drivers}/${id}`);
    },
    [history],
  );

  const onChangeFilters = useCallback(
    (filters) => {
      dispatch(changeFilters(filters));
    },
    [dispatch],
  );

  const onChangeSearch = useCallback(
    (searchValue) => {
      dispatch(changeFilters({ name: searchValue, offset: undefined }));
    },
    [dispatch],
  );

  return (
    <BaseLayout>
      <Grid>
        <Grid item container xs={12} className={classes.padding}>
          <DriversHeader onClickCreate={handleOpenEditModal} onChangeSearch={onChangeSearch} />
        </Grid>
        <Grid item container xs={12} className={classes.borderTop}>
          <Grid item lg={9} xs={12} className={classnames(classes.padding, classes.borderRight)}>
            {isDriversLoading ? <Loader show /> : <DriverList items={drivers} onEdit={onEditClick} />}
            {pageCount > 1 && (
              <Pagination
                className={classes.pagination}
                count={pageCount}
                shape="rounded"
                page={page}
                onChange={handleChangePage}
              />
            )}
          </Grid>
          <Grid item lg={3} xs={12}>
            <Grid item className={classnames(classes.padding, classes.title)}>
              <Typography variant="h2">Фильтр</Typography>
            </Grid>
            <Grid className={classes.padding}>
              <DriversFilters filters={filters} onChangeFilters={onChangeFilters} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        actionTitle="Сохранить"
        formId={DriverEditForm}
        modalTitle="Водитель"
      >
        <DriverEdit isCreate onSave={handleCloseEditModal} driver={initialDriver} />
      </Modal>
    </BaseLayout>
  );
};

export default DriversContainer;

const useStyles = makeStyles((_theme: Theme) => ({
  pagination: {
    float: 'right',
  },
  padding: {
    padding: 16,
  },
  borderTop: {
    borderTop: '1px solid #F2F2F2',
    flexWrap: 'wrap-reverse',
  },
  borderRight: {
    borderRight: '1px solid #F2F2F2',
  },
  title: {
    borderBottom: '1px solid #F2F2F2',
  },
}));
