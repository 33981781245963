import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { InputProps, TextField } from '@material-ui/core';
import { FieldErrors } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

export type TField = {
  title: string;
  maxWidth?: number;
  type?: string;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number | string;
};

export type TextFieldInputProps = {
  field: TField;
  errors?: FieldErrors;
  name: string;
  value?: any;
  InputProps?: InputProps;
};

const TextFieldInput: React.FC<TextFieldInputProps> = ({ value, field, errors, name, ...props }) => {
  const classes = useStyles();

  const { type, title, disabled, maxWidth, multiline } = field;

  return (
    <div className={classes.container}>
      <div style={{ maxWidth: maxWidth || 'none' }} className={classes.label}>
        {title}
      </div>
      <div className={classes.inputContainer}>
        <TextField
          {...props}
          {...field}
          InputProps={{ classes: { input: classes.inputStyle } }}
          value={value}
          multiline={multiline}
          disabled={disabled}
          type={type}
          className={classes.textField}
          variant="outlined"
        />
        {errors && <ErrorMessage as="span" className={classes.error} errors={errors} name={name} />}
      </div>
    </div>
  );
};

export default TextFieldInput;

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: 16,
    width: 'auto',
    maxWidth: 240,
    fontWeight: 300,
    lineHeight: '19px',
    color: theme.palette.primary.main,
    paddingBottom: 2,
  },
  inputContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
  },
  textField: {
    flexGrow: 1,
    height: 'auto',
    color: theme.palette.text.primary,
    borderRadius: 4,

    '& .MuiInputBase-multiline': {
      padding: '7px 0',
    },

    '& .MuiAutocomplete-inputRoot': {
      paddingTop: [0, '!important'],
      paddingBottom: [0, '!important'],
    },

    '& input, textarea': {
      padding: '0 7px !important',
      minHeight: 32,
    },
  },
  error: {
    color: theme.palette.secondary.light,
  },
  inputStyle: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      display: 'none',
    },
  },
}));
