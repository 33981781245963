import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { AttachIcon } from 'assets/images';
import SmallEntityAvatar from 'components/shared/avatar/SmallAvatar';

export type TPhotoInputProps = {
  title: string;
  label?: string;
  url?: string;
  name?: string;
  onDeleteImage: () => void;
  onChange: (e: React.SyntheticEvent<EventTarget>) => void;
};

const PhotoInput: React.FC<TPhotoInputProps> = ({ title, url, label, name, onChange, onDeleteImage }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <input type="file" id={name ?? 'thumbnailId'} hidden accept="image/*" onChange={onChange} />
      <div style={{ maxWidth: 240 }} className={classes.label}>
        {title}
      </div>
      {url ? (
        <SmallEntityAvatar url={url} onClick={onDeleteImage} />
      ) : (
        <div className={classes.textContainer}>
          <AttachIcon />
          <label htmlFor={name ?? 'thumbnailId'} className={classes.text}>
            {label || 'Добавить фото ТС'}
          </label>
        </div>
      )}
    </div>
  );
};

export default PhotoInput;

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 600,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: 16,
    width: '40%',
    maxWidth: 240,
    fontWeight: 300,
    lineHeight: '19px',
    color: theme.palette.primary.main,
    paddingBottom: 2,
  },
  text: {
    color: theme.palette.secondary.main,
    fontSize: 16,
    lineHeight: '19px',
    paddingLeft: theme.spacing(1),
    cursor: 'pointer',
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));
