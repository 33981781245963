import * as Yup from 'yup';
import VALIDATION_MESSAGES, { DEFAULT_MAX_TEXT_LENGTH } from 'constants/ValidationsMessage';

export const ValidationSchemaResetPassword = Yup.object().shape({
  oldPassword: Yup.string()
    .required(VALIDATION_MESSAGES.required)
    .nullable()
    .min(6, VALIDATION_MESSAGES.minLength(6))
    .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength()),
  newPassword: Yup.string()
    .required(VALIDATION_MESSAGES.required)
    .nullable()
    .min(6, VALIDATION_MESSAGES.minLength(6))
    .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength()),
  newPasswordConfirm: Yup.string()
    .required(VALIDATION_MESSAGES.required)
    .nullable()
    .min(6, VALIDATION_MESSAGES.minLength(6))
    .max(DEFAULT_MAX_TEXT_LENGTH, VALIDATION_MESSAGES.maxLength()),
});
