import React, { ComponentType, SVGProps } from 'react';
import cn from 'classnames';
import Typography from '@material-ui/core/Typography/Typography';
import { ActivePromo, ExpiredPromo } from 'assets/images';
import { makeStyles, createStyles } from '@material-ui/core';
import { PromoStatus } from 'store/reducers/promo/types';

const iconMap: Record<PromoStatus, ComponentType<SVGProps<SVGSVGElement>>> = {
  active: ActivePromo,
  expired: ExpiredPromo,
};

const titleMap: Record<PromoStatus, string> = {
  active: 'Активный',
  expired: 'Истек срок применения',
};

type Props = {
  className?: string;
  variant: PromoStatus;
};

export const PromoStatusBadge = ({ className, variant }: Props) => {
  const classes = useStyles();

  const isValidVariant = ['active', 'expired'].includes(variant);

  if (!isValidVariant) return null;

  const Icon = iconMap[variant];
  const title = titleMap[variant];

  return (
    <div
      className={cn(
        classes.root,
        {
          [classes.active]: variant === 'active',
          [classes.expired]: variant === 'expired',
        },
        className,
      )}
    >
      <Icon color="inherit" />
      <Typography component="span" variant="inherit">
        {title}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'inline-flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      fontWeight: 400,
      fontSize: 15,
      lineHeight: '18px',
    },
    active: {
      color: '#26cc7c',
    },
    expired: {
      color: '#cc2631',
    },
  }),
);
