export const getFilterOffset = (page: number, limit: number) => {
  const normalizedValue = page - 1;
  return normalizedValue ? normalizedValue * limit : undefined;
};

export const getPageByOffset = (offset: number | undefined) => {
  if (offset) {
    return offset / 10 + 1;
  }
  return 1;
};
