import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import CarCard from './carCard';
import { useHistory } from 'react-router-dom';
import { Car } from 'interfaces/Car';
import { Routes } from 'constants/Routes';

type TCarAdsProps = {
  cars: Car[];
};

const CarsListing: React.FC<TCarAdsProps> = ({ cars }) => {
  const classes = useStyles();
  const history = useHistory();
  const onClick = useCallback(
    (id: string) => {
      history.push(`${Routes.cars}/${id}`);
    },
    [history],
  );

  if (isEmpty(cars))
    return (
      <span className={classes.noAdsText}>
        Здесь будут отображаться транспортные средства, на которые записан водитель
      </span>
    );

  return (
    <Grid container>
      <Grid item container xs={12}>
        {cars.map((car: Car) => (
          <CarCard key={car.id} onClick={onClick} car={car} />
        ))}
      </Grid>
    </Grid>
  );
};

export default React.memo(CarsListing);

const useStyles = makeStyles((theme) => ({
  noAdsText: {
    fontWeight: 300,
    fontSize: 16,
    lineHeight: '19px',
    color: theme.palette.primary.dark,
  },
}));
