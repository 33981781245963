import { axiosClient } from 'services/ApiClient';
import { AxiosResponse } from 'axios';
import { Car, ICarsFilters, IPeriod } from 'interfaces/Car';

const carsUrl = '/cars';

type CreateCar = {
  data: Car;
};

type UpdateCar = {
  data: Partial<Car>;
};

export const updateById = (id: string, fields: UpdateCar): Promise<AxiosResponse> =>
  axiosClient.put(`${carsUrl}/${id}`, fields);

export const loadAllCars = (filters: ICarsFilters): Promise<AxiosResponse> =>
  axiosClient.get(`${carsUrl}`, { params: filters });

export const loadById = (id: string): Promise<AxiosResponse> => axiosClient.get(`${carsUrl}/${id}`);
export const deleteById = (id: string): Promise<AxiosResponse> => axiosClient.delete(`${carsUrl}/${id}`);
export const create = (fields: CreateCar): Promise<AxiosResponse> => axiosClient.post(`${carsUrl}`, fields);

export const loadCarPromoById = (id: string): Promise<AxiosResponse> => axiosClient.get(`${carsUrl}/${id}/promo`);

export const pause = (carId: string, period: IPeriod): Promise<AxiosResponse> =>
  axiosClient.post(`${carsUrl}/${carId}/pause`, period);

export const loadCarPhotoReportById = (id: string): Promise<AxiosResponse> =>
  axiosClient.get(`/photos/report/car/${id}`);

export const reject = (carId: string): Promise<AxiosResponse> => axiosClient.post(`${carsUrl}/${carId}/reject`);

export const restore = (carId: string): Promise<AxiosResponse> => axiosClient.post(`${carsUrl}/${carId}/restore`);
