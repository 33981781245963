import React, { useCallback } from 'react';
import { Card, CardHeader, CardContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import DeleteButton from 'components/shared/buttons/DeleteButton';
import Modal from 'components/shared/modal';
import CustomAutocompleteSelect from 'components/shared/select/CustomAutocompleteSelect';
import Status from 'components/shared/status/SimpleStatus';
import PhotoReport from 'components/containers/cars/item/elements/ads/Card/PhotoReport';
import { Promotion } from 'interfaces/Promotions';
import { useForm, Controller } from 'react-hook-form';
import { useAppDispatch } from 'store';
import { rejectCar } from 'store/reducers/promotions/actions';
import ConfirmActionModal from 'components/shared/modal/ConfirmActionModal';
import { getUserMe } from '../../../../../../../store/reducers/users';
import { useSelector } from 'react-redux';

type TCarAdsProps = {
  ad: Promotion;
  carId: string;
  photoReports: any;
};

const AdsCard: React.FC<TCarAdsProps> = ({ ad, carId, photoReports }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { handleSubmit, control, errors } = useForm<any>();
  const [expanded, setExpanded] = React.useState(false);
  const [openDoneModal, setOpenDoneModal] = React.useState(false);
  const handleExpandClick = () => setExpanded(!expanded);
  const userMe = useSelector(getUserMe);

  const [openPhotoReportModal, setOpenPhotoReportModal] = React.useState(false);
  const handleClosePhotoReportModal = () => setOpenPhotoReportModal(false);
  const handleOpenPhotoReportModal = () => setOpenPhotoReportModal(true);
  const handleCloseDoneModal = () => setOpenDoneModal(false);
  const handleOpenDoneModal = () => setOpenDoneModal(true);

  const options = [
    {
      title: 'asdas',
      value: 'as',
    },
    {
      title: 'asdas',
      value: 'as',
    },
  ];

  const onClickDeleteAd = useCallback(() => {
    dispatch(rejectCar({ carId: carId, promoId: ad.id }));
    console.log('delete up on ads');
  }, [ad.id, carId, dispatch]);

  const onClickEditPhotoReport = useCallback((data) => {
    //dispatch(deleteCar(12));
    console.log(data);
    console.log('edit photo report ads');
  }, []);

  return (
    <>
      <Card classes={{ root: classes.root }}>
        <CardHeader
          title={
            <>
              <div className={classes.title}>{ad.name}</div>
              <Status status={ad.status} />
            </>
          }
          classes={{ title: classes.titleContainer }}
          action={
            <IconButton
              className={classnames(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              disabled={photoReports.length == 0}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          }
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {photoReports.map((report: any) => (
              <PhotoReport report={report} key={report.id} />
            ))}
          </CardContent>
        </Collapse>
        {userMe?.admin && (
          <CardContent>
            <Grid container justify="flex-end" spacing={1}>
              <Grid item>
                <DeleteButton onClick={handleOpenDoneModal} title="Снять с рекламной кампании" />
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
      <Modal
        open={openPhotoReportModal}
        onClose={handleClosePhotoReportModal}
        actionTitle="Записать на рекламу"
        modalTitle="Настройка фотоотчета"
        onClick={handleSubmit(onClickEditPhotoReport)}
      >
        <Grid container justify="center">
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="time"
                  control={control}
                  render={(props) => (
                    <CustomAutocompleteSelect
                      value={props.value}
                      options={options}
                      title="Периодичность фотоотчета"
                      onChange={props.onChange}
                      errors={errors}
                      name="time"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="period"
                  control={control}
                  render={(props) => (
                    <CustomAutocompleteSelect
                      value={props.value}
                      options={options}
                      title="Срок рекламной кампании"
                      onChange={props.onChange}
                      errors={errors}
                      name="period"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Modal>
      <ConfirmActionModal
        openModalStatus={openDoneModal}
        buttonClose={handleCloseDoneModal}
        buttonDelete={onClickDeleteAd}
        actionText="Отменить запись"
        text="запись на рекламную кампанию"
      />
    </>
  );
};

export default React.memo(AdsCard);

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '19px',
    paddingRight: 16,
  },
  root: {
    border: '1px solid #F2F2F2',
    borderRadius: 8,
    boxShadow: 'none',
    marginBottom: 8,
    '&:hover': {
      boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.1)',
    },
  },
}));
