import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  finishPromo,
  getMaps,
  holdCar,
  loadPromotionById,
  loadPromotionCars,
  loadPromotionCarsForAdd,
  loadPromotions,
  startPromo,
  updatePromotionById,
} from './actions';
import { initialPromotion, initialState } from './types';
import { TState } from 'store/index';
import { IPromotionsFilters } from 'interfaces/Promotions';
import { IPhoto } from 'interfaces/Media';
import { ICarsFilters } from 'interfaces/Car';

const promotionsSlice = createSlice({
  name: 'promotions',
  initialState,
  reducers: {
    changeFilters(state, action: PayloadAction<IPromotionsFilters>) {
      state.listing.filters = { ...state.listing.filters, ...action.payload };
    },
    UploadImage(state, action: PayloadAction<IPhoto>) {
      state.item.entity.files = [...state.item.entity.files, { ...action.payload }];
    },
    changeCarsFilters(state, action: PayloadAction<ICarsFilters>) {
      state.cars.filters = { ...state.cars.filters, ...action.payload };
    },
    changeReviewCarsFilters(state, action: PayloadAction<ICarsFilters>) {
      state.reviewCarsFilters = { ...state.reviewCarsFilters, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadPromotions.pending, (state) => {
      state.listing.entities = [];
      state.listing.loading = true;
      state.listing.loaded = false;
    });
    builder.addCase(loadPromotions.fulfilled, (state, { payload }) => {
      state.listing.entities = payload.data[0];
      state.listing.count = payload.data[1];
      state.listing.loading = false;
      state.listing.loaded = true;
    });
    builder.addCase(loadPromotions.rejected, (state, action) => {
      state.listing.loading = false;
      state.listing.error = action.error.message;
    });

    builder.addCase(loadPromotionById.pending, (state) => {
      state.item.entity = initialPromotion;
      state.item.loading = true;
      state.item.loaded = false;
    });
    builder.addCase(loadPromotionById.fulfilled, (state, { payload }) => {
      state.item.entity = { ...state.item.entity, ...payload.data };
      state.item.loading = false;
      state.item.loaded = true;
    });
    builder.addCase(loadPromotionById.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error.message;
    });

    builder.addCase(updatePromotionById.pending, (state) => {
      state.item.loading = true;
      state.item.loaded = false;
    });
    builder.addCase(updatePromotionById.fulfilled, (state, { payload }) => {
      state.item.entity = { ...state.item.entity, ...payload.data, files: [] };
      state.item.loading = false;
      state.item.loaded = true;
    });
    builder.addCase(updatePromotionById.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error.message;
    });

    builder.addCase(loadPromotionCarsForAdd.pending, (state) => {
      state.reviewCars.cars = [];
      state.reviewCars.loading = true;
      state.reviewCars.loaded = false;
    });
    builder.addCase(loadPromotionCarsForAdd.fulfilled, (state, { payload }) => {
      state.reviewCars.cars = payload[0];
      state.reviewCars.count = payload[1];
      state.reviewCars.loading = false;
      state.reviewCars.loaded = true;
    });
    builder.addCase(loadPromotionCarsForAdd.rejected, (state, action) => {
      state.reviewCars.loading = false;
      state.reviewCars.error = action.error.message;
    });

    builder.addCase(loadPromotionCars.pending, (state) => {
      state.cars.cars = [];
      state.cars.loading = true;
      state.cars.loaded = false;
    });
    builder.addCase(loadPromotionCars.fulfilled, (state, { payload }) => {
      state.cars.cars = payload[0];
      state.cars.count = payload[1];
      state.cars.loading = false;
      state.cars.loaded = true;
    });
    builder.addCase(loadPromotionCars.rejected, (state, action) => {
      state.cars.loading = false;
      state.cars.error = action.error.message;
    });

    builder.addCase(holdCar.pending, (state) => {
      state.cars.loading = true;
      state.cars.loaded = false;
    });
    builder.addCase(holdCar.fulfilled, (state, { payload }) => {
      state.cars.cars = [...state.cars.cars, payload];
      state.cars.count = state.cars.count + 1;
      state.cars.loading = false;
      state.cars.loaded = true;
    });
    builder.addCase(holdCar.rejected, (state, action) => {
      state.cars.loading = false;
      state.cars.error = action.error.message;
    });

    builder.addCase(startPromo.pending, (state) => {
      state.item.loading = true;
      state.item.loaded = false;
    });
    builder.addCase(startPromo.fulfilled, (state, { payload }) => {
      state.item.entity = { ...state.item.entity, ...payload };
      state.item.loading = false;
      state.item.loaded = true;
    });
    builder.addCase(startPromo.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error.message;
    });

    builder.addCase(finishPromo.pending, (state) => {
      state.item.loading = true;
      state.item.loaded = false;
    });
    builder.addCase(finishPromo.fulfilled, (state, { payload }) => {
      state.item.entity = { ...state.item.entity, ...payload };
      state.item.loading = false;
      state.item.loaded = true;
    });
    builder.addCase(finishPromo.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error.message;
    });

    builder.addCase(getMaps.pending, (state) => {
      state.maps.loading = true;
      state.maps.loaded = false;
    });
    builder.addCase(getMaps.fulfilled, (state, { payload }) => {
      state.maps.maps = payload;
      state.maps.loading = false;
      state.maps.loaded = true;
    });
    builder.addCase(getMaps.rejected, (state, action) => {
      state.item.loading = false;
      state.maps.error = action.error.message;
    });
  },
});

export const { changeFilters, changeCarsFilters, UploadImage, changeReviewCarsFilters } = promotionsSlice.actions;
export const getPromotions = (state: TState) => state.promotions.listing.entities;
export const getCountPromotions = (state: TState) => state.promotions.listing.count;
export const getPromotionsLoading = (state: TState) => state.promotions.listing.loading;
export const getPromotionsFilters = (state: TState) => state.promotions.listing.filters;
export const getPromotion = (state: TState) => state.promotions.item.entity;
export const getPromotionLoading = (state: TState) => state.promotions.item.loading;
export const getPromotionCars = (state: TState) => state.promotions.cars.cars;
export const getPromotionCarsLoading = (state: TState) => state.promotions.cars.loading;
export const getPromotionCarsFilter = (state: TState) => state.promotions.cars.filters;
export const getCarsCount = (state: TState) => state.promotions.cars.count;
export const getReviewCars = (state: TState) => state.promotions.reviewCars.cars;
export const getReviewCarsCount = (state: TState) => state.promotions.reviewCars.count;
export const getReviewCarsFilters = (state: TState) => state.promotions.reviewCarsFilters;
export const getReviewCarsLoading = (state: TState) => state.promotions.reviewCars.loading;
export const getPromoMaps = (state: TState) => state.promotions.maps.maps;
export const getPromoMapsLoading = (state: TState) => state.promotions.maps.loading;
export default promotionsSlice.reducer;
