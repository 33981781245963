import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { store, persistor } from 'store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { Routes } from 'constants/Routes';
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from 'services/Snackbar';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import theme from 'services/theme';
import NoMatchContainer from 'components/containers/no-match';
import { Authorization, CantAuthorize, NewPassword, Registration } from 'components/containers/authorization';
import PrivateRoute from 'components/shared/privateRoute';
import CarsContainer from 'components/containers/cars/listing';
import CarContainer from 'components/containers/cars/item';
import AdCompaniesContainer from 'components/containers/adCompanies/listing';
import DriversContainer from 'components/containers/drivers/listing';
import DriverContainer from 'components/containers/drivers/item';
import AdCompanyContainer from 'components/containers/adCompanies/item';
import AdvertisersContainer from 'components/containers/advertisers/listing';
import { UserProfile } from 'components/containers/profile';
import AdvertiserContainer from 'components/containers/advertisers/item';
import LayoutAdCompanies from './components/landing/ads';
import LandingDrivers from './components/landing/drivers';
import TermsAndConditions from 'components/containers/termsAndConditions';
import ConsentToDataProcessing from 'components/containers/consentToDataProcessing';
import { PromoListPage } from 'components/containers/promo';
import { PromoDetails } from 'components/containers/promo/details';

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider locale={ruLocale} utils={DateFnsUtils}>
          <SnackbarProvider
            preventDuplicate
            maxSnack={3}
            autoHideDuration={2000}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <>
              <SnackbarUtilsConfigurator />
              <CssBaseline />
              <BrowserRouter>
                <Switch>
                  <Route path={Routes.auth} exact>
                    <Authorization />
                  </Route>

                  <Route path={Routes.layoutDrivers} exact>
                    <LandingDrivers />
                  </Route>

                  <Route path={Routes.layoutAdCompanies} exact>
                    <LayoutAdCompanies />
                  </Route>

                  <Route path={Routes.signup} exact>
                    <Registration />
                  </Route>

                  <Route path={Routes.restore} exact>
                    <CantAuthorize />
                  </Route>

                  <Route path={Routes.reset} exact>
                    <NewPassword />
                  </Route>

                  <Route path={Routes.termsAndConditions} exact>
                    <TermsAndConditions />
                  </Route>

                  <Route path={Routes.consentToDataProcessing} exact>
                    <ConsentToDataProcessing />
                  </Route>

                  <PrivateRoute path={Routes.cars} exact>
                    <CarsContainer />
                  </PrivateRoute>

                  <PrivateRoute path={Routes.car} exact>
                    <CarContainer />
                  </PrivateRoute>

                  <PrivateRoute path={Routes.drivers} exact>
                    <DriversContainer />
                  </PrivateRoute>

                  <PrivateRoute path={Routes.driver} exact>
                    <DriverContainer />
                  </PrivateRoute>

                  <PrivateRoute path={Routes.adCompanies} exact>
                    <AdCompaniesContainer />
                  </PrivateRoute>

                  <PrivateRoute path={Routes.adCompany} exact>
                    <AdCompanyContainer />
                  </PrivateRoute>

                  <PrivateRoute path={Routes.advertisers} exact>
                    <AdvertisersContainer />
                  </PrivateRoute>

                  <PrivateRoute path={Routes.advertiser} exact>
                    <AdvertiserContainer />
                  </PrivateRoute>

                  <PrivateRoute path={Routes.profile} exact>
                    <UserProfile />
                  </PrivateRoute>

                  <PrivateRoute path={Routes.promos} exact>
                    <PromoListPage />
                  </PrivateRoute>

                  <PrivateRoute path={Routes.promo} exact>
                    <PromoDetails />
                  </PrivateRoute>

                  <Redirect from="/" to={Routes.auth} exact />

                  <PrivateRoute path="*" exact>
                    <NoMatchContainer />
                  </PrivateRoute>
                </Switch>
              </BrowserRouter>
            </>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </PersistGate>
  </Provider>
);

export default App;
