import React from 'react';
import Typography from '@material-ui/core/Typography';
import Modal from 'components/shared/modal';
import { createStyles, makeStyles } from '@material-ui/core';

type Props = {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
};

export const PromoDeletetModal = ({ open, onClose, onDelete }: Props) => {
  const classes = useStyles();

  return (
    <Modal
      actionClassName={classes.action}
      open={open}
      modalTitle="Промо"
      actionTitle="Удалить"
      onClose={onClose}
      onClick={onDelete}
    >
      <Typography className={classes.text}>Вы уверены, что хотите удалить промо?</Typography>
      <Typography className={classes.text}>Отменить это действие будет невозможно!</Typography>
    </Modal>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    text: {
      textAlign: 'center',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '18px',
      color: '#000',
    },
    action: {
      border: `1px solid ${theme.palette.secondary.light}`,
      color: theme.palette.secondary.light,

      '&:hover': {
        border: `1px solid ${theme.palette.secondary.light}`,
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.background.default,
      },
    },
  }),
);
