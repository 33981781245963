export enum ActionsTypes {
  LOGIN = 'LOGIN',
  RESTORE = 'RESTORE',
  SIGNUP = 'SIGNUP',
  RESET_PASSWORD = 'RESET_PASSWORD',
  LOGOUT = 'LOGOUT',
}

export type TResetPassword = {
  resetCode: string | null;
  password: string;
};

export type TCredential = {
  email: string;
  password: string;
};

export interface AuthState {
  error: string | null | undefined;
  token: string | null;
  loading: boolean;
  loaded: boolean;
}

export const initialState: AuthState = {
  token: null,
  loading: false,
  loaded: false,
  error: null,
};
