import { DataResponse } from 'interfaces/Response';
import { axiosClient } from 'services/ApiClient';
import { Promo } from './types';

export const getPromoListEndpoint = (offset = 0, limit = 10) => {
  return axiosClient.get<DataResponse<[Promo[], number]>>('/promocode', { params: { offset, limit } });
};

export const getPromoEndpoint = (id: string) => {
  return axiosClient.get<DataResponse<Promo>>(`/promocode/${id}`);
};

export const createPromoEndpoint = (data: Partial<Promo>) => {
  return axiosClient.post<DataResponse<Promo>>('/promocode', data);
};

export const editPromoEndpoint = (data: Partial<Promo>) => {
  return axiosClient.put<DataResponse<Promo>>(`/promocode/${data.id}`, data);
};

export const deletePromoEndpoint = (id: string) => {
  return axiosClient.delete<DataResponse<Promo>>(`/promocode/${id}`);
};
