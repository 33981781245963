import { IPhoto } from 'interfaces/Media';

export type PromoStatus = 'active' | 'expired';

export interface PromoState {
  list: Promo[];
  item: Promo | null;
  loading: boolean;
  error: string;
  count: number;
}

export enum ActionsTypes {
  GET_PROMO_LIST = 'GET_PROMO_LIST',
  GET_PROMO = 'GET_PROMO',
  CREATE_PROMO = 'CREATE_PROMO',
  EDIT_PROMO = 'EDIT_PROMO',
  DELETE_PROMO = 'DELETE_PROMO',
}

export interface Promo {
  id: string;
  annotation: string;
  description: string;
  finish: string;
  name: string;
  photo?: IPhoto | null;
  photoId?: string | null;
  promoCode: string;
  QRCode: string;
  refUrl: string;
  status: PromoStatus;
  createdAt: string;
  deletedAt?: string | null;
  updatedAt: string;
}
