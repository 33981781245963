import React from 'react';
import cn from 'classnames';
import { Grid, Typography, createStyles, makeStyles } from '@material-ui/core';
import { format } from 'date-fns';
import { PromoStatusBadge } from '../status/status';
import { PromoStatus } from 'store/reducers/promo/types';

const formatExpirationDate = (date: string) => {
  return format(new Date(date), 'dd.MM.yyyy');
};

type Props = {
  className?: string;
  status: PromoStatus;
  annotation: string;
  expirationDate: string;
  name: string;
  onClick?: () => void;
};

export const PromoListItem = ({ className, status, annotation, expirationDate, name, onClick }: Props) => {
  const classes = useStyles();

  return (
    <Grid className={cn(classes.root, className)} container item onClick={onClick}>
      <Grid className={classes.column} item sm={4}>
        <Typography className={classes.text} variant="inherit">
          {name}
        </Typography>
      </Grid>
      <Grid className={classes.column} item sm={4}>
        <Typography className={classes.text} variant="inherit">
          {annotation}
        </Typography>
      </Grid>
      <Grid className={classes.column} item sm={2}>
        <PromoStatusBadge variant={status} />
      </Grid>
      <Grid className={classes.column} item sm={2}>
        <Typography className={classes.text} variant="inherit">
          {formatExpirationDate(expirationDate)}
        </Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      borderRadius: 8,
      border: '1px solid #f2f2f2',
      cursor: 'pointer',

      '&:hover': {
        boxShadow: '0px 0px 16px rgb(0 0 0 / 10%)',
        transition: 'box-shadow 0.3s ease-in-out',
      },
    },
    column: {
      padding: theme.spacing(1),
      fontWeight: 400,
      fontSize: 15,
      lineHeight: '18px',
      color: '#000',
    },
    text: {
      wordBreak: 'break-all',
    },
  }),
);
