import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import CreateButton from 'components/shared/buttons/CreateButton';
import Search from 'components/shared/inputs/SearchInput';

type TProps = {
  onClickCreate: () => void;
  onChangeSearch: (value: string) => void;
};

const DriversHeader: FC<TProps> = ({ onClickCreate, onChangeSearch }) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <div className={classes.title}>
        <Typography variant="h1">Водители</Typography>
      </div>
      <div className={classes.filters}>
        <Search onChangeSearch={onChangeSearch} />
        <CreateButton onClick={onClickCreate} />
      </div>
    </div>
  );
};

export default React.memo(DriversHeader);

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    flexWrap: 'wrap',
  },
  title: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
  },
  link: {
    padding: 0,
    position: 'absolute',
    top: 30,
    width: 'max-content',
  },
  filters: {
    display: 'flex',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
}));
