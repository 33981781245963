import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import TextFieldInput from 'components/shared/inputs/TextFieldInput';
import { useAppDispatch } from 'store';
import { AdvertiserEditForm } from 'constants/Forms';
import { Sponsor } from 'interfaces/Sponsors';
import { createSponsor, updateSponsorById } from 'store/reducers/sponsors/actions';
import { Routes } from 'constants/Routes';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { ValidationSchema } from 'components/containers/advertisers/item/elements/edit/ValidationSchema';
import InputMask, { Props as InputMaskProps } from 'react-input-mask';
import CustomAutocompleteSelect from 'components/shared/select/CustomAutocompleteSelect';
import { useSelector } from 'react-redux';
import { getCities } from 'store/reducers/helpers';
import { updateMeSponsor } from 'store/reducers/users/actions';

type TAdvertiserEditProps = {
  advertiser: Sponsor;
  onSave: () => void;
  isCreate?: boolean;
  isSponsor?: boolean;
};

const AdvertiserEdit: React.FC<TAdvertiserEditProps> = ({ advertiser, onSave, isCreate, isSponsor }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { handleSubmit, control, errors } = useForm<Sponsor>({
    defaultValues: advertiser,
    resolver: yupResolver(ValidationSchema),
  });

  const helperCities = useSelector(getCities);
  const getCityValue = (city: string) => helperCities.entities.find((item) => item.displayName === city);

  const onSubmit = useCallback(
    async (data) => {
      data.city = data.city.displayName || advertiser.city;
      if (isCreate) {
        await dispatch(createSponsor(data)).then((result) => {
          if (result.payload.data?.id) {
            history.push(`${Routes.advertisers}/${result.payload.data.id}`);
          }
        });
      } else if (isSponsor) {
        await dispatch(updateMeSponsor(data));
        onSave();
      } else {
        await dispatch(updateSponsorById({ ...data, id: advertiser.id }));
        onSave();
      }
    },
    [advertiser.city, isSponsor, advertiser.id, history, onSave, isCreate, dispatch],
  );

  return (
    <form id={AdvertiserEditForm} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            as={TextFieldInput}
            field={{ title: 'Организация:' }}
            name="name"
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="city"
            control={control}
            render={(props) => (
              <CustomAutocompleteSelect
                name="city"
                value={typeof props.value === 'object' ? props.value : getCityValue(props.value)}
                options={helperCities.entities}
                title="Город:"
                errors={errors}
                onChange={props.onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller as={TextFieldInput} field={{ title: 'E-mail:' }} name="eMail" errors={errors} control={control} />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="phone"
            errors={errors}
            control={control}
            render={(props) => (
              <InputMask mask="+7(999)999 9999" value={props.value} onChange={props.onChange}>
                {(params: InputMaskProps) => (
                  <TextFieldInput name="phone" errors={errors} field={{ title: 'Телефон:' }} {...params} />
                )}
              </InputMask>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="phoneExt"
            errors={errors}
            control={control}
            render={(props) => (
              <InputMask mask="+7(999)999 9999" value={props.value} onChange={props.onChange}>
                {(params: InputMaskProps) => (
                  <TextFieldInput
                    name="phone"
                    errors={errors}
                    field={{ title: 'Дополнительный Телефон:' }}
                    {...params}
                  />
                )}
              </InputMask>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={TextFieldInput}
            field={{ title: 'ИНН:', type: 'number' }}
            name="inn"
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={TextFieldInput}
            field={{ title: 'КПП:', type: 'number' }}
            name="kpp"
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={TextFieldInput}
            field={{ title: 'БИК:', type: 'number' }}
            name="bik"
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={TextFieldInput}
            field={{ title: 'Р/С:', type: 'number' }}
            name="account"
            errors={errors}
            control={control}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default React.memo(AdvertiserEdit);
