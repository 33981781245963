import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import DeleteButton from 'components/shared/buttons/DeleteButton';
import { Back } from 'assets/images';
import CreateButton from 'components/shared/buttons/CreateButton';
import { PromotionStatus } from 'interfaces/Promotions';
import AcceptButton from '../../../../../shared/buttons/AcceptButton';
import { useHistory } from 'react-router-dom';

type TProps = {
  onClickDelete: () => void;
  onClickDone: () => void;
  onClickStart: () => void;
  title: string;
  status: PromotionStatus | undefined;
};

const AdCompanyHeader: FC<TProps> = ({ title, onClickDelete, onClickDone, onClickStart, status }) => {
  const classes = useStyles();
  const history = useHistory();

  const onClickBack = () => {
    history.goBack();
  };

  return (
    <div className={classes.header}>
      <div className={classes.titleContainer} onClick={onClickBack}>
        <Back />
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
      </div>
      <div className={classes.buttons}>
        <DeleteButton onClick={onClickDelete} />
        {status === PromotionStatus.new && <AcceptButton title="Одобрить" onClick={onClickStart} />}
        {status === PromotionStatus.work && <CreateButton title="Завершить" onClick={onClickDone} />}
      </div>
    </div>
  );
};

export default React.memo(AdCompanyHeader);

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    flexWrap: 'wrap',
  },
  titleContainer: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
  },
  title: {
    paddingLeft: 10,
  },
  buttons: {
    display: 'flex',
    '& > :nth-child(1)': {
      marginRight: theme.spacing(2),
    },
  },
}));
