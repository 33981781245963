import React from 'react';
import { Car, CarStatus } from 'interfaces/Car';
import { Grid } from '@material-ui/core';
import DeleteButton from 'components/shared/buttons/DeleteButton';
import Status from 'components/shared/status';
import FieldView from 'components/shared/inputs/FieldView';
import Avatar from 'components/shared/avatar';
import CreateButton from 'components/shared/buttons/CreateButton';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { format, parseISO } from 'date-fns';

type TCarViewProps = {
  car: Car;
  onClickDecline: () => void;
  onClickReview: () => void;
  onClickPauseStop: () => void;
  onClickPauseStart: () => void;
};

const CarView: React.FC<TCarViewProps> = ({
  car,
  onClickDecline,
  onClickReview,
  onClickPauseStop,
  onClickPauseStart,
}) => {
  const classes = useStyles();

  const getCrashValue = (value: boolean) => {
    return value ? 'Да' : 'Нет';
  };

  return (
    <Grid container>
      <Grid item xs={6}>
        <div style={{ paddingBottom: 16 }}>
          <Avatar url={car.avatar} />
        </div>
        <FieldView title="Марка:" value={car.mark} />
        <FieldView title="Модель:" value={car.model} />
        <FieldView title="Класс:" value={car.autoClass} />
        <FieldView title="Год выпуска:" value={car.year} />
        <FieldView title="Гос. номер:" value={car.registration} />
        <FieldView title="VIN-номер:" value={car.vin} />
        <FieldView title="Участвовал в ДТП:" value={getCrashValue(car.crash)} />
        <FieldView title="Город:" value={car.city} />
        <FieldView title="Цвет:" value={car.color} />
        <FieldView title="Тип кузова:" value={car.bodyType} />
      </Grid>
      <Grid item container xs={6} justify="flex-start" alignContent="flex-end" direction="column">
        <Status status={car.status} />
        {car.status === CarStatus.Pause && (
          <span className={classes.time}>
            {format(parseISO(car.pause?.on), 'dd.MM.yyyy')} - {format(parseISO(car.pause?.off), 'dd.MM.yyyy')}
          </span>
        )}
      </Grid>
      <Grid item container xs={12} justify="flex-end">
        {car.status === CarStatus.Review && <DeleteButton onClick={onClickDecline} title="Отклонить ТС" />}
        {car.status === CarStatus.Hold && <DeleteButton onClick={onClickDecline} title="Отклонить ТС" />}
        {car.status === CarStatus.Reject && <CreateButton onClick={onClickReview} title="Поставить на рассмотрение" />}
        {car.status === CarStatus.Pause && <CreateButton onClick={onClickPauseStop} title="Выйти из паузы" />}
        {car.status === CarStatus.Busy && <CreateButton onClick={onClickPauseStart} title="Поставить на паузу" />}
      </Grid>
    </Grid>
  );
};

export default React.memo(CarView);

const useStyles = makeStyles((theme: Theme) => ({
  time: {
    fontWeight: 300,
    fontSize: 12,
    lineHeight: '14px',
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(1),
  },
}));
