import React from 'react';
import { Grid } from '@material-ui/core';
import FieldView from 'components/shared/inputs/FieldView';
import { Sponsor } from 'interfaces/Sponsors';

type TAdvertiserViewProps = {
  advertiser: Sponsor;
};

const AdvertiserView: React.FC<TAdvertiserViewProps> = ({ advertiser }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <FieldView title="Организация:" value={advertiser?.name} />
        <FieldView title="Город:" value={advertiser?.city} />
        <FieldView title="E-mail:" value={advertiser?.eMail} />
        <FieldView title="Телефон:" value={advertiser?.phone} />
        <FieldView title="Дополнительный телефон:" value={advertiser?.phoneExt} />
        <FieldView title="ИНН:" value={advertiser?.inn} />
        <FieldView title="КПП:" value={advertiser?.kpp} />
        <FieldView title="БИК:" value={advertiser?.bik} />
        <FieldView title="Р/С:" value={advertiser?.account} />
      </Grid>
    </Grid>
  );
};

export default React.memo(AdvertiserView);
