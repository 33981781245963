import { User } from 'interfaces/User';
import { initialSponsor } from '../sponsors/types';

export const initialUser = {
  name: '',
  email: '',
  password: '',
  admin: false,
  sponsor: initialSponsor,
};

export const initialUserMe = {
  success: false,
  apiVersion: '',
  timestamp: '',
  requestId: '',
  statusCode: 404,
  data: '',
};

export enum ActionsTypes {
  LOAD_ALL_USERS = 'LOAD_ALL_USERS',
  LOAD_ME = 'LOAD_ME',
  UPDATE_ME = 'UPDATE_ME',
  DELETE_ME = 'DELETE_ME',
  FREEZE_USER = 'FREEZE_USER',
  LOAD_USER_AUTH = 'LOAD_USER_AUTH',
  NEW_PROMO = 'NEW_PROMO',
  UPDATE_ME_SPONSOR = 'UPDATE_ME_SPONSOR',
  UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD',
}

export interface UserMe {
  success?: boolean;
  apiVersion: string;
  timestamp: string;
  requestId: string;
  statusCode: number;
  data: any;
}

export interface UsersState {
  listing: {
    error: string | null | undefined;
    entities: User[];
    loading: boolean;
    loaded: boolean;
  };
  me: {
    error: string | null | undefined;
    entity: User;
    loading: boolean;
    loaded: boolean;
  };
  userMe: {
    error: string | null | undefined;
    entity: UserMe;
    loading: boolean;
    loaded: boolean;
  };
}

export const initialState: UsersState = {
  listing: {
    entities: [],
    loading: false,
    loaded: false,
    error: null,
  },
  me: {
    entity: initialUser,
    loading: false,
    loaded: false,
    error: null,
  },
  userMe: {
    entity: initialUserMe,
    loading: false,
    loaded: false,
    error: null,
  },
};
