import { axiosClient } from 'services/ApiClient';
import { AxiosResponse } from 'axios';
import { Promotion } from 'interfaces/Promotions';
import { Sponsor } from 'interfaces/Sponsors';
import { PasswordChange } from '../../../interfaces/User';

type CreatePromotion = {
  data: Promotion;
};

type UpdateSponsor = {
  data: Sponsor;
};

const users = '/users';

export const updateById = (id: string, fields: any): Promise<AxiosResponse> =>
  axiosClient.put(`${users}/${id}`, fields);

export const loadAllUsers = (): Promise<AxiosResponse> => axiosClient.get(`${users}`);

export const loadById = (id: string): Promise<AxiosResponse> => axiosClient.get(`${users}/${id}`);
export const deleteById = (id: string): Promise<AxiosResponse> => axiosClient.delete(`${users}/${id}`);

export const freeze = (id: string): Promise<AxiosResponse> => axiosClient.post(`${users}/${id}/freeze`);

export const loadUserAuth = (): Promise<AxiosResponse> => axiosClient.get(`${users}/me`);

export const newPromo = (fields: CreatePromotion): Promise<AxiosResponse> =>
  axiosClient.post(`${users}/new/promo`, fields);

export const updateSponsor = (fields: UpdateSponsor): Promise<AxiosResponse> =>
  axiosClient.put(`${users}/sponsor`, fields);

export const updatePassword = (fields: PasswordChange): Promise<AxiosResponse> =>
  axiosClient.post(`${users}/me/password`, fields);
