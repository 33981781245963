import { createSlice } from '@reduxjs/toolkit';
import {
  loadCarCitiesHelper,
  loadCarMarksHelper,
  loadCarModelsHelper,
  loadCarColorsHelper,
  loadCarBodiesHelper,
  loadCarClassesHelper,
  loadLandingCitiesHelper,
  loadTaxStatusesHelper,
} from './actions';
import { initialState } from './types';
import { TState } from 'store/index';

const helpersSlice = createSlice({
  name: 'helpers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadCarCitiesHelper.pending, (state) => {
      state.helperCitiesItem.entities = [];
      state.helperCitiesItem.loading = true;
      state.helperCitiesItem.loaded = false;
    });
    builder.addCase(loadCarCitiesHelper.fulfilled, (state, { payload }) => {
      state.helperCitiesItem.entities = payload;
      state.helperCitiesItem.loading = false;
      state.helperCitiesItem.loaded = true;
    });
    builder.addCase(loadCarCitiesHelper.rejected, (state, action) => {
      state.helperCitiesItem.loading = false;
      state.helperCitiesItem.error = action.error.message;
    });

    builder.addCase(loadCarMarksHelper.pending, (state) => {
      state.helperMarksItem.entities = [];
      state.helperMarksItem.loading = true;
      state.helperMarksItem.loaded = false;
    });
    builder.addCase(loadCarMarksHelper.fulfilled, (state, { payload }) => {
      state.helperMarksItem.entities = payload;
      state.helperMarksItem.loading = false;
      state.helperMarksItem.loaded = true;
    });
    builder.addCase(loadCarMarksHelper.rejected, (state, action) => {
      state.helperMarksItem.loading = false;
      state.helperMarksItem.error = action.error.message;
    });

    builder.addCase(loadCarModelsHelper.pending, (state) => {
      state.helperModelsItem.entities = [];
      state.helperModelsItem.loading = true;
      state.helperModelsItem.loaded = false;
    });
    builder.addCase(loadCarModelsHelper.fulfilled, (state, { payload }) => {
      state.helperModelsItem.entities = payload;
      state.helperModelsItem.loading = false;
      state.helperModelsItem.loaded = true;
    });
    builder.addCase(loadCarModelsHelper.rejected, (state, action) => {
      state.helperModelsItem.loading = false;
      state.helperModelsItem.error = action.error.message;
    });

    builder.addCase(loadCarColorsHelper.pending, (state) => {
      state.helperColorsItem.entities = [];
      state.helperColorsItem.loading = true;
      state.helperColorsItem.loaded = false;
    });
    builder.addCase(loadCarColorsHelper.fulfilled, (state, { payload }) => {
      state.helperColorsItem.entities = payload;
      state.helperColorsItem.loading = false;
      state.helperColorsItem.loaded = true;
    });
    builder.addCase(loadCarColorsHelper.rejected, (state, action) => {
      state.helperColorsItem.loading = false;
      state.helperColorsItem.error = action.error.message;
    });

    builder.addCase(loadCarBodiesHelper.pending, (state) => {
      state.helperBodiesItem.entities = [];
      state.helperBodiesItem.loading = true;
      state.helperBodiesItem.loaded = false;
    });
    builder.addCase(loadCarBodiesHelper.fulfilled, (state, { payload }) => {
      state.helperBodiesItem.entities = payload;
      state.helperBodiesItem.loading = false;
      state.helperBodiesItem.loaded = true;
    });
    builder.addCase(loadCarBodiesHelper.rejected, (state, action) => {
      state.helperBodiesItem.loading = false;
      state.helperBodiesItem.error = action.error.message;
    });

    builder.addCase(loadCarClassesHelper.pending, (state) => {
      state.helperClassesItem.entities = [];
      state.helperClassesItem.loading = true;
      state.helperClassesItem.loaded = false;
    });
    builder.addCase(loadCarClassesHelper.fulfilled, (state, { payload }) => {
      state.helperClassesItem.entities = payload;
      state.helperClassesItem.loading = false;
      state.helperClassesItem.loaded = true;
    });
    builder.addCase(loadCarClassesHelper.rejected, (state, action) => {
      state.helperClassesItem.loading = false;
      state.helperClassesItem.error = action.error.message;
    });

    builder.addCase(loadLandingCitiesHelper.pending, (state) => {
      state.helperLandingCitiesItem.entities = [];
      state.helperLandingCitiesItem.loading = true;
      state.helperLandingCitiesItem.loaded = false;
    });
    builder.addCase(loadLandingCitiesHelper.fulfilled, (state, { payload }) => {
      state.helperLandingCitiesItem.entities = payload;
      state.helperLandingCitiesItem.loading = false;
      state.helperLandingCitiesItem.loaded = true;
    });
    builder.addCase(loadLandingCitiesHelper.rejected, (state, action) => {
      state.helperLandingCitiesItem.loading = false;
      state.helperLandingCitiesItem.error = action.error.message;
    });

    builder.addCase(loadTaxStatusesHelper.pending, (state) => {
      state.helperTaxStatusesItem.entities = [];
      state.helperTaxStatusesItem.loading = true;
      state.helperTaxStatusesItem.loaded = false;
    });
    builder.addCase(loadTaxStatusesHelper.fulfilled, (state, { payload }) => {
      state.helperTaxStatusesItem.entities = payload;
      state.helperTaxStatusesItem.loading = false;
      state.helperTaxStatusesItem.loaded = true;
    });
    builder.addCase(loadTaxStatusesHelper.rejected, (state, action) => {
      state.helperTaxStatusesItem.loading = false;
      state.helperTaxStatusesItem.error = action.error.message;
    });
  },
});

export const getCities = (state: TState) => state.helpers.helperCitiesItem;
export const getCitiesLoading = (state: TState) => state.helpers.helperCitiesItem.loading;
export const getCitiesLoaded = (state: TState) => state.helpers.helperCitiesItem.loaded;
export const getCitiesError = (state: TState) => state.helpers.helperCitiesItem.error;
export const getMarks = (state: TState) => state.helpers.helperMarksItem;
export const getModels = (state: TState) => state.helpers.helperModelsItem;
export const getColors = (state: TState) => state.helpers.helperColorsItem;
export const getBodies = (state: TState) => state.helpers.helperBodiesItem;
export const getClasses = (state: TState) => state.helpers.helperClassesItem;
export const getLandingCities = (state: TState) => state.helpers.helperLandingCitiesItem;
export const getTaxStatuses = (state: TState) => state.helpers.helperTaxStatusesItem;
export default helpersSlice.reducer;
