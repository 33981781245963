import React, { ReactElement } from 'react';
import { Driver } from 'interfaces/Driver';
import DriverListItem from './DriverListItem';
import DriverListTitle from './DriverListTitle';
import driversStyles from './styles';

export type OnClickListItem = {
  onEdit: (id: string | undefined) => void;
};

export type PropsListItem = {
  items: Partial<Driver>[];
} & OnClickListItem;

const DriverList = ({ items, onEdit }: PropsListItem): ReactElement | null => {
  const classes = driversStyles();

  return (
    <>
      <DriverListTitle />
      {items.map((driver: Partial<Driver>) => (
        <div key={driver.id} className={classes.tileItem} aria-hidden="true">
          <DriverListItem onEdit={onEdit} item={driver} />
        </div>
      ))}
    </>
  );
};

export default React.memo(DriverList);
