import React from 'react';
import { Grid, Typography, createStyles, makeStyles } from '@material-ui/core';

type Props = {
  className?: string;
};

export const PromoListHeader = ({ className }: Props) => {
  const classes = useStyles();

  return (
    <Grid className={className} container item>
      <Grid className={classes.column} item sm={4}>
        <Typography variant="inherit">Организация</Typography>
      </Grid>
      <Grid className={classes.column} item sm={4}>
        <Typography variant="inherit">Название промо</Typography>
      </Grid>
      <Grid className={classes.column} item sm={2}>
        <Typography variant="inherit">Статус</Typography>
      </Grid>
      <Grid className={classes.column} item sm={2}>
        <Typography variant="inherit">Истекает</Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    column: {
      padding: theme.spacing(1),
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#7d7d7d',
    },
  }),
);
