import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import TextFieldInput from 'components/shared/inputs/TextFieldInput';
import { useDispatch } from 'react-redux';
import { UserEditForm } from 'constants/Forms';
import { User } from 'interfaces/User';
import { updateUserByMe } from 'store/reducers/users/actions';

type TUserEditProps = {
  user: User;
  onSave: () => void;
};

const UserEdit: React.FC<TUserEditProps> = ({ user, onSave }) => {
  const dispatch = useDispatch();

  const { handleSubmit, control, errors } = useForm<User>({
    defaultValues: user || {},
  });

  const onSubmit = useCallback(
    (data) => {
      dispatch(updateUserByMe(data));
      onSave();
    },
    [onSave, dispatch],
  );

  return (
    <form id={UserEditForm} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller as={TextFieldInput} field={{ title: 'ФИО:' }} name="name" errors={errors} control={control} />
        </Grid>
        <Grid item xs={12}>
          <Controller as={TextFieldInput} field={{ title: 'E-mail:' }} name="email" errors={errors} control={control} />
        </Grid>
      </Grid>
    </form>
  );
};

export default React.memo(UserEdit);
