import React, { useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, FieldErrors, useWatch } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { isValid } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    fontSize: 16,
    maxWidth: 240,
    fontWeight: 300,
    lineHeight: '19px',
    color: theme.palette.primary.main,
    paddingBottom: 2,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
  },
  error: {
    color: theme.palette.secondary.light,
  },
  timeRangeContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > :first-child': {
      marginRight: theme.spacing(1),
    },
  },
  input: {
    maxHeight: 32,
  },
  root: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      padding: 7,
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
}));

type ControlledRangeDatePickerProps = {
  startTimeName: string;
  endTimeName: string;
  label: string;
  errors?: FieldErrors;
  control: any;
};

const ControlledRangeDatePicker: React.FC<ControlledRangeDatePickerProps> = ({
  startTimeName,
  endTimeName,
  errors,
  control,
  label,
}) => {
  const classes = useStyles();

  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);

  const finishDate = useWatch({
    control,
    name: 'finish',
    defaultValue: endTime,
  });

  const startDate = useWatch({
    control,
    name: 'start',
    defaultValue: endTime,
  });

  return (
    <div className={classes.container}>
      {label && <div className={classes.label}>{label}</div>}
      <div className={classes.inputContainer}>
        <div className={classes.timeRangeContainer}>
          <Controller
            as={(props) => (
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                value={props.value}
                onChange={(e) => {
                  props.onChange(e);
                  isValid(e) && setStartTime(e);
                }}
                autoOk
                placeholder="с ДД/ММ/ГГ"
                className={classes.root}
                InputProps={{ className: classes.input }}
                inputVariant="outlined"
                format="dd/MM/yyyy"
                invalidDateMessage="Неверный формат даты"
                maxDate={finishDate}
                maxDateMessage="Начальная дата не может быть раньше конечной"
              />
            )}
            name={startTimeName}
            control={control}
          />
          <Controller
            as={(props) => (
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                value={props.value}
                onChange={(e) => {
                  props.onChange(e);
                  isValid(e) && setEndTime(e);
                }}
                autoOk
                placeholder="до ДД/ММ/ГГ"
                className={classes.root}
                InputProps={{ className: classes.input }}
                inputVariant="outlined"
                format="dd/MM/yyyy"
                invalidDateMessage="Неверный формат даты"
                minDate={startDate}
                minDateMessage="Конечная дата не может быть раньше начальной"
              />
            )}
            name={endTimeName}
            control={control}
          />
        </div>
        {errors && (
          <>
            <ErrorMessage as="span" className={classes.error} errors={errors} name={startTimeName} />
            <ErrorMessage as="span" className={classes.error} errors={errors} name={endTimeName} />
          </>
        )}
      </div>
    </div>
  );
};

export default ControlledRangeDatePicker;
